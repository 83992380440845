import React, { useState, useEffect, useRef, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { DropdownApp as Dropdown } from '@components/form/Dropdown';
import { Dropdown as DropdownPagination } from 'primereact/dropdown';
import { CalendarApp as Calendar } from '@components/form/Calendar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Ripple } from 'primereact/ripple';
import { useParams, useHistory } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { DesplazamientoService } from "@services/DesplazamientoService";
import _ from 'lodash';
import useWindowDimensions from '@hooks/UseHeight'
import { formatDate, formatCalendar, getDateFormatWithHour } from '@util/date';

export const Desplazamientos = () => {
    const history = useHistory();
    let { id } = useParams();
    const [idemploye, setIdemploye] = useState(id)

    const { height } = useWindowDimensions();
    let emptyElement = {
        id: null,
        num_document: '',
        document_id: '',
        dependencie_id: '',
        office_id: '',
        created_at: ''
    };

    const [elements, setElements] = useState([]);
    const [dataform, setDataform] = useState({ offices: [] });
    const [loadingAllElements, setLoadingAllElements] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingDeleteIds, setLoadingDeleteIds] = useState(false);
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [elementDialog, setElementDialog] = useState(false);
    const [deleteElementDialog, setDeleteElementDialog] = useState(false);
    const [deleteElementsDialog, setDeleteElementsDialog] = useState(false);
    const [element, setElement] = useState(emptyElement);
    const [condition, setCondition] = useState('Nuevo');
    const [errors, setErrors] = useState([]);
    const [selectedElements, setSelectedElements] = useState(null);
    const [selectedElementsId, setSelectedElementsId] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const elementsService = new DesplazamientoService();

    //pagination 
    const [rows, setRows] = useState(10);
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);

    useEffect(() => {
        async function getInit() {
            setLoadingAllElements(true)
            getAllElementsFunction(currentpage, rows);
            let result = await elementsService.getEmployeDataDesplazamiento(idemploye);
            setDataform(result ? result : [])
            setLoadingAllElements(false)
        }
        getInit();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openNew = () => {
        setErrors([]);
        setCondition('Nuevo');
        setElement(emptyElement);
        setSubmitted(false);
        setElementDialog(true);
    }

    const hideDialog = () => {
        setCondition('Nuevo');

        setElementDialog(false);
        setSubmitted(false);
    }

    const hideDeleteElementDialog = () => {
        setDeleteElementDialog(false);
    }

    const hideDeleteElementsDialog = () => {
        setDeleteElementsDialog(false);
    }

    const saveElement = async () => {
        setSubmitted(true);
        if (!element.num_document) {
            return false
        }

        setLoading(true)
        let el = {
            num_document: element.num_document,
            document_id: (element.document_id ? element.document_id.id : ''),
            office_id: (element.office_id ? element.office_id.id : ''),
            created_at: element.created_at ? formatDate(element.created_at, 'yyyy-MM-dd HH:mm:ss') : '',
        }
        let result;
        if (condition === 'Nuevo') {
            result = await elementsService.setCreateElement(el, idemploye)
            if (result.errors) {
                setErrors(result.errors[0]);
                setLoading(false)
                return false;
            }
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        } else {
            result = await elementsService.setUpdateElement(el, element.id)
            if (result.errors) {
                setErrors(result.errors[0]);
                setLoading(false)
                return false;
            }
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        }

        setErrors([]);
        getAllElementsFunction(currentpage, rows);
        setLoading(false)
        setElementDialog(false);
        setElement(emptyElement);

    }

    const editElement = (element) => {
        console.log(element);
        let data = {
            ...element, dependencie_id: element.office.dependency,
            office_id: dataform.offices.find(param => param.id === element.office.id && param.dependency_id === element.office.dependency.id), document_id: element.document

        }
        data.created_at = data.created_at ? formatCalendar(data.created_at) : ''

        setErrors([]);
        setCondition('Actualizar');
        setElement({ ...data });
        setElementDialog(true);
    }

    const confirmDeleteElement = (element) => {
        setElement(element);
        setDeleteElementDialog(true);
    }

    const deleteElement = async () => {
        let result = await elementsService.setDeleteElement(element.id)

        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        setDeleteElementDialog(false);
        setElement(emptyElement);
        getAllElementsFunction(currentpage, rows);
    }

    const getAllElementsFunction = async (page, row, q = null, sort = null) => {
        await elementsService.getAllElements(page, row, q, sort, idemploye).then(data => {
            if (!_.isEmpty(data.meta)) {
                setCurrentpage(data.meta.current_page);
                setTotalrecords(data.meta.total);
                setElements(data);
                setLastpage(data.meta.last_page);
            }

        });
    }

    const confirmDeleteSelected = () => {
        let ids = [];
        for (let i = 0; i < selectedElements.length; i++) {
            ids.push(selectedElements[i].id);
        }
        setSelectedElementsId(ids);
        setDeleteElementsDialog(true);
    }

    const deleteSelectedElements = async () => {
        setLoadingDeleteIds(true)
        let result = await elementsService.setDeleteMultipleElement(selectedElementsId)
        getAllElementsFunction(currentpage, rows);
        setLoadingDeleteIds(false)
        setDeleteElementsDialog(false);
        setSelectedElements(null);
        setSelectedElementsId([]);
        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
    }

    const onInputChange = (e, name) => {
        let val;
        if (!_.has(e, 'target')) {
            val = e;
        } else {
            val = (e.target && e.target.value) || '';
        }
        let _element = { ...element };
        _element[`${name}`] = val;
        setElement(_element);
    }

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getAllElementsFunction(event.page + 1, event.rows);
    }

    const getPaginationElement = async (event) => {
        setCurrentpage(event.page + 1);
        getAllElementsFunction(event.page + 1, event.rows);
    }

    const sortFunctionElements = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }


        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }

        getAllElementsFunction(currentpage, rows, null, string);
    }

    const searchGlobalElements = useCallback(
        _.debounce(async (value) => {
            getAllElementsFunction(currentpage, rows, value);
        }, 600),
        [],
    );

    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(elements.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(elements.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                        onClick={() => getPaginationElement({
                            page: index,
                            rows: rows
                        })}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(elements.data) ? <DropdownPagination value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },

    }


    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="justify-content-center w-full md:flex hidden">
                    <div>
                        <Button icon="pi pi-pencil" className="p-button  mr-2" onClick={() => editElement(rowData)} />
                        <Button icon="pi pi-trash" className="p-button  p-button-danger" onClick={() => confirmDeleteElement(rowData)} />
                    </div>
                </div>
                <div className="justify-content-between w-6rem md:hidden flex">
                    <div>
                        <Button icon="pi pi-pencil" className="p-button  mr-2" onClick={() => editElement(rowData)} />
                        <Button icon="pi pi-trash" className="p-button  p-button-danger" onClick={() => confirmDeleteElement(rowData)} />
                    </div>
                </div>
            </>
        );
    }

    const header = (
        <div className="table-header-container">
            <div className="grid">
                <div className="md:col-5 col-12">
                    <div className="table-header">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText type="search" onInput={(e) => searchGlobalElements(e.target.value)} placeholder="Buscar..." />
                        </span>
                    </div>
                </div>
                <div className="md:col-7 col-12 ">
                    <div className=" md:block hidden">
                        <div className="flex md:justify-content-end">
                            <Button label="Nuevo" icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
                            <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedElements || !selectedElements.length} />
                            {/*<Button label="Exportar" icon="pi pi-file-excel" className="p-button " />*/}

                        </div>
                    </div>
                    <div className="md:hidden block ">
                        <div className="flex flex-wrap justify-content-between w-full">
                            <Button icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
                            <Button icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedElements || !selectedElements.length} />
                            <Button icon="pi pi-file-excel" className="p-button" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
    const elementDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button disabled={loading} label={
                loading ? '...Cargando' : (condition === 'Nuevo' ? 'Guardar' : 'Actualizar')
            } icon="pi pi-check" onClick={saveElement} />
        </>
    );
    const deleteElementDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteElementDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteElement} />
        </>
    );
    const deleteElementsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteElementsDialog} />
            <Button label={loadingDeleteIds ? '...Cargando' : 'Si'} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedElements} />
        </>
    );

    const numdocumentField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Número de Documento</span>
                {rowData.num_document}
            </>
        )
    }

    const createdAtField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha de Registro</span>
                {getDateFormatWithHour(rowData.created_at)}
            </>
        )
    }

    const documentField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Documento</span>
                {rowData.document.name}
            </>
        )
    }
    const officeField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Oficina</span>
                {rowData.office.name}
            </>
        )
    }
    const dependencyField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Dependencia</span>
                {rowData.office.dependency.name}
            </>
        )
    }

    return (
        <div className="datatable-responsive">
            <div className="mb-4">
                <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-text" onClick={() => history.push('/empleado/listado')} tooltip="Regresar" />
            </div>
            <div className="card">
                <Toast ref={toast} />
                <h5 className="p-m-0">Listado de desplazamientos</h5>
                <DataTable
                    showGridlines
                    className="p-datatable-responsive"
                    ref={dt}
                    value={elements.data}
                    selection={selectedElements}
                    onSelectionChange={(e) => setSelectedElements(e.value)}
                    dataKey="id"
                    paginator
                    loading={loadingAllElements}
                    onPage={onCustomPage}
                    rows={rows}
                    paginatorTemplate={templatePaginator}
                    emptyMessage="No se encontraron resultados"
                    currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} desplazamientos"
                    scrollable
                    scrollHeight={`${height - 460}px`}
                    header={header}
                    sortMode="multiple"
                    multiSortMeta={multiSortMeta}
                    onSort={e => sortFunctionElements(e.multiSortMeta)}
                    sortOrder={multiSortMeta}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="dependency" header="Dependencia" sortable body={dependencyField}></Column>
                    <Column field="office" header="Oficina" sortable body={officeField}></Column>
                    <Column field="document" header="Documento" sortable body={documentField}></Column>
                    <Column field="num_document" header="Número de Documento" sortable body={numdocumentField}></Column>
                    <Column field="created_at" header="Fecha de Registro" sortable body={createdAtField}></Column>
                    <Column body={actionBodyTemplate} className="text-center"></Column>
                </DataTable>


                <Dialog
                    visible={elementDialog}
                    style={{ width: '450px' }}
                    header={`${condition} desplazamiento`}
                    modal
                    className="p-fluid"
                    footer={elementDialogFooter}
                    onHide={hideDialog}
                >
                    <div className="field">
                        <label htmlFor="resolution">Número de Documento</label>
                        <InputText id="num_document" value={element.num_document} onChange={(e) => onInputChange(e, 'num_document')} required autoFocus className={classNames({ 'p-invalid': submitted && !element.num_document })} />
                        {submitted && !element.num_document && <small className="p-error">El campo número de documento es requerido.</small>}
                        {submitted && errors.num_document && element.num_document && <small className="p-error">{errors.num_document[0]}</small>}
                    </div>

                    <div className="field">
                        <Dropdown
                            label="Documento"
                            value={element.document_id}
                            options={dataform.documents}
                            optionLabel="name"
                            onChange={(e) => onInputChange(e, 'document_id')}
                            errors={errors.document_id && errors.document_id.length > 0}
                            textError={errors.document_id && errors.document_id[0]} />
                    </div>


                    <div className="field">
                        <Dropdown
                            label="Dependencia"
                            value={element.dependencie_id}
                            options={dataform.dependencies}
                            optionLabel="name"
                            onChange={(e) => onInputChange(e, 'dependencie_id')}
                            errors={errors.dependencie_id && errors.dependencie_id.length > 0}
                            textError={errors.dependencie_id && errors.dependencie_id[0]} />
                    </div>

                    <div className="field">
                        <Dropdown
                            label="Oficina"
                            value={element.office_id}
                            options={dataform.offices.filter(param => element.dependencie_id && param.dependency_id === element.dependencie_id.id)}
                            optionLabel="name"
                            onChange={(e) => onInputChange(e, 'office_id')}
                            errors={errors.office_id && errors.office_id.length > 0}
                            textError={errors.office_id && errors.office_id[0]} />
                    </div>


                    <div className="field">
                        <Calendar
                            showTime
                            label="Fecha de Registro"
                            errors={errors.created_at && errors.created_at.length > 0}
                            value={element.created_at}
                            onChange={(e) => onInputChange(e, 'created_at')}
                            textError={errors.created_at && errors.created_at[0]} />
                    </div>

                </Dialog>

                <Dialog
                    visible={deleteElementDialog}
                    style={{ width: '480px' }}
                    header="Eliminar desplazamiento"
                    modal
                    footer={deleteElementDialogFooter}
                    onHide={hideDeleteElementDialog}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {element && <span>¿Está seguro de eliminar el desplazamiento <b>{element.num_document}</b>?</span>}
                    </div>
                </Dialog>

                <Dialog
                    visible={deleteElementsDialog}
                    style={{ width: '480px' }}
                    header="Eliminar desplazamientos"
                    modal
                    footer={deleteElementsDialogFooter}
                    onHide={hideDeleteElementsDialog}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {element && <span>¿Está seguro de eliminar a los desplazamientos seleccionados?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}