import React from 'react';
import PropTypes from 'prop-types'
import { Label } from './Label';
import { AutoComplete } from 'primereact/autocomplete';
export const AutoCompleteApp = (props) => {
  const { errors, textError, label, onChange, value, completeMethod, suggestions } = props;


  return (
    <>
      <div className="py-2 p-field w-full">
        <Label>{label}</Label>
        <div className="pt-1">
          <AutoComplete value={value} suggestions={suggestions} completeMethod={completeMethod} field="name" dropdown forceSelection
            onChange={(e) => onChange(e.value)} />
          {errors &&
            <div>  <small className="w-full text-red-700 label-app">{textError}</small></div>
          }
        </div>
      </div>
    </>
  )
};

AutoCompleteApp.defaultProps = {
  errors: false,
  textError: '',
  label: '',
  value: null,
  optionLabel: '',
  editable: false,
  placeholder: '',
  options: [],
  filter: false
};
AutoCompleteApp.propTypes = {
  errors: PropTypes.bool,
  onChange: PropTypes.func,
  textError: PropTypes.string,
  editable: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  optionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  filter: PropTypes.bool
}
