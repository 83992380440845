import React from 'react';
import _ from "lodash";
import Admin from "./main";
import { Route, Switch } from 'react-router-dom'; 

function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            render={props => (
                // pass the sub-routes down to keep nesting
                <Admin>
                    <route.component {...props} routes={route.routes} />
                </Admin>
            )}
        />

    );
}

const App = ({ routes, children }) => {
    return ( 
                !_.isEmpty(routes) ?
                    <Switch>
                        {routes.map((route, i) => (
                            <RouteWithSubRoutes key={i} {...route} />
                        ))}
                    </Switch>
                    :
                    children 
    );
}

export default App;
