
import axios_client from "../util/axios_client";

export class DisabilityService {

    async getAllElements(page, itemsPerPage, q = null, sort, employe) {
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (q) {
            url = url + "&filter=" + q;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        return await axios_client.get(`/api/employee/${employe}/disabilityEmployee` + url
        )
            .then(res => res.data);
    }

    async setCreateElement(param, employe) {

        return await axios_client.post(`/api/employee/${employe}/disabilityEmployee`, param)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

    async getEmployeDataDisabilities() {
        try {
            let result = await axios_client.get('/api/disabilityEmployee/data');
            return result.data.data.disabilities
        } catch (error) {
            return false;
        }
    }

    async setUpdateElement(param, id) {
        return await axios_client.put(`/api/disabilityEmployee/${id}`, param)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

    async setDeleteElement(id) {
        return await axios_client.delete(`/api/disabilityEmployee/${id}`)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

    async setDeleteMultipleElement(param) {
        return await axios_client.delete(`/api/disabilityEmployee/destroyMultiple`, {
            data: {
                ids: param
            }
        })
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

}