import React, { useState, useEffect, useRef, useContext } from 'react';
import { InputApp as InputText } from '@components/form/InputText';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { update } from '@services/ProfileService';
import { Toast } from 'primereact/toast';
import Cookies from 'js-cookie';
import { Context } from '@context/Auth'; 

export const EditarUsuario = () => {
    const { control, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
    const [dataUser, setDataUser] = useState({ id: '', email: '', name: '' });
    const context = useContext(Context);

    useEffect(() => {
        setDataUser(JSON.parse(Cookies.get('app_user')))
        return () => {
            setDataUser({ id: '', email: '', name: '' })
        }
    }, [])
    useEffect(() => {
        setValue('email',dataUser.email)
        setValue('name',dataUser.name)
        return () => {
            setValue('')
        }
    }, [dataUser.id])
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);
    const password = watch("password");
    const password_repeat = watch("password_repeat");
    const onSubmit = async (data) => {
        setLoading(true);
        let param = {
            email: data.email,
            password: data.password,
            password_confirmation: data.password_repeat,
            name: data.name
        }
        let result = await update(param)
        if (result) {
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Mensaje', detail: 'Ocurrio un error.', life: 3000 });
        }
        setLoading(false);
        setTimeout(() => {
            context.actions.closeSession()
        }, 600);
    }
    return (
        <>
            <Toast ref={toast} />
            <div>
                <h5>Actualizar perfil</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <InputText
                                    label="Nombre"
                                    errors={errors.name && errors.name.length > 0}
                                    onChange={onChange}
                                    value={value}
                                    textError={errors.name && errors.name[0]} />
                            </>
                        )}
                        name="name"
                        rules={{
                            required: {
                                value: true,
                                message: "Campo requerido."
                            }
                        }}
                    />
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <InputText
                                    label="Correo"
                                    errors={errors.email && errors.email.length > 0}
                                    onChange={onChange}
                                    value={value}
                                    textError={errors.email && errors.email[0]} />
                            </>
                        )}
                        name="email"
                        rules={{
                            required: {
                                value: true,
                                message: "Campo requerido."
                            }
                        }}
                    />
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <InputText
                                    errors={errors.password}
                                    onChange={onChange}
                                    value={value}
                                    type="password"
                                    label="Contraseña"
                                    type={viewPassword ? 'text' : 'password'}
                                    iconRight={viewPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                    onClickRight={() => setViewPassword(!viewPassword)}
                                    textError={errors.password && errors.password.message} />
                            </>
                        )}
                        name="password"

                        rules={{
                            required: {
                                value: true,
                                message: "Campo requerido."
                            }
                        }}
                        defaultValue=""
                    />

                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <InputText
                                    errors={errors.password_repeat || (password_repeat !== '' && password !== '' && password_repeat !== password)}
                                    onChange={onChange}
                                    value={value}
                                    label="Confirmar contraseña"
                                    type="password"
                                    type={viewPassword ? 'text' : 'password'}
                                    iconRight={viewPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                    onClickRight={() => setViewPassword(!viewPassword)}
                                    textError={(errors.password_repeat && errors.password_repeat.message) || password_repeat !== password ? "Las contraseñas no coinciden" : ""} />
                            </>
                        )}
                        name="password_repeat"
                        rules={{
                            required: {
                                value: true,
                                message: "Campo requerido."
                            }
                        }}
                        defaultValue=""
                    />

                    <Button className="mt-4 w-full" disabled={loading} label={
                        loading ? '...Cargando' : 'Actualizar'
                    } icon="pi pi-check" />
                </form>
            </div>
        </>
    )
}