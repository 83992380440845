import React from 'react';
import PropTypes from 'prop-types'

export const Label = (props) => {
    const { children, font } = props;

    return <label className={`${font} label-app `} >{children}</label>
}

Label.defaultProps = {
    font: 'font-normal'
};

Label.propTypes = {
    font: PropTypes.string
}
