import React, { useState, useEffect, useRef, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { Ripple } from 'primereact/ripple';
import { InputText } from 'primereact/inputtext';
import { UsersService } from "@services/UsersService";
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';
import useWindowDimensions from '@hooks/UseHeight'


export const Usuarios = () => {
    const { height } = useWindowDimensions();
    let emptyUser = {
        id: null,
        name: '',
        image: null,
        email: '',
        role: null,
        password_confirmation: '',
        permissions: []
    };

    const [users, setUsers] = useState([]);
    const [loadingAllUsers, setLoadingAllUsers] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingDeleteIds, setLoadingDeleteIds] = useState(false);
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [userDialog, setUserDialog] = useState(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [deleteUsersDialog, setDeleteUsersDialog] = useState(false);
    const [user, setUser] = useState(emptyUser);
    const [roles, setRoles] = useState([]);
    const [condition, setCondition] = useState('Nuevo');
    const [errors, setErrors] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [selectedUsersId, setSelectedUsersId] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const usersService = new UsersService();

    //pagination 
    const [rows, setRows] = useState(10);
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);

    useEffect(() => {
        async function getInit() {
            setLoadingAllUsers(true)
            getAllUsersFunction(currentpage, rows);
            let result = await usersService.getUserDataRole();
            setRoles(result ? result : [])
            setLoadingAllUsers(false)
        }
        getInit();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openNew = () => {
        setErrors([]);
        setCondition('Nuevo');
        setUser(emptyUser);
        setSubmitted(false);
        setUserDialog(true);
    }

    const hideDialog = () => {
        setCondition('Nuevo');

        setUserDialog(false);
        setSubmitted(false);
    }

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    }

    const hideDeleteUsersDialog = () => {
        setDeleteUsersDialog(false);
    }

    const saveUser = async () => {
        setSubmitted(true);
        if (!user.name || !user.email || !user.role || (!user.password_confirmation && condition === 'Nuevo') || (!user.password && condition === 'Nuevo')) {
            return false
        }
        setLoading(true)

        let result;
        if (condition === 'Nuevo') {
            result = await usersService.setCreateUser(user)
            if (result.errors) {
                setErrors(result.errors[0]);
                setLoading(false)
                return false;
            }
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        } else {
            result = await usersService.setUpdateUser(user, user.id)
            if (result.errors) {
                setErrors(result.errors[0]);
                setLoading(false)
                return false;
            }
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        }

        setErrors([]);
        getAllUsersFunction(currentpage, rows);
        setLoading(false)
        setUserDialog(false);
        setUser(emptyUser);

    }

    const editUser = (user) => {
        setErrors([]);
        setCondition('Actualizar');
        setUser({ ...user });
        setUserDialog(true);
    }

    const confirmDeleteUser = (user) => {
        setUser(user);
        setDeleteUserDialog(true);
    }

    const deleteUser = async () => {
        let result = await usersService.setDeleteUser(user.id)

        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        setDeleteUserDialog(false);
        setUser(emptyUser);
        getAllUsersFunction(currentpage, rows);
    }

    const getAllUsersFunction = async (page, row, q = null, sort = null) => {
        await usersService.getAllUsers(page, row, q, sort).then(data => {
            if (!_.isEmpty(data.meta)) {
                setCurrentpage(data.meta.current_page);
                setTotalrecords(data.meta.total);
                setUsers(data);
                setLastpage(data.meta.last_page);
            }

        });
    }

    const confirmDeleteSelected = () => {
        let ids = [];
        for (let i = 0; i < selectedUsers.length; i++) {
            ids.push(selectedUsers[i].id);
        }
        setSelectedUsersId(ids);
        setDeleteUsersDialog(true);
    }

    const deleteSelectedUsers = async () => {
        setLoadingDeleteIds(true)
        let result = await usersService.setDeleteMultipleUser(selectedUsersId)
        getAllUsersFunction(currentpage, rows);
        setLoadingDeleteIds(false)
        setDeleteUsersDialog(false);
        setSelectedUsers(null);
        setSelectedUsersId([]);
        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
    }

    const onRoleChange = (e) => {
        let _user = { ...user };
        _user['role'] = e.value;
        setUser(_user);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = { ...user };
        _user[`${name}`] = val;
        setUser(_user);
    }

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getAllUsersFunction(event.page + 1, event.rows);
    }

    const getPaginationUser = async (event) => {
        setCurrentpage(event.page + 1);
        getAllUsersFunction(event.page + 1, event.rows);
    }

    const sortFunctionUsers = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }


        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }

        getAllUsersFunction(currentpage, rows, null, string);
    }

    const searchGlobalUsers = useCallback(
        _.debounce(async (value) => {
            getAllUsersFunction(currentpage, rows, value);
        }, 600),
        [],
    );

    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(users.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(users.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                        onClick={() => getPaginationUser({
                            page: index,
                            rows: rows
                        })}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(users.data) ? <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },

    }


    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="justify-content-center w-full md:flex hidden">
                    <div>
                        <Button icon="pi pi-pencil" className="p-button  mr-2" onClick={() => editUser(rowData)} />
                        <Button icon="pi pi-trash" className="p-button  p-button-danger" onClick={() => confirmDeleteUser(rowData)} />
                    </div>
                </div>
                <div className="justify-content-between w-6rem md:hidden flex">
                    <div>
                        <Button icon="pi pi-pencil" className="p-button  mr-2" onClick={() => editUser(rowData)} />
                        <Button icon="pi pi-trash" className="p-button  p-button-danger" onClick={() => confirmDeleteUser(rowData)} />
                    </div>
                </div>
            </>
        );
    }

    const header = (
        <div className="table-header-container">
            <div className="grid">
                <div className="md:col-5 col-12">
                    <div className="table-header">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText type="search" onInput={(e) => searchGlobalUsers(e.target.value)} placeholder="Buscar..." />
                        </span>
                    </div>
                </div>
                <div className="md:col-7 col-12 ">
                    <div className=" md:block hidden">
                        <div className="flex md:justify-content-end">
                            <Button label="Nuevo" icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
                            <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} />
                            {/*<Button label="Exportar" icon="pi pi-file-excel" className="p-button " />*/}

                        </div>
                    </div>
                    <div className="md:hidden block ">
                        <div className="flex flex-wrap justify-content-between w-full">
                            <Button icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
                            <Button icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} />
                            <Button icon="pi pi-file-excel" className="p-button" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
    const userDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button disabled={loading} label={
                loading ? '...Cargando' : (condition === 'Nuevo' ? 'Guardar' : 'Actualizar')
            } icon="pi pi-check" onClick={saveUser} />
        </>
    );
    const deleteUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteUserDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteUser} />
        </>
    );
    const deleteUsersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteUsersDialog} />
            <Button label={loadingDeleteIds ? '...Cargando' : 'Si'} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUsers} />
        </>
    );

    const nameField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombres</span>
                {rowData.name}
            </>
        )
    }

    const emailField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Correo</span>
                {rowData.email}
            </>
        )
    }

    const roleField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Rol</span>
                {rowData.role}
            </>
        )
    }

    return (
        <div className="datatable-responsive">
            <div className="card">
                <Toast ref={toast} />
                <h5 className="p-m-0">Listado de usuarios</h5>
                <DataTable
                    showGridlines
                    className="p-datatable-responsive"
                    ref={dt}
                    value={users.data}
                    selection={selectedUsers}
                    onSelectionChange={(e) => setSelectedUsers(e.value)}
                    dataKey="id"
                    paginator
                    loading={loadingAllUsers}
                    onPage={onCustomPage}
                    rows={rows}
                    paginatorTemplate={templatePaginator}
                    emptyMessage="No se encontraron resultados"
                    currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} usuarios"
                    scrollable
                    scrollHeight={`${height - 460}px`}
                    header={header}
                    sortMode="multiple"
                    multiSortMeta={multiSortMeta}
                    onSort={e => sortFunctionUsers(e.multiSortMeta)}
                    sortOrder={multiSortMeta}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="name" header="Nombres" sortable body={nameField}></Column>
                    <Column field="email" header="Correo" sortable body={emailField}></Column>
                    <Column field="role" header="Rol" sortable body={roleField}></Column>
                    <Column body={actionBodyTemplate} className="text-center"></Column>
                </DataTable>


                <Dialog
                    visible={userDialog}
                    style={{ width: '450px' }}
                    header={`${condition} Usuario`}
                    modal
                    className="p-fluid"
                    footer={userDialogFooter}
                    onHide={hideDialog}
                >
                    {user.image && <img src={`showcase/demo/images/user/${user.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={user.image} className="user-image" />}
                    <div className="field">
                        <label htmlFor="name">Nombres</label>
                        <InputText id="name" value={user.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.name })} />
                        {submitted && !user.name && <small className="p-error">El campo nombres es requerido.</small>}
                        {submitted && errors.name && user.name && <small className="p-error">{errors.name[0]}</small>}
                    </div>

                    <div className="field">
                        <label htmlFor="email">Correo</label>
                        <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.email })} />
                        {submitted && !user.email && <small className="p-error">El campo email es requerido.</small>}
                        {submitted && errors.email && user.email && <small className="p-error">{errors.email[0]}</small>}
                    </div>

                    <div className="field">
                        <label htmlFor="password">Contraseña</label>
                        <InputText id="password" type="password" value={user.password} onChange={(e) => onInputChange(e, 'password')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.password && condition === 'Nuevo' })} />
                        {submitted && !user.password && condition === 'Nuevo' && <small className="p-error">El campo contraseña es requerido.</small>}
                        {submitted && errors.password && user.password && <small className="p-error">{errors.password[0]}</small>}
                        {submitted && errors.password && !user.password && condition === 'Actualizar' && <small className="p-error">{errors.password[0]}</small>}
                    </div>

                    <div className="field">
                        <label htmlFor="password_confirmation">Confirmación de Contraseña</label>
                        <InputText id="password_confirmation" type="password"
                            value={user.password_confirmation}
                            onChange={(e) => onInputChange(e, 'password_confirmation')} required autoFocus
                            className={classNames({ 'p-invalid': submitted && !user.password_confirmation && condition === 'Nuevo' })} />
                        {submitted && !user.password_confirmation && condition === 'Nuevo' && <small className="p-error">El campo confirmación de contraseña es requerido.</small>}
                        {submitted && user.password_confirmation && user.password_confirmation !== user.password && <small className="p-error">El campo de confirmación de contraseña no coincide.</small>}
                        {submitted && errors.password_confirmation && user.password_confirmation && <small className="p-error">{errors.password_confirmation[0]}</small>}
                        {submitted && errors.password_confirmation && !user.password_confirmation && condition === 'Actualizar' && <small className="p-error">{errors.password_confirmation[0]}</small>}
                    </div>

                    <div className="field mt-2">
                        <label className="mb-3">Rol</label>
                        <div className="formgrid  grid">
                            {roles.map((param, index) => (
                                <div className="field-radiobutton  col-6" key={index}>
                                    <RadioButton inputId={param} name="roles" value={param} onChange={onRoleChange} checked={user.role === param} />
                                    <label htmlFor={param}>{param}</label>
                                </div>
                            ))}
                        </div>
                        {submitted && !user.role && <small className="p-error">Debe elegir un rol.</small>}
                        {submitted && errors.role && user.role && <small className="p-error">{errors.role[0]}</small>}
                    </div>
                </Dialog>
                <Dialog
                    visible={deleteUserDialog}
                    style={{ width: '480px' }}
                    header="Eliminar Usuario"
                    modal
                    footer={deleteUserDialogFooter}
                    onHide={hideDeleteUserDialog}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {user && <span>¿Está seguro de eliminar al usuario <b>{user.name}</b>?</span>}
                    </div>
                </Dialog>

                <Dialog
                    visible={deleteUsersDialog}
                    style={{ width: '480px' }}
                    header="Eliminar Usuarios"
                    modal
                    footer={deleteUsersDialogFooter}
                    onHide={hideDeleteUsersDialog}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {user && <span>¿Está seguro de eliminar a los usuarios seleccionados?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}