import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Label } from '@components/form/Label';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import useWindowDimensions from '@hooks/UseHeight'
import { MenuApp as Menu } from '@components/menu';
import { TransfersService } from "@services/TransfersService";
import { useMediaQuery } from 'react-responsive';
import { useHistory } from "react-router-dom";
import _ from 'lodash';

export const Listado = () => {
    const history = useHistory();
    const [selectedElements, setSelectedElements] = useState(null);
    const [selectedElementsId, setSelectedElementsId] = useState([]);
    const screenLg = useMediaQuery({ query: '(min-width: 480px)' })
    const toast = useRef(null);
    const { height } = useWindowDimensions();
    const [element, setElement] = useState({ assetTransfers: [] });
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [elements, setElements] = useState([]);
    const [loadingDeleteIds, setLoadingDeleteIds] = useState(false);
    const [loadingAllElements, setLoadingAllElements] = useState(true);
    const [deleteElementDialog, setDeleteElementDialog] = useState(false);
    const [showElementDialog, setShowElementDialog] = useState(false);
    const [deleteElementsDialog, setDeleteElementsDialog] = useState(false);
    const transfersService = new TransfersService();
    //pagination 
    const [rows, setRows] = useState(10);
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);

    useEffect(() => {
        async function getInit() {
            setLoadingAllElements(true)
            getAllElementsFunction(currentpage, rows);
        }
        getInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const bodyTemplate = (data, props) => {
        return (
            <div className="h-3rem flex   flex-wrap">
                <div className="flex align-items-center">{data[props.field]}</div>
            </div>
        );
    };

    const bodyTemplateApplicant = (data, props) => {
        return (
            <div className="h-3rem flex   flex-wrap">
                <div className="flex align-items-center">{data.applicant?.name} {data.applicant?.last_name}</div>
            </div>
        );
    };

    const bodyTemplateInCharge = (data, props) => {
        return (
            <div className="h-3rem flex   flex-wrap">
                <div className="flex align-items-center">{data.in_charge?.name} {data.in_charge?.last_name}</div>
            </div>
        );
    };

    const getAllElementsFunction = async (page, row, q = null, sort = null) => {
        await transfersService.getAllElements(page, row, q, sort).then(data => {
            if (!_.isEmpty(data.meta)) {
                setCurrentpage(data.meta.current_page);
                setTotalrecords(data.meta.total);
                setElements(data);
                setLastpage(data.meta.last_page);
            }
        });
        setLoadingAllElements(false)
    }

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getAllElementsFunction(event.page + 1, event.rows);
    }

    const getPaginationElement = async (event) => {
        setCurrentpage(event.page + 1);
        getAllElementsFunction(event.page + 1, event.rows);
    }

    const sortFunctionElementss = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }
        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }
        getAllElementsFunction(currentpage, rows, null, string);
    }

    const searchGlobalElements = useCallback(
        _.debounce(async (value) => {
            getAllElementsFunction(currentpage, rows, value);
        }, 600),
        [],
    );

    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(elements.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(elements.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                        onClick={() => getPaginationElement({
                            page: index,
                            rows: rows
                        })}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(elements.data) ? <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },
    }

    const desplazamientoTableHeader = (
        <div className="table-header-container">
            <div className="grid">
                <div className="md:col-5 col-12">
                    <div className="table-header">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText type="search" onInput={(e) => searchGlobalElements(e.target.value)} placeholder="Buscar..." />
                        </span>
                    </div>
                </div>
                <div className="md:col-7 col-12 ">
                    <div className=" md:block hidden">
                        <div className="flex md:justify-content-end">
                            <Button label="Nuevo" icon="pi pi-plus" className="p-button mr-2" onClick={() => history.push('/desplazamiento-de-bienes/registro')} />
                            <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger mr-2" onClick={() => confirmDeleteSelected()} disabled={!selectedElements || !selectedElements.length} />
                            {/*<Button label="Exportar" icon="pi pi-file-excel" className="p-button"   />*/}
                        </div>
                    </div>
                    <div className="md:hidden block ">
                        <div className="flex flex-wrap justify-content-between w-full">
                            <Button icon="pi pi-plus" className="p-button mr-2" onClick={() => history.push('/desplazamiento-de-bienes/registro')} />
                            <Button icon="pi pi-trash" className="p-button-danger mr-2" onClick={() => confirmDeleteSelected()} disabled={!selectedElements || !selectedElements.length} />
                            {/*<Button icon="pi pi-file-excel" className="p-button"   />*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const hideDeleteElementDialog = () => {
        setDeleteElementDialog(false);
    }

    const hideDeleteElementsDialog = () => {
        setDeleteElementsDialog(false);
    }

    const confirmDeleteElement = (element) => {
        setElement(element);
        setDeleteElementDialog(true);
    }

    const showElement = (element) => {
        setElement(element);
        setShowElementDialog(true);
    }

    const deleteElement = async () => {
        let result = await transfersService.setDeleteElement(element.id)
        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        setDeleteElementDialog(false);
        setElement({});
        getAllElementsFunction(currentpage, rows);
    }

    const confirmDeleteSelected = () => {
        let ids = [];
        for (let i = 0; i < selectedElements.length; i++) {
            ids.push(selectedElements[i].id);
        }
        console.log(ids);
        setSelectedElementsId(ids);
        setDeleteElementsDialog(true);
    }

    const deleteSelectedElements = async () => {
        setLoadingDeleteIds(true)
        let result = await transfersService.setDeleteMultipleElement(selectedElementsId)
        getAllElementsFunction(currentpage, rows);
        setLoadingDeleteIds(false)
        setDeleteElementsDialog(false);
        setSelectedElements(null);
        setSelectedElementsId([]);
        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
    }

    const hideDialog = () => {
        setShowElementDialog(false);
    }

    const deleteElementDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteElementDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteElement} />
        </>
    );

    const deleteElementsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteElementsDialog} />
            <Button label={loadingDeleteIds ? '...Cargando' : 'Si'} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedElements} />
        </>
    );

    const elementDialogFooter = (
        <>
            <Button label="Aceptar" icon="pi pi-check" onClick={hideDialog} />
        </>
    );

    const option = (data) => {
        return (
            <>
                <div className="flex justify-content-center flex-wrap ">
                    <div className="flex align-items-center justify-content-center ">

                        <Button className="    p-button-sm  p-button-info  mr-2" icon="pi pi-eye" onClick={() => showElement(data)} />
                        <Button className="    p-button-sm  mr-2 p-button-primary" icon="pi pi-user-edit" onClick={(event) => history.push('/desplazamiento-de-bienes/editar/' + data.id)} />
                        <Button className="    p-button-sm   p-button-danger" icon="pi pi-trash " onClick={() => confirmDeleteElement(data)} />
                    </div>
                </div>
            </>
        )
    }

    const patrimonialCodeField = (rowData) => {
        return (
            <>

                {rowData?.asset.patrimonial_code}
            </>
        )
    }

    const descriptionField = (rowData) => {
        return (
            <>

                {rowData?.asset.description}
            </>
        )
    }

    const brandField = (rowData) => {
        return (
            <>

                {rowData?.asset.brand?.name}
            </>
        )
    }
    const blueprintField = (rowData) => {
        return (
            <>

                {rowData?.asset.blueprint?.name}
            </>
        )
    }
    const serieField = (rowData) => {
        return (
            <>

                {rowData?.asset.serial_number}
            </>
        )
    }
    const colorField = (rowData) => {
        return (
            <>

                {rowData?.asset.color?.name}
            </>
        )
    }
    const assetStateField = (rowData) => {
        return (
            <>

                {rowData?.asset.asset_state?.name}
            </>
        )
    }

    return (
        <div className="card">
            <h5>Lista de desplazamiento de bienes</h5>
            <Toast ref={toast} />
            <DataTable
                scrollable
                scrollHeight={`${height - 388}px`}
                value={elements.data}
                className="p-datatable-responsive"
                paginator
                loading={loadingAllElements}
                onPage={onCustomPage}
                rows={rows}
                paginatorTemplate={templatePaginator}
                emptyMessage="No se encontraron resultados"
                currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} desplazamientos"
                dataKey="id"
                showGridlines
                selection={selectedElements}
                onSelectionChange={(e) => setSelectedElements(e.value)}
                header={desplazamientoTableHeader}
                sortMode="multiple"
                multiSortMeta={multiSortMeta}
                onSort={e => sortFunctionElementss(e.multiSortMeta)}
                sortOrder={multiSortMeta}
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column field="code" header="Código" sortable body={bodyTemplate} headerStyle={{ width: '300px' }} columnKey="code"></Column>
                <Column field="applicant" header="Solicitante" sortable body={bodyTemplateApplicant} headerStyle={{ width: '300px' }} columnKey="applicant"></Column>
                <Column field="in_charge" header="Responsable del traslado" sortable body={bodyTemplateInCharge} headerStyle={{ width: '300px' }} columnKey="in_charge"></Column>
                <Column field="departure_date" header="Fecha de salida" sortable body={bodyTemplate} headerStyle={{ width: '300px' }} columnKey="departure_date"></Column>
                <Column field="return_date" header="Fecha de retorno" sortable body={bodyTemplate} headerStyle={{ width: '300px' }} columnKey="return_date"></Column>
                <Column headerStyle={{ width: '10rem' }} body={option} className="text-center"></Column>
            </DataTable>

            <Dialog
                visible={showElementDialog}
                style={{ width: '850px' }}
                breakpoints={{ '4096px': '800px', '1300px': '800px', '960px': '700px', '640px': '550px', '575px': '450px' }}
                header={`Detalle de desplazamiento`}
                modal
                className="p-fluid"
                footer={elementDialogFooter}
                onHide={hideDialog}
            >
                <div className="grid">
                    <div className="md:col-4 sm:col-6 col-12">
                        <div className="field">
                            <label htmlFor="d_code">Código:</label>
                            <p id="d_code">{element.code} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12">
                        <div className="field">
                            <label htmlFor="d_reason">Motivo:</label>
                            <p id="d_reason">{element.reason} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_departure_date">Fecha de salida:</label>
                            <p id="d_departure_date">{element.departure_date} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_return_date">Fecha de retorno:</label>
                            <p id="d_return_date">{element.return_date} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_applicant">Solicitante:</label>
                            <p id="d_applicant">{element.applicant?.name} {element.applicant?.last_name}</p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_in_charge">Responsable del traslado:</label>
                            <p id="d_in_charge">{element.in_charge?.name} {element.in_charge?.last_name}</p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_dependency_origin">Dependencia de origen:</label>
                            <p id="d_dependency_origin">{element.dependency_origin?.name} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_dependency_destination">Dependencia de destino:</label>
                            <p id="d_dependency_destination">{element.dependency_destination?.name} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_transfer_type">Tipo de transferencia:</label>
                            <p id="d_transfer_type">{element.transfer_type?.name} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_observations">Observación:</label>
                            <p id="d_observations">{element.observations} </p>
                        </div>
                    </div>

                    <div className="md:col-12 sm:col-12 col-12 ">
                        <br />
                        {
                            element.assetTransfers?.length > 0 && <DataTable

                                showGridlines
                                className="p-datatable-responsive"
                                value={element.assetTransfers}
                                dataKey="id"
                                emptyMessage="No se encontraron resultados"
                                currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} bienes patrimoniales"
                                scrollable
                                scrollHeight={`${height - 500}px`}
                            >
                                <Column field="patrimonial_code" header="Código Patrimonial" body={patrimonialCodeField}></Column>
                                <Column field="description" header="Descripción" body={descriptionField}></Column>
                                <Column field="brand" header="Marca" body={brandField}></Column>
                                <Column field="blueprint" header="Modelo" body={blueprintField}></Column>
                                <Column field="serial_number" header="Serie" body={serieField}></Column>
                                <Column field="color" header="Color" body={colorField}></Column>
                                <Column field="assetState" header="Estado" body={assetStateField}></Column>
                            </DataTable>
                        }

                        <br />
                    </div>
                </div>
            </Dialog>

            <Dialog
                visible={deleteElementDialog}
                style={{ width: '480px' }}
                header="Eliminar desplazamiento"
                modal
                footer={deleteElementDialogFooter}
                onHide={hideDeleteElementDialog}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {element && <span>¿Está seguro de eliminar al desplazamiento <b>{element.code}</b>?</span>}
                </div>
            </Dialog>

            <Dialog
                visible={deleteElementsDialog}
                style={{ width: '480px' }}
                header="Eliminar desplazamientos"
                modal
                footer={deleteElementsDialogFooter}
                onHide={hideDeleteElementsDialog}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {element && <span>¿Está seguro de eliminar los desplazamientos seleccionados?</span>}
                </div>
            </Dialog>
        </div>
    )
}
