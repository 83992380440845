
import axios_client from "../util/axios_client";

export class ContratoService {

    async getAllElements(page, itemsPerPage, q = null, sort, employe) {
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (q) {
            url = url + "&filter=" + q;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        return await axios_client.get(`/api/employee/${employe}/contractDetail` + url
        )
            .then(res => res.data);
    }

    async setCreateElement(param, employe) {

        return await axios_client.post(`/api/employee/${employe}/contractDetail`, param)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

    async getEmployeDataContrato() {
        try {
            let result = await axios_client.get(`/api/contractDetail/data`);
            return result.data.data
        } catch (error) {
            return false;
        }
    }

    async setUpdateElement(param, id) {
        return await axios_client.put(`/api/contractDetail/${id}`, param)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

    async getWaiverForContract(id) {
        try {
            let result = await axios_client.get(`/api/contractDetail/${id}/waiver`);
            return result.data.data
        } catch (error) {
            return false;
        }
    }

    async setCreateElementWaiver(param, contract) {

        return await axios_client.post(`/api/contractDetail/${contract}/waiver`, param)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }
    async setUpdateElementWaiver(param, id) {
        return await axios_client.put(`/api/waiver/${id}`, param)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

    async setDeleteElementWaiver(id) {
        return await axios_client.delete(`/api/waiver/${id}`)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }
}