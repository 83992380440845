
import { Usuarios } from '@pages/Usuario';
import { Listado as LEmpleados } from '@pages/Empleado/Listado';
import { Registro as REmpleados } from '@pages/Empleado/Registro';

import { Listado as LBienes } from '@pages/DesplazamientoDeBienes/Listado';
import { Registro as RBienes } from '@pages/DesplazamientoDeBienes/Registro';

import { Horario } from '@pages/Empleado/Horario/index';
import { Permisos } from '@pages/Empleado/Permisos/index';
import { Vacaciones } from '@pages/Empleado/Vacaciones';
import { DetallesContrato } from '@pages/Empleado/DetallesContrato';
import { Desplazamientos } from '@pages/Empleado/Desplazamientos';
import { Discapacidades } from '@pages/Empleado/Discapacidades';
import { CargaFamiliar } from '@pages/Empleado/CargaFamiliar';
import { BienesPatrimoniales } from '@pages/Empleado/BienesPatrimoniales';
import { EditarUsuario } from '@pages/Usuario/edit';
import { PermisosEmpleadoPorPeriodo } from '@pages/Reportes/Permisos/PermisosEmpleadoPorPeriodo/index';
import { PermisosPorPeriodo } from '@pages/Reportes/Permisos/PermisosPorPeriodo/index';
import { PermisosRegimenPorPeriodo } from '@pages/Reportes/Permisos/PermisosRegimenPorPeriodo/index';

import { VacacionesPorAno } from '@pages/Reportes/Vacaciones/VacacionesPorAno/index';
import { DesplazamientosPorPeriodo } from '@pages/Reportes/Desplazamientos/DesplazamientosPorPeriodo/index';
import { Desplazamientos as DesplazamientosReporte } from '@pages/Reportes/Desplazamientos/DesplazamientosEmpleado/index';

import { HijosPorEmpleado } from '@pages/Reportes/Empleados/HijosPorEmpleado/index';
import { EmpleadosPorRegimenLaboral } from '@pages/Reportes/Empleados/EmpleadosPorRegimenLaboral/index';
import { EmpleadosPorGrupoSanguineo } from '@pages/Reportes/Empleados/EmpleadosPorGrupoSanguineo/index';
import { RenunciasPorPeriodo } from '@pages/Reportes/Contratos/RenunciasPorPeriodo/index';
import { EmpleadoPorTipoDeContrato } from '@pages/Reportes/Contratos/EmpleadoPorTipoDeContrato/index';
import { EmpleadosQueSonPadres } from '@pages/Reportes/Empleados/EmpleadosQueSonPadres/index';
import { EmpleadosQueSonMadres } from '@pages/Reportes/Empleados/EmpleadosQueSonMadres/index';
import { EmpleadosQueHanTenidoCovid } from '@pages/Reportes/Empleados/EmpleadosQueHanTenidoCovid/index';
import { EmpleadosPorComorbilidad } from '@pages/Reportes/Empleados/EmpleadosPorComorbilidad/index';
import { EmpleadosPorSistemaPension } from '@pages/Reportes/Empleados/EmpleadosPorSistemaPension/index';
import { EmpleadosPorDiscapacidad } from '@pages/Reportes/Empleados/EmpleadosPorDiscapacidad/index';
import { EmpleadosPorHijosRangoEdad } from '@pages/Reportes/Empleados/EmpleadosPorHijosRangoEdad/index';
import { FichaEmpleado } from '@pages/Reportes/Empleados/FichaEmpleado/index';

import { TotalBienesPatrimoniales } from '@pages/Reportes/BienesPatrimoniales/TotalBienesPatrimoniales/index';
import { BienesPatrimonialesPorEmpleado } from '@pages/Reportes/BienesPatrimoniales/BienesPatrimonialesPorEmpleado/index';
import { BienesPatrimonialesPorCodigoBarras } from '@pages/Reportes/BienesPatrimoniales/BienesPatrimonialesPorCodigoBarras/index';

import { TotalDesplazamientoBienesPatrimoniales } from '@pages/Reportes/DesplazamientoBienesPatrimoniales/TotalDesplazamientoBienesPatrimoniales/index';

import { Home } from '@pages/Home';
import Admin from '@layouts/admin';

export default [
    {
        path: '/inicio',
        component: Home
    },
    {
        path: '/usuario',
        component: Admin,
        routes: [
            {
                path: '/usuario/listado',
                component: Usuarios
            },
            {
                path: '/usuario/mi-perfil',
                component: EditarUsuario
            },

        ]
    },
    {
        path: '/desplazamiento-de-bienes',
        component: Admin,
        routes: [
            {
                path: '/desplazamiento-de-bienes/listado',
                component: LBienes
            },
            {
                path: '/desplazamiento-de-bienes/registro',
                component: RBienes
            },
            {
                path: '/desplazamiento-de-bienes/editar/:id',
                component: RBienes
            },
        ]
    },
    {
        path: '/empleado',
        component: Admin,
        routes: [
            {
                path: '/empleado/listado',
                component: LEmpleados
            },
            {
                path: '/empleado/registro',
                component: REmpleados
            },
            {
                path: '/empleado/editar/:id',
                component: REmpleados
            },
            {
                path: '/empleado/horarios/:id',
                component: Horario
            },
            {
                path: '/empleado/permisos/:id',
                component: Permisos
            },
            {
                path: '/empleado/vacaciones/:id',
                component: Vacaciones
            },
            {
                path: '/empleado/detalles-contratos/:id',
                component: DetallesContrato
            },
            {
                path: '/empleado/desplazamientos/:id',
                component: Desplazamientos
            },
            {
                path: '/empleado/discapacidades/:id',
                component: Discapacidades
            },
            {
                path: '/empleado/carga-familiar/:id',
                component: CargaFamiliar
            },
            {
                path: '/empleado/bienes-patrimoniales/:id',
                component: BienesPatrimoniales
            },
        ]
    },
    {
        path: '/reportes',
        component: Admin,
        routes: [
            //empleados
            {
                path: '/reportes/empleados/hijos-por-empleado',
                component: HijosPorEmpleado
            },
            {
                path: '/reportes/empleados/empleados-por-regimen-laboral',
                component: EmpleadosPorRegimenLaboral
            },
            {
                path: '/reportes/empleados/empleados-por-grupo-sanguineo',
                component: EmpleadosPorGrupoSanguineo
            },
            {
                path: '/reportes/empleados/empleados-que-son-padres',
                component: EmpleadosQueSonPadres
            },
            {
                path: '/reportes/empleados/empleados-que-son-madres',
                component: EmpleadosQueSonMadres
            },
            {
                path: '/reportes/empleados/empleados-que-han-tenido-covid',
                component: EmpleadosQueHanTenidoCovid
            },
            {
                path: '/reportes/empleados/empleados-por-comorbilidad',
                component: EmpleadosPorComorbilidad
            },
            {
                path: '/reportes/empleados/empleados-por-sistema-pension',
                component: EmpleadosPorSistemaPension
            },
            {
                path: '/reportes/empleados/empleados-por-discapacidad',
                component: EmpleadosPorDiscapacidad
            },
            {
                path: '/reportes/empleados/empleados-con-hijos-entre-rango-edad',
                component: EmpleadosPorHijosRangoEdad
            },
            {
                path: '/reportes/empleados/ficha-por-empleado',
                component: FichaEmpleado
            },
            //permisos
            {
                path: '/reportes/permisos/permisos-empleado-por-periodo',
                component: PermisosEmpleadoPorPeriodo
            },
            {
                path: '/reportes/permisos/permisos-por-periodo',
                component: PermisosPorPeriodo
            },
            {
                path: '/reportes/permisos/permisos-regimen-laboral-por-periodo',
                component: PermisosRegimenPorPeriodo
            },

            //vacaciones
            {
                path: '/reportes/vacaciones/vacaciones-por-año',
                component: VacacionesPorAno
            },

            //desplazamientos
            {
                path: '/reportes/desplazamientos/desplazamientos',
                component: DesplazamientosReporte
            },
            {
                path: '/reportes/desplazamientos/desplazamientos-por-periodo',
                component: DesplazamientosPorPeriodo
            },
            //contratos
            {
                path: '/reportes/contrato/renuncias-por-periodo',
                component: RenunciasPorPeriodo
            },
            {
                path: '/reportes/contrato/empleados-por-tipo-contrato',
                component: EmpleadoPorTipoDeContrato
            },

            //bienes patrimoniales
            {
                path: '/reportes/bienes-patrimoniales/total-bienes-patrimoniales',
                component: TotalBienesPatrimoniales
            },
            {
                path: '/reportes/bienes-patrimoniales/bienes-patrimoniales-por-servidor',
                component: BienesPatrimonialesPorEmpleado
            },
            {
                path: '/reportes/bienes-patrimoniales/consulta-bien-patrimonial-por-codigo-de-barras',
                component: BienesPatrimonialesPorCodigoBarras
            },

            //desplazamiento de bienes patrimoniales
            {
                path: '/reportes/desplazamientos-bienes-patrimoniales/total-desplazamientos-bienes-patrimoniales',
                component: TotalDesplazamientoBienesPatrimoniales
            },

        ]
    },

]