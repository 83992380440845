
import Viewer from "viewerjs"
import Compressor from 'compressorjs';
require("./../../node_modules/viewerjs/dist/viewer.css");

var inputFile = document.createElement("INPUT");
inputFile.setAttribute("type", "file");
inputFile.setAttribute("id", "input-file-global");
inputFile.setAttribute("accept", '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentatioapplication/vnd.ms-powerpoint,text/plain, application/pdf, image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/mspowerpoint , application/powerpoint, 	application/vnd.ms-powerpoint, application/x-mspowerpoint,. doc, .docx, .ppt, .pptx, .txt, .pdf');

var inputFileImgs = document.createElement("INPUT");
inputFileImgs.setAttribute("type", "file");
inputFileImgs.setAttribute("id", "input-file-global");
inputFileImgs.setAttribute("accept", 'image/*');
inputFileImgs.setAttribute("multiple", "");


var inputFileImg = document.createElement("INPUT");
inputFileImg.setAttribute("type", "file");
inputFileImg.setAttribute("id", "input-file-global");
inputFileImg.setAttribute("accept", 'image/*');

var preview = document.createElement("IMG");

 
function zoomFiles(position, files) { 
    var newDiv = document.createElement("div");
    for (let index = 0; index < files.length; index++) {
      var img = document.createElement("img");
      img.setAttribute(
        "data-original",
        process.env.API_SERVICE_N +
        files[index].name
      );

      img.src =
        process.env.API_SERVICE_N +
        files[index].name

      newDiv.appendChild(img);
    }

    let prev = files.length === 1 ? false : true;
    let next = files.length === 1 ? false : true;
    let navbar = files.length === 1 ? false : true;

    let viewer = new Viewer(newDiv, {
      url: "data-original",
      navbar: navbar,
      initialViewIndex: position,
      movable: false,
      toolbar: {
        zoomIn: true,
        zoomOut: true,
        oneToOne: false,
        reset: false,
        prev: prev,
        play: {
          show: false,
          size: "large"
        },
        next: next,
        rotateLeft: false,
        rotateRight: false,
        flipHorizontal: false,
        flipVertical: false
      }
    });
    viewer.show();
  } 

  function zoomImg(img_new, title= '') { 
    var img = document.createElement("img");
    img.setAttribute("data-original", img_new);
    var newDiv = document.createElement("div");
    newDiv.appendChild(img);

    let viewer = new Viewer(newDiv, {
      url: "data-original",
      movable: false,
      navbar: false,
      title: ()=>{
        return  title
      },
      toolbar: {
        zoomIn: false,
        zoomOut: false,
        oneToOne: false,
        reset: false,
        prev: false,
        play: {
          show: false,
          size: "large"
        },
        next: false,
        rotateLeft: false,
        rotateRight: false,
        flipHorizontal: false,
        flipVertical: false
      }
    });
    viewer.show();
  } 

  async function onFileSelectedMultiple(param) {
    inputFileImgs.value = ""
    let photos_video_names = param.photos_video_names
    let photos_video = param.photos_video
    let photos_video_B64 = param.photos_video_B64
    let user_id = param.user_id
    inputFileImgs.click();
    return new Promise(resolve => {
      inputFileImgs.onchange = async function (e) {
        let files = e.target.files
        let alertMaxFiles = false
        let alertTypeFile = false
        let alertMaxSizeNames = []
        for (let index = 0; index < files.length; index++) {

          if (files[index].size / 1024 < 10241) {
            if (
              files[index].type === "image/png" ||
              files[index].type === "image/jpeg" ||
              files[index].type === "image/svg+xml" ||
              files[index].type === "image/webp"
            ) {
              var state = false;
              for (let indexJ = 0; indexJ < photos_video_names.length; indexJ++) { //Buscar existencia
                if (
                  photos_video_names[indexJ].nameOrigin === files[index].name
                ) {
                  state = true;
                  break;
                }
              }
              if (!state) {
                let hora = new Date().toISOString().replace(/:/g, "-")
                let name = hora + '-' + user_id;
                let size = files[index].size
                let mimetype = files[index].mimetype
                if (photos_video_names.length < 10) {
                  photos_video_names.push({ // nombres
                    nameOrigin: files[index].name,
                    name: name,
                    size: size,
                    mimetype: mimetype
                  });

                  //Pasando a Base y obteniendo imagenes con nuevos tamaños
                  let imglg = await imageToBase64(files[index]); //pasa a b64
                  let imgmd = await resize(files[index], 85, 85);

                  let namelg = "lg-" + name;
                  let namemd = "md-" + name;
                  //Pasando a Binary con nuevos nombres
                  photos_video.push({ //files
                    img: dataURLtoFile(imglg, namelg),
                    imgmd: dataURLtoFile(imgmd, namemd),
                  });

                  photos_video_B64.push({  //formato 64, para mostrar
                    name: name,
                    img: imgmd
                  });
                } else {
                  alertMaxFiles = true
                  break
                }

              }
            } else {
              alertTypeFile = true
            }
          } else {
            alertMaxSizeNames.push({
              name: files[index].name
            })
          }
        }
        let result = {
          alertMaxSizeNames: alertMaxSizeNames, //Archivos que exceden
          alertMaxFiles: alertMaxFiles,//alerta de maximo 10 archivos
          alertTypeFile: alertTypeFile, // alerta de
          //archivos
          photos_video_names: photos_video_names,//nombres
          photos_video: photos_video,//files
          photos_video_B64: photos_video_B64//formato 64
        }
        resolve(result)


      }
    })


  }  

  async function onFileSelectedImg(param = null,compress = 150, max=5120) {
    inputFileImg.value = "" 
    let photos_video_names = ''
    let photos_video = ''
    let photos_video_B64 = ''  
    inputFileImg.click(); 
    return new Promise(resolve => {
    inputFileImg.onchange = async function (e) {
        let files = e.target.files[0] 
        let alertMaxFiles = false
        let alertTypeFile = false
        let alertMaxSize =  false 
          if (files.size / 1024 < max) { // máximo 10mb
            if (
              files.type === "image/png" ||
              files.type === "image/jpeg" ||
              files.type === "image/svg+xml" ||
              files.type === "image/webp"
            ) {
              
                let hora =  Date.now();
                let name = hora  ;
                let size = files.size
                let mimetype = files.mimetype
                 
                  photos_video_names = { // nombres
                    photoOrigin: files.name, 
                    photoName: `${name}.webp`,
                    size: size,
                    mimetype: mimetype
                  }

                  let fileLg =  await  getCompressor(files, { 
                    mimeType: 'image/webp',
                  }, `lg-${name}`); 
                  
                   
                  let fileMd = await getCompressor(files, {
                    quality: 0.5,
                    maxHeight: 300,
                    maxWidth: 300,
                    mimeType: 'image/webp' 
                  },`md-${name}`);
                   
                   photos_video =  {
                    imgLg:  fileLg , 
                    imgMd:  fileMd , 
                  }

                  photos_video_B64 = {  //formato 64, para mostrar
                    name: name,
                    img:  await imageToBase64(files)
                  } 
 
            } else {
              alertTypeFile = true
            }
          } else {
            alertMaxSize = true
          } 

          resolve({
            alertMaxSize : alertMaxSize, //Archivos que exceden
            alertMaxFiles: alertMaxFiles,//alerta de maximo 10 archivos
            alertTypeFile: alertTypeFile, // alerta de
            //archivos
            photos_video_names: photos_video_names,//nombres
            photos_video: photos_video,//files
            photos_video_B64: photos_video_B64//formato 64
          })  
      } 
    })
  } 
 
 
  function imageToBase64(file) {
    var reader = new FileReader();
    return new Promise(resolve => {
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  } 
  
  function getCompressor(file ,someOptions, name){
    return new Promise((resolve, reject) => {
      someOptions.success = (result) => {
        resolve(new File([result], name, {type: someOptions.mimeType}))
      } 
      new Compressor(file, someOptions)
  });
  }

  function _resize(img, maxWidth, maxHeight) {
    var ratio = 1;
    var canvas = document.createElement("canvas");
    canvas.style.display = "none";
    document.body.appendChild(canvas);
    var canvasCopy = document.createElement("canvas");
    canvasCopy.style.display = "none";
    document.body.appendChild(canvasCopy);
    var ctx = canvas.getContext("2d");
    var copyContext = canvasCopy.getContext("2d");
    if (img.width > maxWidth) ratio = maxWidth / img.width;
    else if (img.height > maxHeight) ratio = maxHeight / img.height;
    canvasCopy.width = img.width;
    canvasCopy.height = img.height;
    try {
      copyContext.drawImage(img, 0, 0);
    } catch (e) {
      return false;
    }
    canvas.width = img.width * ratio;
    canvas.height = img.height * ratio;
    // the line to change
    //ctx.drawImage(canvasCopy, 0, 0, canvasCopy.width, canvasCopy.height, 0, 0, canvas.width, canvas.height);
    // the method signature you are using is for slicing
    ctx.drawImage(canvasCopy, 0, 0, canvas.width, canvas.height);
    var dataURL = canvas.toDataURL("image/jpg");
    document.body.removeChild(canvas);
    document.body.removeChild(canvasCopy);
    return dataURL;
  }

  function resize(photo, maxx, maxy) {
    return new Promise(function (resolve) {
      var file = photo;
      var r = new FileReader();
      r.onload = (function (previewImage) {
        return function (e) {
          previewImage.src = e.target.result;
          previewImage.onload = function () {
            var k = _resize(previewImage, maxx, maxy);
            if (k !== false) {
              resolve(k);
            } else {
              resolve(false);
              alert("problem - please attempt to upload again");
            }
          };
        };
      })(preview);
      r.readAsDataURL(file);
    });
  } 


 function dataURLtoFile(data, name) {
    var arr = data.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], name, { type: mime });
  }
  

 async function onFile( max=5120) {
  inputFile.click(); 
  let typeFiles = ["accept",".doc",".docx","application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.presentationml.slideshow","application/vnd.openxmlformats-officedocument.presentationml.presentatioapplication/vnd.ms-powerpoint,text/plain", "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "application/mspowerpoint" , "application/powerpoint", 	"application/vnd.ms-powerpoint", "application/x-mspowerpoint",". doc", ".docx", ".ppt", ".pptx", ".txt", ".pdf", "image/svg+xml", "image/jpeg", "image/png", "image/webp" , "text/plain"]

  return new Promise(resolve => {
    inputFile.onchange = async function (e) {
      let files = e.target.files[0] 
      if (files.size / 1024 < max) { // máximo 10mb 
        if ( typeFiles.includes( files.type )) { 
          
            let hora =  Date.now();
            let name = hora + getFileExtension(files.name) ;
            let size = files.size
            let mimetype = files.type
             
            resolve({ // nombres
                nameOrigin: files.name, 
                name: name,
                size: size,
                mimetype: mimetype,
                alertMaxSize: false,
                alertTypeFile: false,
                file: files
              })
        } else {
          resolve({ // nombres
            nameOrigin: files.name, 
            name: '',
            size: '',
            mimetype: '',
            alertMaxSize: false,
            alertTypeFile: true,
            file: files
          })
        }
      } else {
        resolve({ // nombres
          nameOrigin: files.name, 
          name: '',
          size: '',
          mimetype: '',
          alertMaxSize: true,
          alertTypeFile: false,
          file: files
        })
      }
  }
  })
 } 

 function getFileExtension(filename) {
  return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
 }

 function number_format (number, decimals) {
  let amount = number
  amount += ''; // por si pasan un numero en vez de un string
  amount = parseFloat(amount.replace(/[^0-9\.]/g, '')); // elimino cualquier cosa que no sea numero o punto

  decimals = decimals || 0; // por si la variable no fue fue pasada

  // si no es un numero o es igual a cero retorno el mismo cero
  if (isNaN(amount) || amount === 0) 
      return parseFloat(0).toFixed(decimals);

  // si es mayor o menor que cero retorno el valor formateado como numero
  amount = '' + amount.toFixed(decimals);

  var amount_parts = amount.split('.'),
      regexp = /(\d+)(\d{3})/;

  while (regexp.test(amount_parts[0]))
      amount_parts[0] = amount_parts[0].replace(regexp, '$1' + ',' + '$2');
  let result =number < 0 ? '-' + amount_parts.join('.') : amount_parts.join('.')
  
  return result;
}


export  {
  onFile,
  onFileSelectedImg,
  onFileSelectedMultiple,
  zoomFiles,
  zoomImg, 
  number_format
} 