import React, { useState, useEffect, useRef } from 'react';
import useWindowDimensions from '@hooks/UseHeight';
import { Toast } from 'primereact/toast';
import { useForm, Controller } from 'react-hook-form';
import { InputApp as InputText } from '@components/form/InputText';
import _ from 'lodash';
import { getAssetByBarCode } from '@services/ReportService';
import { Button } from 'primereact/button';


export const BienesPatrimonialesPorCodigoBarrasList = () => {
    const [dataList, setDataList] = useState(null);
    const { control, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
    const { height } = useWindowDimensions();
    //pagination  
    const [inputsselected, setInputsselected] = useState({});
    const toast = useRef(null);
    useEffect(() => {

    }, [])


    const getListFunction = async (param = null) => {
        let result = await getAssetByBarCode(param);
        if (result) {
            const { data } = result;
            setDataList(data);
        } else {
            toast.current.show({ severity: 'error', summary: 'Mensaje', detail: 'No se encontró un bien patrimonial con ese código de barras', life: 4000 });
            setDataList(null);
        }
    }

    const onSubmit = (data) => {
        console.log(data);
        let param = {
            bar_code: data.bar_code
        }
        setInputsselected(param);
        getListFunction(param);
    }

    return (
        <div>

            <div className="grid">

                <div className=" col-12">
                    <Toast ref={toast} />
                    <div className="table-header-container">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid">
                                <div className="md:col-8 col-12 md:flex justify-content-start">
                                    <div className="justify-content-start">
                                        <Controller
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <InputText
                                                        label="Código de Barras"
                                                        onChange={onChange}
                                                        value={value}
                                                        textError={errors.bar_code && errors.bar_code.message}
                                                        errors={_.has(errors, 'bar_code')}

                                                    />
                                                </>
                                            )}
                                            name="bar_code"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Campo requerido."
                                                }
                                            }}
                                            defaultValue=""
                                        />
                                    </div>
                                    <div className="md:ml-4 md:mt-5">
                                        <Button label="Buscar" className="p-button " />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

            {
                dataList && <div className="grid">
                    <div className=" col-12">
                        <div className="field">
                            <h5 font="font-medium">Detalle de bien patrimonial</h5>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_margesi_code">Código Margesi:</label>
                            <p id="d_margesi_code">{dataList.margesi_code} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_patrimonial_code">Código Patrimonial:</label>
                            <p id="d_patrimonial_code">{dataList.patrimonial_code} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_serial_number">Número de Serie:</label>
                            <p id="d_serial_number">{dataList.serial_number} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_bar_code">Código de Barras:</label>
                            <p id="d_bar_code">{dataList.bar_code} </p>
                        </div>
                    </div>

                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_employee">Servidor:</label>
                            <p id="d_employee">{dataList.employee?.name} {dataList.employee?.last_name}</p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_employee">Dependencia:</label>
                            <p id="d_employee">  {dataList.employee?.currentPosting?.office?.dependency?.name}</p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_blueprint">Modelo:</label>
                            {dataList.blueprint && <p id="d_blueprint">{dataList.blueprint.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_brand">Marca:</label>
                            {dataList.brand && <p id="d_brand">{dataList.brand.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_color">Color:</label>
                            {dataList.color && <p id="d_color">{dataList.color.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_asset_state">Estado:</label>
                            {dataList.asset_state && <p id="d_asset_state">{dataList.asset_state.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-12 sm:col-12 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_description">Descripción:</label>
                            <p id="d_description">{dataList.description} </p>
                        </div>
                    </div>

                </div>


            }
        </div>


    )
}