import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import _ from 'lodash';
import { EmpleadosPorSistemaPensionList } from './list';

export const EmpleadosPorSistemaPension = () => {
    const history = useHistory();

    return (
        <div>
            <div className="pb-2">
                <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-text" onClick={() => history.push('/inicio')} tooltip="Regresar a inicio" label="Regresar" />
            </div>
            <div className="datatable-responsive">
                <div className="card">
                    <h5 className="p-m-0">Listado y cantidad de servidores que pertenezcan a AFP, NO AFILIADO, ONP Y PENSIONISTA</h5>
                    <EmpleadosPorSistemaPensionList />
                </div>
            </div>
        </div>
    );
}