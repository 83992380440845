
import axios_client from "@util/axios_client";

export class RegimenLaboralService {

    async getAll(q = null) {
        return await axios_client.get('/api/employmentRegime'
        )
            .then(res => res.data);
    }

}