import React, { useState, useEffect, useLayoutEffect } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import OverlayScrollbars from 'overlayscrollbars';
import { AppTopbar } from '@layouts/admin/AppTopbar';
import { AppFooter } from '@layouts/admin/AppFooter';
import { AppMenu } from '@layouts/admin/AppMenu';
import { AppConfig } from '@layouts/admin/AppConfig'; 

import PrimeReact, { addLocale, locale } from 'primereact/api'; 
import menu from '../../data/sidebar.json'
import { useHistory } from "react-router-dom";
import _ from "lodash"
import { useSelector } from 'react-redux';

// const instance = () => { //convertirlo en hook
//     const dispatch = useDispatch();
//     const setButtonTop = (payload) => dispatch({ payload, type: "SETVIEWSCROLL" });

//     return OverlayScrollbars(document.body, {
//         className: "os-theme-thick-dark",
//         nativeScrollbarsOverlaid: {
//             initialize: false
//         },

//         callbacks: {
//             onScroll: (e) => {
//                 setButtonTop(e.target.offsetHeight + e.target.scrollTop + 100 >= e.target.scrollHeight ? true : false)
//             },
//         }
//     }); 
// }



const App = ({ children }) => {
    const view = useSelector(state => state.sidebar.view);
    const animation = useSelector(state => state.sidebar.animation);
    useLayoutEffect(()=> {
        if(animation) {
            setTransitionSidebar(true)
        }
        else {
            setTimeout(() => {
                setTransitionSidebar(true)
            }, 300);
        } 
    },[])
    useEffect(()=>{  
        onToggleMenuClick()
       
    }, [view])
    // const dispatch = useDispatch();
    // const buttonTop = useSelector(state => state.scroll.view);
    // const setButtonTop = (payload) => dispatch({ payload, type: "SETVIEWSCROLL" });

    OverlayScrollbars(document.body, {
        className: "os-theme-thick-dark",
        nativeScrollbarsOverlaid: {
            initialize: false
        }
    });
    let history = useHistory();
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['DO', 'LU', 'MA', 'MI', 'JU', 'VI', 'SA'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Claro'
    });

    locale('es');

    const items = [
        { label: 'Computer' },
        { label: 'Notebook' },
        { label: 'Accessories' },
        { label: 'Backpacks' },
        { label: 'Item' }
    ];

    const home = { icon: 'pi pi-home', command: () => history.push('/inicio') }

 

    const [transitionSidebar, setTransitionSidebar] = useState(false);
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(true); //ocultar sidebar
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);

    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    // useEffect(() => {
    //     setTimeout(() => {
    //         setViewBtnTop(true)
    //     }, 2000);
    // })



    // useEffect(() => {
    //     if (typeof windows !== undefined) {
    //         const toggleVisibility = () => {
    //             setButtonTop(window.pageYOffset + window.screen.availHeight + 100 >= document.body.scrollHeight ? true : false)
    //         };

    //         window.addEventListener("scroll", toggleVisibility);
    //     }

    // }, [])

    useEffect(() => {
        if (mobileMenuActive) { 
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    // const scrollTop = () => {
    //     instance.scroll([0, 0], 800);
    // }

    // const scrollTopSm = () => {
    //     window.scrollTo({
    //         top: 0,
    //         left: 0,
    //         behavior: 'smooth'
    //     });
    // }


    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);

        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = () => {
        menuClick = true; 
        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
          
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }
        setStaticMenuInactive(view)
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
      
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
      
        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
            
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 991;
    }



    const addClass = (element, className) => {
        
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => { 
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar  layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="app-sidebar">
                <div onClick={onSidebarClick}>
                    <OverlayScrollbarsComponent className={`layout-sidebar ${transitionSidebar && 'transition-sidebar'}`}
                        options={{ scrollbars: { autoHide: 'move' } }}>
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                    </OverlayScrollbarsComponent>
                </div>
            </div>

            <div className="layout-main-container"> 
                {children}
                <AppFooter layoutColorMode={layoutColorMode} />
                {/* {
                    <div className={viewBtnTop ? 'visibility-visible' : 'visibility-hidden'}>
                        <div
                            className={`fixed pb-1 pr-2 ${buttonTop ? 'animate__animated animate__fadeIn' : 'animate__animated animate__fadeOut'}`} >
                            <div className="sm:block hidden">
                                <Button icon="pi pi-chevron-up" onClick={scrollTop} className="p-button-rounded p-button-primary" />
                            </div>
                            <div className="sm:hidden block">
                                <Button icon="pi pi-chevron-up" onClick={scrollTopSm} className="p-button-rounded p-button-primary" />
                            </div>
                        </div>
                    </div>
                } */}
            </div>

            <AppConfig 
                rippleEffect={ripple} 
                onRippleEffect={onRipple} 
                inputStyle={inputStyle} 
                onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} 
                onLayoutModeChange={onLayoutModeChange} 
                layoutColorMode={layoutColorMode} 
                onColorModeChange={onColorModeChange} />
 
                <div className="layout-mask p-component-overlay"></div> 
        </div>
    );

}

export default App;
