
import axios_client from "../util/axios_client";

export class UsersService { 
    async getAllUsers(page, itemsPerPage, q = null, sort) {
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (q) {
            url = url + "&filter=" + q;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        return await axios_client.get('/api/user' + url
        )
            .then(res => res.data);
    }

    async setCreateUser(param) {

        return await axios_client.post(`/api/user`, param)
            .then(res => res.data).catch((error) => {
                return error.response;
            })

    }

    async setUpdateUser(param, id) {
        return await axios_client.put(`/api/user/${id}`, param)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }
 

    async getUserDataRole() {
        try {
            let result = await axios_client.get('/api/user/data') ;
            return  result.data.data.roles
        } catch (error) {
            return false;
        }
    }

    async setDeleteUser(id) {
        return await axios_client.delete(`/api/user/${id}`)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

    async setDeleteMultipleUser(param) {
        return await axios_client.delete(`/api/user/destroyMultiple`, {
            data: {
                ids: param
            }
        })
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

   

}
