import React, { useState, useEffect, useContext, useCallback } from 'react';
import useWindowDimensions from '@hooks/UseHeight';
import { useForm, Controller } from 'react-hook-form';
import { AutoCompleteApp as AutoComplete } from '@components/form/Autocomplete';
import { Ripple } from 'primereact/ripple';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { Label } from '@components/form/Label';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { getDataSheetEmploye } from '@services/ReportService';
import { EmployesService } from '@services/EmployesService'
import { CalendarApp as Calendar } from '@components/form/Calendar';
import { formatCalendar, getDateFormat } from '@util/date';
import { Dropdown as DropdownPagination } from 'primereact/dropdown';
import FileSaver from 'file-saver';

export const FichaEmpleadoList = () => {
    const employesService = new EmployesService();
    const [dataList, setDataList] = useState(null);
    const [loadingList, setLoadingList] = useState(false);
    const { control, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
    const { height } = useWindowDimensions();
    //pagination 
    const [rows, setRows] = useState(10);
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [inputsselected, setInputsselected] = useState({});

    //Filter 
    const [employes, setEmployes] = useState([]);
    const [porcentage, setPorcentage] = useState('');


    useEffect(() => {
        getEmploye()
        //getListFunction(currentpage, rows);
    }, [])

    const getEmploye = async (search) => {
        let data = await employesService.getAll(search);
        setEmployes(data.data.map(param => {
            return {
                name: `${param.name} ${param.last_name}`,
                code: param.id
            }
        }));
    }

    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(dataList) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(dataList) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(dataList) ? <DropdownPagination value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },

    }

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getListFunction(event.page + 1, event.rows, null, null, inputsselected);
    }

    const progressUpload = (porcentage) => {
        if (isFinite(porcentage)) setPorcentage(`${porcentage}%`)
        if (porcentage === 100) setPorcentage('')
    }

    const getListFunction = async (page, row, search = null, sort = null, param = null) => {
        let result = await getDataSheetEmploye(param, progressUpload);
        if (result) {
            if (param.type === 'query') {
                console.log(result);
                const { data } = result;
                setDataList(data);
            } else {
                FileSaver.saveAs(result, `Ficha personal de empleado - ${getDateFormat()}.xlsx`)
            }
        }
    }

    const sortFunction = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }


        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }

        getListFunction(currentpage, rows, null, string);
    }

    const searchEmploye = useCallback(
        _.debounce(async ({ query }) => {
            getEmploye(query);
        }, 300),
        [],
    );

    const onSubmit = (data) => {
        let param = {
            employee_id: data.employesSelect.code,
            type: 'query'
        }
        setInputsselected(param);
        getListFunction(currentpage, rows, null, null, param);
    }

    const exportExcel = () => {
        let param = {
            employee_id: inputsselected.employee_id,
            type: 'excel'
        }
        if (inputsselected.employee_id) {
            getListFunction(currentpage, rows, null, null, param);
        }
    }


    return (
        <div>

            <div className="grid">
                <div className=" col-12">
                    <div className="table-header-container">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid">
                                <div className="md:col-8 col-12 md:flex justify-content-start">
                                    <div className="justify-content-start">
                                        <Controller
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <>
                                                    <AutoComplete label="Servidor"
                                                        value={value} suggestions={employes}
                                                        completeMethod={searchEmploye} field="name" dropdown forceSelection
                                                        textError={errors.employesSelect && errors.employesSelect.message}
                                                        errors={_.has(errors, 'employesSelect')}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </>
                                            )}
                                            name="employesSelect"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Campo requerido."
                                                }
                                            }}
                                            defaultValue=""
                                        />
                                    </div>
                                    <div className="md:ml-4 md:mt-5">
                                        <Button label="Buscar" className="p-button " />
                                    </div>
                                </div>
                                <div className="md:col-4 col-12 ">
                                    <div className=" md:block hidden">
                                        <div className="flex md:justify-content-end">
                                            <Button type="button" label={`Exportar ${porcentage}`} icon="pi pi-file-excel" className="p-button " onClick={exportExcel} />
                                        </div>
                                    </div>
                                    <div className="md:hidden block ">
                                        <div className="flex flex-wrap justify-content-between w-full">
                                            <Button type="button" icon="pi pi-file-excel" className="p-button" onClick={exportExcel} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

            {
                dataList && <div className="grid">
                    <div className=" col-12">
                        <div className="field">
                            <h5 font="font-medium">Ficha de servidor</h5>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_dni">DNI:</label>
                            <p id="d_dni">{dataList.dni} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_name">Nombres:</label>
                            <p id="d_name">{dataList.name} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_last_name">Apellidos:</label>
                            <p id="d_last_name">{dataList.last_name} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_birth_date">Fecha de Nacimiento:</label>
                            <p id="d_birth_date">{dataList.birth_date} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_private_email">Correo Personal:</label>
                            <p id="d_private_email">{dataList.private_email} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_phone">Celular:</label>
                            <p id="d_phone">{dataList.phone} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_address">Dirección:</label>
                            <p id="d_address">{dataList.address} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_sex">Sexo:</label>
                            {dataList.sex && <p id="d_sex">{dataList.sex.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_civil_status">Estado Civil:</label>
                            {dataList.civil_status && <p id="d_civil_status">{dataList.civil_status.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_has_chidlren">¿Tiene Hijos?:</label>
                            {dataList.hasChildren ? <p>Si</p> : <p> No </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_had_covid">¿Ha tenido covid?:</label>
                            {dataList.hadCovid ? <p>Si</p> : <p> No </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_blood_type">Grupo Sanguíneo:</label>
                            {dataList.blood_type && <p id="d_blood_type">{dataList.blood_type.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_comorbidity">Comorbilidad:</label>
                            {dataList.comorbidity && <p id="d_comorbidity">{dataList.comorbidity.name} </p>}
                        </div>
                    </div>

                    <div className=" col-12">
                        <div className="field">
                            <h5 font="font-bold">Datos Empresa</h5>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_company_email">Correo Institucional:</label>
                            <p id="d_company_email">{dataList.company_email} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_profession">Profesión:</label>
                            <p id="d_profession">{dataList.profession} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_pension_system">Sistema de Pensiones:</label>
                            {dataList.pension_system && <p id="d_pension_system">{dataList.pension_system.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_currentContract">Fecha de Inicio de Contrato:</label>
                            {dataList.currentContract && <p id="d_currentContract">{dataList.currentContract.start_date} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_currentContractF">Fecha de Fin de Contrato:</label>
                            {dataList.currentContract && <p id="d_currentContractF">{dataList.currentContract.end_date} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_call_number">Número de Convocatoria:</label>
                            {dataList.currentContract && <p id="d_call_number">{dataList.currentContract.call_number} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_type_contract">Tipo de Contrato:</label>
                            {dataList.currentContract && dataList.currentContract.contract && <p id="d_type_contract">{dataList.currentContract.contract.type} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_contract_number">Número de Contrato:</label>
                            {dataList.currentContract && <p id="d_contract_number">{dataList.currentContract.contract_number} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_cargo">Cargo:</label>
                            {dataList.currentContract && dataList.currentContract.position && <p id="d_cargo">{dataList.currentContract.position.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_dependencia">Dependencia:</label>
                            {dataList.currentPosting && dataList.currentPosting?.office && <p id="d_dependencia">{dataList.currentPosting?.office?.dependency?.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_oficina">Oficina:</label>
                            {dataList.currentPosting && dataList.currentPosting.office && <p id="d_oficina">{dataList.currentPosting.office.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label className="font-medium" htmlFor="d_employment_regime">Régimen Laboral:</label>
                            {dataList.currentContract && dataList.currentContract.employment_regime && <p id="d_employment_regime">{dataList.currentContract.employment_regime.name} </p>}
                        </div>
                    </div>
                </div>


            }
        </div>


    )
}