import React, { useState, useEffect, useCallback } from 'react';
import useWindowDimensions from '@hooks/UseHeight';
import { useForm, Controller } from 'react-hook-form';
import { Ripple } from 'primereact/ripple';
import { CalendarApp as Calendar } from '@components/form/Calendar';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { getListAssetTransferAll, getDownloadPdfTransfer } from '@services/ReportService';
import { getDateFormat } from '@util/date';
import { Dropdown as DropdownPagination } from 'primereact/dropdown';
import FileSaver from 'file-saver';
import { formatDate } from '@util/date';

export const TotalDesplazamientoBienesPatrimonialesList = () => {
    const [dataList, setDataList] = useState([]);
    const [loadingList, setLoadingList] = useState(false);
    const { control, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
    const datewatch = watch("date_selected");
    const { height } = useWindowDimensions();
    //pagination 
    const [rows, setRows] = useState(10);
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [inputsselected, setInputsselected] = useState({ type: 'query' });

    //Filter 
    const [regimens, setRegimens] = useState([]);
    const [porcentage, setPorcentage] = useState('');


    useEffect(() => {
        getListFunction(1, rows, null, null, inputsselected);
    }, [])

    const bodyTemplate = (data, props) => {
        return (
            <div className="h-3rem flex   flex-wrap">
                <div className="flex align-items-center">{data[props.field]}</div>
            </div>
        );
    };

    const bodyTemplateApplicant = (data, props) => {
        return (
            <div className="h-3rem flex   flex-wrap">
                <div className="flex align-items-center">{data.applicant?.name} {data.applicant?.last_name}</div>
            </div>
        );
    };

    const bodyTemplateInCharge = (data, props) => {
        return (
            <div className="h-3rem flex   flex-wrap">
                <div className="flex align-items-center">{data.in_charge?.name} {data.in_charge?.last_name}</div>
            </div>
        );
    };

    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(dataList) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(dataList) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                        onClick={() => getPagination({
                            page: index,
                            rows: rows
                        })}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(dataList) ? <DropdownPagination value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },

    }

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getListFunction(event.page + 1, event.rows, null, null, inputsselected);
    }

    const progressUpload = (porcentage) => {
        if (isFinite(porcentage)) setPorcentage(`${porcentage}%`)
        if (porcentage === 100) setPorcentage('')
    }

    const getListFunction = async (page, row, search = null, sort = null, param = null) => {
        let result = await getListAssetTransferAll(page, row, search, sort, param, progressUpload);
        if (result) {
            const { data, meta } = result;
            setDataList(data);
            setCurrentpage(meta.current_page);
            setTotalrecords(meta.total);
            setLastpage(meta.last_page);

        }
    }

    const sortFunction = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }


        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }

        getListFunction(currentpage, rows, null, string);
    }

    const getPagination = async (event) => {
        setCurrentpage(event.page + 1);
        getListFunction(event.page + 1, event.rows, null, null, inputsselected);
    }

    const onSubmit = (data) => {
        let param = {
            type: 'query'
        }
        setInputsselected(param);
        getListFunction(currentpage, rows, null, null, param);
    }

    const downloadPdf = async (data) => {
        let param = {
            type: 'PDF',
            transfer_id: data.id,
            date: (datewatch ? formatDate(datewatch, 'yyyy-MM-dd') : ''),
        }
        let result = await getDownloadPdfTransfer(param, progressUpload);
        if (result) {
            if (param.type === 'PDF') {
                FileSaver.saveAs(result, `Desplazamiento de bien patrimonial - ${getDateFormat()}.pdf`)
            } else {
            }
        }
    }


    const option = (data) => {
        return (
            <>
                <div className="flex justify-content-center flex-wrap ">
                    <div className="flex align-items-center justify-content-center ">

                        <Button disabled={!datewatch} className="    p-button-sm  p-button-danger  mr-2" icon="pi pi-file-pdf" onClick={() => downloadPdf(data)} />
                    </div>
                </div>
            </>
        )
    }

    const searchGlobalElements = useCallback(
        _.debounce(async (value) => {
            let param = {
                type: 'query'
            }
            getListFunction(currentpage, rows, value, param);
        }, 600),
        [],
    );

    const header = (
        <div className="table-header-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid">
                    <div className="md:col-5 col-12">
                        <div className="table-header">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText type="search" onInput={(e) => searchGlobalElements(e.target.value)} placeholder="Buscar..." />
                            </span>
                        </div>
                    </div>
                    <div className="md:col-7 col-12  ">
                        <div className="flex justify-content-end" style={{ marginTop: '-10px' }}>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Calendar
                                            placeholder="Fecha" 
                                            onChange={onChange}
                                            value={value} />
                                    </>
                                )}
                                name="date_selected"
                                type="date"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );

    return (
        <DataTable
            showGridlines
            className="p-datatable-responsive"
            value={dataList}
            dataKey="id"
            paginator
            loading={loadingList}
            onPage={onCustomPage}
            rows={rows}
            paginatorTemplate={templatePaginator}
            emptyMessage="No se encontraron resultados"
            currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} bienes patrimoniales"
            scrollable
            scrollHeight={`${height - 500}px`}
            header={header}
            sortMode="multiple"
            multiSortMeta={multiSortMeta}
            onSort={e => sortFunction(e.multiSortMeta)}
            sortOrder={multiSortMeta}
        >
            <Column field="code" header="Código" sortable body={bodyTemplate} columnKey="code"></Column>
            <Column field="applicant" header="Solicitante" sortable body={bodyTemplateApplicant} columnKey="applicant"></Column>
            <Column field="in_charge" header="Responsable del traslado" sortable body={bodyTemplateInCharge} columnKey="in_charge"></Column>
            <Column field="departure_date" header="Fecha de salida" sortable body={bodyTemplate} columnKey="departure_date"></Column>
            <Column field="return_date" header="Fecha de retorno" sortable body={bodyTemplate} columnKey="return_date"></Column>
            <Column headerStyle={{ width: '10rem' }} body={option} className="text-center"></Column>
        </DataTable>
    )
}