import React, { useContext, useEffect,useState } from 'react';
import router from './router';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '@styles/flags/flags.css';
import '@styles/layout.scss';
import '@styles/App.scss';
import { store, persistor } from '@store/index'
import { Provider } from 'react-redux';
import { Route, useLocation, useHistory, Switch } from 'react-router-dom';
import { Login } from "@pages/Login";
import { Context } from '@context/Auth';
import { PersistGate } from 'redux-persist/integration/react'
import Cookies from 'js-cookie';

const App = () => {
  let location = useLocation();
  let history = useHistory();
  const [dataUser,setDataUser] = useState({updated_at: 'loading'  });
  useEffect(() => { 
    if(Cookies.get('app_user')) setDataUser(JSON.parse(Cookies.get('app_user')))
    
  }, [])
  const context = useContext(Context)

  useEffect(() => { 
    if ( context.store.auth) { 
    if(dataUser.updated_at === dataUser.created_at && dataUser.created_at !== 'loading')  {history.push("/usuario/mi-perfil");}
    }
  }, [ location.pathname, dataUser])

  useEffect(() => {
    function getAuth() { 
    
      if (!context.store.auth && location.pathname !== '/') {
        history.push("/");
      }
      if (location.pathname === '/' && context.store.auth) {
        history.push("/inicio");
      }
    }
    getAuth()
  }, [context.store.auth, history, location.pathname])


  useEffect(() => {
    function getAuth() {
    }

    return () => {
      getAuth()
    }
  }, [history])

  if (!context.store.auth) return <Route path="/" component={Login} />
  //if (!router.some(param=>param.path === location.pathname)) return <Route path="*" component={NotFound} />

  function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        render={props => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    );
  }

  return (
    <>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Switch>
          {router.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
        </PersistGate>
      </Provider>
    </>
  )
}

export default App;
