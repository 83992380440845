

import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import scroll from './reducers/scroll';
import sidebar from './reducers/sidebar';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  scroll,
  sidebar
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['scroll'],
  whitelist: ['sidebar']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
let store = createStore(persistedReducer)
const persistor = persistStore(store);

export { store, persistor };