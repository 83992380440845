import React from 'react';
import PropTypes from 'prop-types'
import { Label } from './Label';
import { Dropdown } from 'primereact/dropdown';

export const DropdownApp = (props) => {
  const { errors, textError, label, onChange, value, placeholder, options, optionLabel, editable, filter } = props;


  return (
    <>
      <div className="py-2 w-full">
        <Label>{label}</Label>
        <div className="p-field pt-1">
          <Dropdown filter={filter} value={value} options={options} onChange={onChange} optionLabel={optionLabel} placeholder={placeholder} editable={editable} />
          {errors &&
            <div>  <small className="w-full text-red-700 label-app">{textError}</small></div>
          }
        </div>
      </div>
    </>
  )
};

DropdownApp.defaultProps = {
  errors: false,
  textError: '',
  label: '',
  value: null,
  optionLabel: '',
  editable: false,
  placeholder: '',
  options: [],
  filter: false
};
DropdownApp.propTypes = {
  errors: PropTypes.bool,
  onChange: PropTypes.func,
  textError: PropTypes.string,
  editable: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  optionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  filter: PropTypes.bool
}
