import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Label } from '@components/form/Label';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import useWindowDimensions from '@hooks/UseHeight'
import { MenuApp as Menu } from '@components/menu';
import { EmployesService } from "@services/EmployesService";
import { useMediaQuery } from 'react-responsive';
import { useHistory } from "react-router-dom";
import _ from 'lodash';

export const Listado = () => {
    const history = useHistory();
    const [selectedEmployes, setSelectedEmployes] = useState(null);
    const [selectedEmployesId, setSelectedEmployesId] = useState([]);
    const screenLg = useMediaQuery({ query: '(min-width: 480px)' })
    const toast = useRef(null);
    const { height } = useWindowDimensions();
    const [employe, setEmploye] = useState({});
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [employes, setEmployes] = useState({ total_enabled: 0 });
    const [loadingDeleteIds, setLoadingDeleteIds] = useState(false);
    const [loadingAllEmployes, setLoadingAllEmployes] = useState(true);
    const [deleteEmployeDialog, setDeleteEmployeDialog] = useState(false);
    const [showEmployeDialog, setShowEmployeDialog] = useState(false);
    const [deleteEmployesDialog, setDeleteEmployesDialog] = useState(false);
    const employesService = new EmployesService();
    //pagination 
    const [rows, setRows] = useState(10);
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);

    useEffect(() => {
        async function getInit() {
            setLoadingAllEmployes(true)
            getAllEmployesFunction(currentpage, rows);

        }
        getInit();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const bodyTemplate = (data, props) => {
        return (
            <div className="h-3rem flex   flex-wrap">
                <div className="flex align-items-center">{data[props.field]}</div>
            </div>
        );
    };

    const bodyTemplateEstado = (data, props) => {
        return (
            <div className="h-3rem flex   flex-wrap">
                <div className="flex align-items-center">{(data[props.field] === 1 ? 'Habilitado' : 'Deshabilitado')}</div>
            </div>
        );
    };

    const getAllEmployesFunction = async (page, row, q = null, sort = null) => {
        await employesService.getAllEmployes(page, row, q, sort).then(data => {
            if (!_.isEmpty(data.meta)) {
                setCurrentpage(data.meta.current_page);
                setTotalrecords(data.meta.total);
                setEmployes(data);
                setLastpage(data.meta.last_page);
            }

        });

        setLoadingAllEmployes(false)
    }

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getAllEmployesFunction(event.page + 1, event.rows);
    }

    const getPaginationEmploye = async (event) => {
        setCurrentpage(event.page + 1);
        getAllEmployesFunction(event.page + 1, event.rows);
    }

    const sortFunctionEmployes = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }


        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }

        getAllEmployesFunction(currentpage, rows, null, string);
    }

    const searchGlobalEmployes = useCallback(
        _.debounce(async (value) => {
            getAllEmployesFunction(currentpage, rows, value);
        }, 600),
        [],
    );

    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(employes.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(employes.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                        onClick={() => getPaginationEmploye({
                            page: index,
                            rows: rows
                        })}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(employes.data) ? <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },

    }
    const productsTableHeader = (
        <div className="table-header-container">
            <div className="grid">
                <div className="md:col-5 col-12">
                    <div className="table-header">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText type="search" onInput={(e) => searchGlobalEmployes(e.target.value)} placeholder="Buscar..." />
                        </span>
                    </div>
                </div>
                <div className="md:col-7 col-12 ">
                    <div className=" md:block hidden">
                        <div className="flex md:justify-content-end">
                            <Button label="Nuevo" icon="pi pi-plus" className="p-button mr-2" onClick={() => history.push('/empleado/registro')} />
                            <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger mr-2" onClick={() => confirmDeleteSelected()} disabled={!selectedEmployes || !selectedEmployes.length} />
                            {/*<Button label="Exportar" icon="pi pi-file-excel" className="p-button"   />*/}
                        </div>
                    </div>
                    <div className="md:hidden block ">
                        <div className="flex flex-wrap justify-content-between w-full">
                            <Button icon="pi pi-plus" className="p-button mr-2" onClick={() => history.push('/empleado/registro')} />
                            <Button icon="pi pi-trash" className="p-button-danger mr-2" onClick={() => confirmDeleteSelected()} disabled={!selectedEmployes || !selectedEmployes.length} />
                            {/*<Button icon="pi pi-file-excel" className="p-button"   />*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const hideDeleteEmployeDialog = () => {
        setDeleteEmployeDialog(false);
    }

    const hideDeleteEmployesDialog = () => {
        setDeleteEmployesDialog(false);
    }

    const confirmDeleteEmploye = (employe) => {
        setEmploye(employe);
        setDeleteEmployeDialog(true);
    }

    const showEmploye = (employe) => {
        setEmploye(employe);
        setShowEmployeDialog(true);
    }

    const deleteEmploye = async () => {
        let result = await employesService.setDeleteEmploye(employe.id)

        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        setDeleteEmployeDialog(false);
        setEmploye({});
        getAllEmployesFunction(currentpage, rows);
    }
    const confirmDeleteSelected = () => {
        let ids = [];
        for (let i = 0; i < selectedEmployes.length; i++) {
            ids.push(selectedEmployes[i].id);
        }
        console.log(ids);
        setSelectedEmployesId(ids);
        setDeleteEmployesDialog(true);
    }

    const deleteSelectedEmployes = async () => {
        setLoadingDeleteIds(true)
        let result = await employesService.setDeleteMultipleEmploye(selectedEmployesId)
        getAllEmployesFunction(currentpage, rows);
        setLoadingDeleteIds(false)
        setDeleteEmployesDialog(false);
        setSelectedEmployes(null);
        setSelectedEmployesId([]);
        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
    }

    const productStatusBodyTemplate = (data) => {
        return (
            <div className="h-3rem flex   flex-wrap">
                {/* <span className="p-column-title">Status</span> */}
                <div className="flex align-items-center">
                    <span className={`product-badge status-${data.inventoryStatus.toLowerCase()}`}>{data.inventoryStatus}</span>
                </div>
            </div>
        );
    };

    const hideDialog = () => {
        setShowEmployeDialog(false);
    }


    const getItemsData = (id) => {
        return [
            {
                label: 'Horarios',
                icon: 'pi pi-refresh',
                command: () => {
                    history.push(`/empleado/horarios/${id}`)
                }
            },
            {
                label: 'Permisos',
                icon: 'pi pi-refresh',
                command: () => {
                    history.push(`/empleado/permisos/${id}`)
                }
            },
            {
                label: 'Vacaciones',
                icon: 'pi pi-refresh',
                command: () => {
                    history.push(`/empleado/vacaciones/${id}`)
                }
            },
            {
                label: 'Detalles de contratos',
                icon: 'pi pi-external-link',
                command: () => {
                    history.push(`/empleado/detalles-contratos/${id}`)
                }
            },
            {
                label: 'Desplazamientos',
                icon: 'pi pi-external-link',
                command: () => {
                    history.push(`/empleado/desplazamientos/${id}`)
                }
            },
            {
                label: 'Discapacidades',
                icon: 'pi pi-external-link',
                command: () => {
                    history.push(`/empleado/discapacidades/${id}`)
                }
            },
            {
                label: 'Carga familiar',
                icon: 'pi pi-external-link',
                command: () => {
                    history.push(`/empleado/carga-familiar/${id}`)
                }
            },
            {
                label: 'Bienes Patrimoniales',
                icon: 'pi pi-external-link',
                command: () => {
                    history.push(`/empleado/bienes-patrimoniales/${id}`)
                }
            }
        ]
    }


    const option = (data) => {
        const dataMenu = getItemsData(data.id)

        return (
            <>

                <div className="flex justify-content-center flex-wrap ">
                    <div className="flex align-items-center justify-content-center ">

                        <Menu data={dataMenu} />
                        <Button className="p-button-text p-button-rounded p-button-sm p-button-outlined" icon="pi pi-user-edit" onClick={(event) => history.push('/empleado/editar/' + data.id)} />
                        <Button className="p-button-text p-button-rounded p-button-sm p-button-outlined" icon="pi pi-trash" onClick={() => confirmDeleteEmploye(data)} />
                        <Button className="p-button-text p-button-rounded p-button-sm p-button-outlined" icon="pi pi-eye" onClick={() => showEmploye(data)} />
                    </div>
                </div>
            </>
        )
    }

    const deleteEmployeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteEmployeDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteEmploye} />
        </>
    );
    const deleteEmployesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteEmployesDialog} />
            <Button label={loadingDeleteIds ? '...Cargando' : 'Si'} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedEmployes} />
        </>
    );

    const elementDialogFooter = (
        <>
            <Button label="Aceptar" icon="pi pi-check" onClick={hideDialog} />

        </>
    );
    return (
        <div className="card">
            <h5>Lista de servidores - <strong>{employes?.total_enabled <= 1 ? employes?.total_enabled + ' habilitado' : employes?.total_enabled + ' habilitados'} </strong> </h5>
            <Toast ref={toast} />
            <DataTable
                scrollable
                frozenWidth={screenLg ? "350px" : "0px"}
                scrollHeight={`${height - 388}px`}
                value={employes.data}
                paginator
                loading={loadingAllEmployes}
                onPage={onCustomPage}
                rows={rows}
                paginatorTemplate={templatePaginator}
                emptyMessage="No se encontraron resultados"
                currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} servidores"
                dataKey="id"
                showGridlines
                selection={selectedEmployes}
                onSelectionChange={(e) => setSelectedEmployes(e.value)}
                header={productsTableHeader}
                sortMode="multiple"
                multiSortMeta={multiSortMeta}
                onSort={e => sortFunctionEmployes(e.multiSortMeta)}
                sortOrder={multiSortMeta}
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} frozen={screenLg}></Column>
                <Column field="option" header="Opciones" headerStyle={{ width: '170px' }} columnKey="option" body={option} frozen={screenLg}></Column>
                <Column field="dni" header="DNI" headerStyle={{ width: '300px' }} columnKey="dni" sortable body={bodyTemplate} frozen={screenLg}></Column>
                <Column field="name" header="Nombres" sortable body={bodyTemplate} headerStyle={{ width: '300px' }} columnKey="name"></Column>
                <Column field="last_name" header="Apellidos" sortable body={bodyTemplate} headerStyle={{ width: '300px' }} columnKey="last_name"></Column>
                <Column field="phone" header="Celular" sortable body={bodyTemplate} headerStyle={{ width: '300px' }} columnKey="phone"></Column>
                <Column field="company_email" header="Correo Institucional" sortable body={bodyTemplate} headerStyle={{ width: '300px' }} columnKey="company_email"></Column>
                <Column field="enabled" header="Estado" sortable body={bodyTemplateEstado} headerStyle={{ width: '300px' }} columnKey="enabled"></Column>
            </DataTable>

            <Dialog
                visible={showEmployeDialog}
                style={{ width: '550px' }}
                breakpoints={{ '4096px': '800px', '1300px': '800px', '960px': '700px', '640px': '550px', '575px': '450px' }}
                header={`Detalle de Servidor`}
                modal
                className="p-fluid"
                footer={elementDialogFooter}
                onHide={hideDialog}
            >
                <div className="grid">
                    <div className=" col-12">
                        <div className="field">
                            <Label font="font-medium">Datos Personales</Label>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12">
                        <div className="field">
                            <label htmlFor="d_dni">DNI:</label>
                            <p id="d_dni">{employe.dni} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12">
                        <div className="field">
                            <label htmlFor="d_name">Nombres:</label>
                            <p id="d_name">{employe.name} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_last_name">Apellidos:</label>
                            <p id="d_last_name">{employe.last_name} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_birth_date">Fecha de Nacimiento:</label>
                            <p id="d_birth_date">{employe.birth_date} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_private_email">Correo Personal:</label>
                            <p id="d_private_email">{employe.private_email} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_phone">Celular:</label>
                            <p id="d_phone">{employe.phone} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_address">Dirección:</label>
                            <p id="d_address">{employe.address} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_sex">Sexo:</label>
                            {employe.sex && <p id="d_sex">{employe.sex.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_civil_status">Estado Civil:</label>
                            {employe.civil_status && <p id="d_civil_status">{employe.civil_status.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_has_chidlren">¿Tiene Hijos?:</label>
                            {employe.hasChildren ? <p>Si</p> : <p> No </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_had_covid">¿Ha tenido covid?:</label>
                            {employe.hadCovid ? <p>Si</p> : <p> No </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_blood_type">Grupo Sanguíneo:</label>
                            {employe.blood_type && <p id="d_blood_type">{employe.blood_type.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_comorbidity">Comorbilidad:</label>
                            {employe.comorbidity && <p id="d_comorbidity">{employe.comorbidity.name} </p>}
                        </div>
                    </div>

                    <div className=" col-12">
                        <div className="field">
                            <Label font="font-medium">Datos Empresa</Label>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_company_email">Correo Institucional:</label>
                            <p id="d_company_email">{employe.company_email} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_profession">Profesión:</label>
                            <p id="d_profession">{employe.profession} </p>
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_pension_system">Sistema de Pensiones:</label>
                            {employe.pension_system && <p id="d_pension_system">{employe.pension_system.name} </p>}
                        </div>
                    </div>
                    <div className="md:col-4 sm:col-6 col-12 ">
                        <div className="field">
                            <label htmlFor="d_had_covid">Estado:</label>
                            {employe.enabled ? <p>Habilitado</p> : <p> Deshabilitado </p>}
                        </div>
                    </div>

                </div>




            </Dialog>


            <Dialog
                visible={deleteEmployeDialog}
                style={{ width: '480px' }}
                header="Eliminar Servidor"
                modal
                footer={deleteEmployeDialogFooter}
                onHide={hideDeleteEmployeDialog}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {employe && <span>¿Está seguro de eliminar al servidor <b>{employe.name}</b>?</span>}
                </div>
            </Dialog>

            <Dialog
                visible={deleteEmployesDialog}
                style={{ width: '480px' }}
                header="Eliminar servidores"
                modal
                footer={deleteEmployesDialogFooter}
                onHide={hideDeleteEmployesDialog}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {employe && <span>¿Está seguro de eliminar a los servidores seleccionados?</span>}
                </div>
            </Dialog>
        </div>
    )
}
