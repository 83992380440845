
import axios_client from "@util/axios_client";

export class EmployesService {

    async getAllEmployes(page, itemsPerPage, q = null, sort) {
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (q) {
            url = url + "&filter=" + q;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        return await axios_client.get('/api/employee' + url
        )
            .then(res => res.data);
    }

    async getAllEmployesLastFiveYears(page, itemsPerPage, q = null, sort) {
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (q) {
            url = url + "&filter=" + q;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        return await axios_client.get('/api/employee/lessFiveYears' + url
        )
            .then(res => res.data);
    }

    async getAll(q = null) {
        let url = ""
        if (q) {
            url = `?filter=${q}&per_page=10`
        }


        return await axios_client.get('/api/employee' + url
        )
            .then(res => res.data);
    }

    async setCreateEmploye(param) {

        return await axios_client.post(`/api/employee`, param)
            .then(res => res.data).catch((error) => {
                return error.response;
            })

    }

    async setUpdateEmploye(param, id) {
        return await axios_client.put(`/api/employee/${id}`, param)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

    async getEmployeData() {
        try {
            let response = await axios_client.get('/api/employee/data');
            return response.status === 200 ? response.data.data : false;
        } catch (error) {
            return false;
        }
    }

    async getEmploye(id) {
        try {
            let result = await axios_client.get('/api/employee/' + id);
            return result.data.data
        } catch (error) {
            return false;
        }
    }

    async setDeleteEmploye(id) {
        return await axios_client.delete(`/api/employee/${id}`)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

    async setDeleteMultipleEmploye(param) {
        return await axios_client.delete(`/api/employee/destroyMultiple`, {
            data: {
                ids: param
            }
        })
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

}