import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types'
import { Button } from 'primereact/button';
import { onFile } from '@util/viewer.js'
import Tippy from '@tippyjs/react';
import { Toast } from 'primereact/toast';


const File = forwardRef((props, ref) => {
  const { maxFile, onFile: onFileInput, download, errors, textError, } = props;
  const [name, setName] = useState('Seleccionar archivo')
  const [nameOrigin, setNameOrigin] = useState('Seleccionar archivo')
  const [file, setFile] = useState(null)
  const toast = useRef(null);
  const getViewer = async () => {
    let viewer = await onFile(maxFile);
    console.log(viewer)
    if (viewer.alertMaxSize) {
      toast.current.show({ severity: 'error', summary: `Se permite máximo ${maxFile}mb de peso en imagen.`, detail: 'Ocurrio un error.', life: 3000 });
    }

    if (viewer.alertTypeFile) {
      toast.current.show({ severity: 'error', summary: 'Mensaje', detail: 'Solo se permiten formatos docx, doc, ppt, pptx, xls,xlsx,png, jpeg, svg y webp.', life: 3000 });
    }

    if (!viewer.alertTypeFile && !viewer.alertMaxSize) {
      let nameOrigin = viewer.nameOrigin.length > 30 ? viewer.nameOrigin.substr(0, 30) + '...' : viewer.nameOrigin
      setName(nameOrigin);
      setNameOrigin(viewer.nameOrigin);
      setFile(viewer.file)
      onFileInput(viewer.file)
    }
  }

  const deleteFile = () => {
    setName('Seleccionar archivo')
    setNameOrigin('Seleccionar archivo')
    onFileInput(null)
    onFileInput()
  }

  useImperativeHandle(ref, () => ({
    deleteFile: () => {
      deleteFile()
    },

    setFileName: (nameOrigin) => {
      setName(nameOrigin);
      setNameOrigin(nameOrigin)
    }
  }))

  const getdownload = () => {
    download()
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="upload flex justify-normal w-32" >
        {/* { !file && nameOrigin !== 'Seleccionar archivo' ? 
              <Button onClick={ getdownload } label="Descargar archivo"  icon="pi pi-paperclip"  className="p-button-outlined" /> 
             : ''}  */}
        <Button type="button" onClick={getViewer} label={file ? nameOrigin : 'Seleccionar archivo.'} icon="pi pi-paperclip" className="p-button-outlined" />
      </div>
      {/* <div className="justify-normal lg:w-64 md:w-48  ">
                      {prop.progress >0 ? <div className="relative pt-1">
                                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200">
                                        <div style={{ width: prop.progress+'%' }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pink-500"></div>
                                    </div>
                      </div> : ''} 
            </div> */}
      {errors &&
        <div>  <small className="w-full text-red-700 label-app">{textError}</small></div>
      }
    </>
  )
});

File.defaultProps = {
  progress: 0,
  errors: false,
  photo: '',
  textError: '',
  maxFile: 5120
};

File.propTypes = {
  progress: PropTypes.number,
  errors: PropTypes.bool,
  photo: PropTypes.string,
  textError: PropTypes.string,
  maxFile: PropTypes.number
}

export default File;