import React, { useState, useEffect, useContext, useCallback } from 'react';
import useWindowDimensions from '@hooks/UseHeight';
import { useForm, Controller } from 'react-hook-form';
import { DropdownApp as Dropdown } from '@components/form/Dropdown';
import { Ripple } from 'primereact/ripple';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { getListEmployeeWhoHadCovid } from '@services/ReportService';
import { RegimenLaboralService } from '@services/RegimenLaboralService'
import { CalendarApp as Calendar } from '@components/form/Calendar';
import { formatCalendar, getDateFormat } from '@util/date';
import { Dropdown as DropdownPagination } from 'primereact/dropdown';
import FileSaver from 'file-saver';

export const EmpleadosQueHanTenidoCovidList = () => {
    const regimenLaboralService = new RegimenLaboralService();
    const [dataList, setDataList] = useState([]);
    const [loadingList, setLoadingList] = useState(false);
    const { control, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
    const { height } = useWindowDimensions();
    //pagination 
    const [rows, setRows] = useState(10);
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [inputsselected, setInputsselected] = useState({ type: 'query' });

    //Filter 
    const [regimens, setRegimens] = useState([]);
    const [porcentage, setPorcentage] = useState('');


    useEffect(() => {
        //getRegimens()
        getListFunction(1, rows, null, null, inputsselected);
    }, [])

    const getRegimens = async () => {
        let data = await regimenLaboralService.getAll();
        setRegimens(data.map(param => {
            return {
                name: `${param.name}`,
                code: param.id
            }
        }));
    }


    const positionField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cargo</span>
                {rowData.currentContract.position.name}
            </>
        )
    }


    const dependencyField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Dependencia</span>
                {rowData.currentContract.dependency.name}
            </>
        )
    }

    const nameField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.name} {rowData.last_name}
            </>
        )
    }


    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(dataList) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(dataList) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                        onClick={() => getPagination({
                            page: index,
                            rows: rows
                        })}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(dataList) ? <DropdownPagination value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },

    }

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getListFunction(event.page + 1, event.rows, null, null, inputsselected);
    }

    const progressUpload = (porcentage) => {
        if (isFinite(porcentage)) setPorcentage(`${porcentage}%`)
        if (porcentage === 100) setPorcentage('')
    }

    const getListFunction = async (page, row, search = null, sort = null, param = null) => {
        let result = await getListEmployeeWhoHadCovid(page, row, search, sort, param, progressUpload);
        if (result) {
            if (param.type === 'query') {
                const { data, meta } = result;
                setDataList(data);
                setCurrentpage(meta.current_page);
                setTotalrecords(meta.total);
                setLastpage(meta.last_page);
            } else {
                FileSaver.saveAs(result, `Servidores que han tenido covid19 - ${getDateFormat()}.xlsx`)
            }
        }
    }

    const sortFunction = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }


        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }

        getListFunction(currentpage, rows, null, string);
    }

    const getPagination = async (event) => {
        setCurrentpage(event.page + 1);
        getListFunction(event.page + 1, event.rows, null, null, inputsselected);
    }

    const onSubmit = (data) => {
        let param = {
            type: 'query'
        }
        setInputsselected(param);
        getListFunction(currentpage, rows, null, null, param);
    }

    const exportExcel = () => {
        let param = {
            type: 'excel'
        }
        getListFunction(currentpage, rows, null, null, param);
    }


    const header = (
        <div className="table-header-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid">
                    <div className="md:col-8 col-12 md:flex justify-content-start">

                    </div>
                    <div className="md:col-4 col-12 ">
                        <div className=" md:block hidden">
                            <div className="flex md:justify-content-end">
                                <Button type="button" label={`Exportar ${porcentage}`} icon="pi pi-file-excel" className="p-button " onClick={exportExcel} />
                            </div>
                        </div>
                        <div className="md:hidden block ">
                            <div className="flex flex-wrap justify-content-between w-full">
                                <Button type="button" icon="pi pi-file-excel" className="p-button" onClick={exportExcel} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );

    return (
        <DataTable
            showGridlines
            className="p-datatable-responsive"
            value={dataList}
            dataKey="id"
            paginator
            loading={loadingList}
            onPage={onCustomPage}
            rows={rows}
            paginatorTemplate={templatePaginator}
            emptyMessage="No se encontraron resultados"
            currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} usuarios"
            scrollable
            scrollHeight={`${height - 500}px`}
            header={header}
            sortMode="multiple"
            multiSortMeta={multiSortMeta}
            onSort={e => sortFunction(e.multiSortMeta)}
            sortOrder={multiSortMeta}
        >
            <Column field="name" header="Nombre" sortable body={nameField} ></Column>
            <Column field="dni" header="DNI" sortable  ></Column>
            <Column field="dependency" header="Dependencia" sortable body={dependencyField}></Column>
            <Column field="position" header="Cargo" sortable body={positionField}></Column>
            <Column field="phone" header="Celular" sortable  ></Column>
            <Column field="company_email" header="Correo Institucional" sortable  ></Column>
        </DataTable>
    )
}