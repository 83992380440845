import React, { useState, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { DropdownApp as Dropdown } from '@components/form/Dropdown';
import { CalendarApp as Calendar } from '@components/form/Calendar';
import { InputApp as InputText } from '@components/form/InputText';
import { InputTextArea } from '@components/form/InputTextArea';
import { create, update } from '@services/PermissionService';
import _ from 'lodash';
import { Context } from './context';
import { formatCalendar, formatDate, add } from '@util/date';
import FileUpload from '@components/form/upload/File';

export const Create = (props) => {
    const { selectsData, id, setClose, condition, setPush } = props;
    const context = useContext(Context)
    const { setUpdate } = context.actions;
    const { select } = context.store;
    const { control, handleSubmit, formState: reset, setValue, watch } = useForm();
    const [loading, setLoading] = useState(false);
    const start = watch("start");
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (_.has(select, 'document')) {
            let documents = select.document;
            let durations = select.duration;
            let permissions = select.permission;
            setValue('document_id', { code: documents.id, name: documents.name });
            setValue('duration_id', { code: durations.id, name: durations.type });
            setValue('permission_id', { code: permissions.id, name: permissions.type });
            setValue("entry", (select.entry ? formatCalendar(select.entry) : ''));
            setValue("start", (select.start ? formatCalendar(select.start) : ''));
            setValue("end", (select.end ? formatCalendar(select.end) : ''));
            setValue('num_document', select.num_document);
            setValue('file', "");
            setValue('observations', _.has(select, 'observations') ? select.observations : '');

        }

    }, [select])

    const onSubmit = async (props) => {
        setLoading(true)
        let body = {
            employee_id: id,
            permission_id: props.permission_id ? props.permission_id.code : '',
            duration_id: props.duration_id ? props.duration_id.code : '',
            document_id: props.document_id ? props.document_id.code : '',
            entry: props.entry ? formatDate(props.entry, 'yyyy-MM-dd HH:mm:ss') : '',
            start: props.start ? formatDate(props.start, 'yyyy-MM-dd HH:mm:ss') : '',
            end: props.end ? formatDate(props.end, 'yyyy-MM-dd HH:mm:ss') : '',
            num_document: props.num_document,
            observations: _.has(props, 'observations') ? props.observations : '',
            file: props.file ? props.file : '',
            id: _.has(select, 'id') ? select.id : ''
        }

        let result = condition === 'Nuevo' ? await create(body) : await update(body);

        if (result.errors) {
            setErrors(result.errors[0]);
            setLoading(false)
            return false;
        }

        if (condition === 'Nuevo') { setPush(result) } else { setPush(result) };
        setClose(result);
        setLoading(false);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Dropdown label="Documento" value={value} options={selectsData.documents} optionLabel="name"
                                onChange={(e) => onChange(e)}
                                textError={errors.document_id && errors.document_id[0]}
                                errors={errors.document_id && errors.document_id.length > 0} />
                        </>
                    )}
                    name="document_id"

                    defaultValue=""
                />
                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputText
                                label="Número de documento"
                                onChange={onChange}
                                value={value}
                                errors={errors.num_document && errors.num_document.length > 0}
                                textError={errors.num_document && errors.num_document[0]} />
                        </>
                    )}
                    name="num_document"

                />

                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Dropdown label="Permiso" value={value} options={selectsData.permissions} optionLabel="name"
                                onChange={(e) => onChange(e)}
                                textError={errors.permission_id && errors.permission_id[0]}
                                errors={errors.permission_id && errors.permission_id.length > 0} />
                        </>
                    )}
                    name="permission_id"

                    defaultValue=""
                />
                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Dropdown label="Duración" value={value} options={selectsData.durations} optionLabel="name"
                                onChange={(e) => onChange(e)}
                                textError={errors.duration_id && errors.duration_id[0]}
                                errors={errors.duration_id && errors.duration_id.length > 0} />
                        </>
                    )}
                    name="duration_id"

                />
                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Calendar
                                label="Fecha de registro"
                                errors={errors.entry && errors.entry.length > 0}
                                showTime
                                onChange={onChange}
                                value={value}
                                textError={errors.entry && errors.entry[0]} />
                        </>
                    )}
                    name="entry"

                    type="date"
                />

                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Calendar
                                showTime
                                label="Fecha de inicio"
                                errors={errors.start && errors.start.length > 0}
                                onChange={onChange}
                                value={value}
                                textError={errors.start && errors.start[0]} />
                        </>
                    )}
                    name="start"


                />
                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Calendar
                                minDate={add(start, 1, 'addMinutes')}
                                label="Fecha de fin"
                                showTime
                                onChange={onChange}
                                value={value}
                                errors={errors.end && errors.end.length > 0}
                                textError={errors.end && errors.end[0]} />
                        </>
                    )}
                    name="end"

                    type="date"
                />

                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputTextArea
                                label="Observaciones"
                                onChange={onChange}
                                value={value} />
                        </>
                    )}

                    name="observations"
                />
                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <FileUpload onFile={e => onChange(e)} value={value}
                                errors={errors.file && errors.file.length > 0}
                                textError={errors.file && errors.file[0]} />

                        </>
                    )}
                    name="file"
                />

                <div className="flex justify-content-between pt-4">
                    <Button type="button" label="Cancelar" icon="pi pi-times" className="p-button-text mr-1" onClick={setClose} />
                    <Button type="submit" className="ml-1" disabled={loading} label={
                        loading ? '...Cargando' : (condition === 'Nuevo' ? 'Guardar' : 'Actualizar')
                    } icon="pi pi-check" />
                </div>
            </form>
        </>
    )
}