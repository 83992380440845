import React  from 'react';
import { ContextHorario } from './context';
import { List } from './list';

export const Permisos = () => {
    return (
        <ContextHorario>
            <List />
        </ContextHorario>
    )
}