import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'primereact/button';
import useWindowDimensions from '@hooks/UseHeight';
import { InputApp as InputText } from '@components/form/InputText';
import { AutoCompleteApp as AutoComplete } from '@components/form/Autocomplete';
import { CalendarApp as Calendar } from '@components/form/Calendar';
import { DropdownApp as Dropdown } from '@components/form/Dropdown';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextArea } from '@components/form/InputTextArea';
import { useHistory, useParams } from "react-router-dom";
import { SelectButtonApp as SelectButton } from '@components/form/SelectButton';
import { EmployesService } from '@services/EmployesService'
import { useForm, Controller } from 'react-hook-form';
import { Label } from '@components/form/Label';
import _ from 'lodash';
import { formatDate, formatCalendar } from '@util/date';

import { TransfersService } from "@services/TransfersService";

export const Registro = ({ location }) => {
    let { id } = useParams();
    const [idelement, setIdelement] = useState(id)
    const employesService = new EmployesService();
    const { height } = useWindowDimensions();
    const [data, setData] = useState({})
    const [dataBienesSelected, setDataBienesSelected] = useState([])
    const history = useHistory();
    const [dataelement, setDataelement] = useState({ offices: [] });
    const toast = useRef(null);
    const elementsService = new TransfersService();
    const [loading, setLoading] = useState(false);
    const [employeSelected, setEmployeSelected] = useState({});
    const [errorsback, setErrorsback] = useState([]);

    //Filter
    const [employes, setEmployes] = useState([]);
    const [bienes, setBienes] = useState([]);


    useEffect(async () => {

        async function getInit() {
            let result = await elementsService.getElementData();
            setDataelement(result ? result : [])

            if (location.pathname !== '/desplazamiento-de-bienes/registro') {
                let results = await elementsService.getElement(id);
                setValue("code", results.code);
                setValue("reason", results.reason);
                setValue("departure_date", results.departure_date ? formatCalendar(results.departure_date) : '');
                setValue("return_date", results.return_date ? formatCalendar(results.return_date) : '');
                setValue("observations", results.observations);

                setValue("applicant_id", results.applicant);
                setValue("dependency_destination_id", results.dependency_destination);
                setValue("dependency_origin_id", results.dependency_origin);
                setValue("in_charge_id", results.in_charge);
                setValue("transfer_type_id", results.transfer_type);

                let arrays_ = [];
                for (let i = 0; i < results.assetTransfers.length; i++) {
                    arrays_.push({
                        id: results.assetTransfers[i].asset.id,
                        patrimonial_code: results.assetTransfers[i].asset.patrimonial_code,
                        description: results.assetTransfers[i].asset.description,
                        brand_id: results.assetTransfers[i].asset.brand_id,
                        brand: results.assetTransfers[i].asset.brand,
                        blueprint_id: results.assetTransfers[i].asset.blueprint_id,
                        blueprint: results.assetTransfers[i].asset.blueprint,
                        serial_number: results.assetTransfers[i].asset.serial_number,
                        color_id: results.assetTransfers[i].asset.color_id,
                        color: results.assetTransfers[i].asset.color,
                        asset_state: results.assetTransfers[i].asset.asset_state,
                        asset_state_id: results.assetTransfers[i].asset.asset_state_id,
                    });
                }
                setDataBienesSelected(arrays_);
            }
        }
        getInit();

        return () => {
            //setData(results)
        }
    }, [])

    const defaultValues = {
        code: '',
        reason: "",
        departure_date: "",
        return_date: "",
        observations: "",
        applicant_id: null,
        in_charge_id: null,
        dependency_origin_id: null,
        dependency_destination_id: null,
        transfer_type_id: null,
        assets: []
    }
    const { watch, control, handleSubmit, formState: { errors }, setValue } = useForm({ defaultValues });

    const getEmploye = async (search) => {
        let data = await employesService.getAll(search);
        setEmployes(data.data.map(param => {
            return {
                name: `${param.name} ${param.last_name}`,
                id: param.id
            }
        }));
    }

    const searchEmploye = useCallback(
        _.debounce(async ({ query }) => {
            getEmploye(query);
        }, 300),
        [],
    );

    const getBien = async (eSelected, search) => {
        let data = await elementsService.getAll(eSelected?.id, search);
        if (!data.errors) {
            setBienes(data.data.map(param => {
                return {
                    name: `${param.patrimonial_code} - ${param.description} `,
                    id: param.id,
                    patrimonial_code: param.patrimonial_code,
                    description: param.description,
                    brand_id: param.brand_id,
                    brand: param.brand,
                    blueprint_id: param.blueprint_id,
                    blueprint: param.blueprint,
                    serial_number: param.serial_number,
                    color_id: param.color_id,
                    color: param.color,
                    asset_state: param.asset_state,
                    asset_state_id: param.asset_state_id,
                }
            }));

        }
    }

    const searchBien = useCallback(
        _.debounce(async ({ query }) => {
            getBien(employeSelected, query);
        }, 300),
        [employeSelected],
    );

    const setArrayBienes = _.debounce((e) => {
        if (typeof e === 'object' && e) {
            let data = dataBienesSelected;
            let result = data.filter(element => element.id == e.id);
            if (result.length == 0) {
                data.push(e);
                setDataBienesSelected(data);
            }
            setValue("bien_select_id", '');
            getBien(employeSelected, ""); //esto lo usé para actualizar el datatables ya que no se actualizaba
        }
    }, 100);

    const setArrayBienesSelect = _.debounce((e) => {
        if (typeof e === 'object' && e) {
            setEmployeSelected(e);
        }
    }, 200);

    const onSubmit = async (data) => {
        setLoading(true)
        let array_assets = [];
        for (let i = 0; i < dataBienesSelected.length; i++) {
            array_assets.push(dataBienesSelected[i].id);
        }
        let element = {
            code: data.code,
            reason: data.reason,
            departure_date: (data.departure_date ? formatDate(data.departure_date, 'yyyy-MM-dd') : ''),
            return_date: (data.return_date ? formatDate(data.return_date, 'yyyy-MM-dd') : ''),
            observations: data.observations,

            applicant_id: (data.applicant_id ? data.applicant_id.id : ''),
            in_charge_id: (data.in_charge_id ? data.in_charge_id.id : ''),
            dependency_origin_id: (data.dependency_origin_id ? data.dependency_origin_id.id : ''),
            dependency_destination_id: (data.dependency_destination_id ? data.dependency_destination_id.id : ''),
            transfer_type_id: (data.transfer_type_id ? data.transfer_type_id.id : ''),
            assets: array_assets
        };

        let result;
        if (location.pathname !== '/desplazamiento-de-bienes/registro') {
            result = await elementsService.setUpdateElement(element, idelement)
        } else {
            result = await elementsService.setCreateElement(element)
        }
        if (result.errors) {
            setErrorsback(result.errors[0]);
            setLoading(false)
            return false;
        }
        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        setErrorsback([]);

        setTimeout(() => {
            setLoading(false)
            history.push('/desplazamiento-de-bienes/listado')
        }, 1000);
    }

    const patrimonialCodeField = (rowData) => {
        return (
            <>

                {rowData.patrimonial_code}
            </>
        )
    }

    const descriptionField = (rowData) => {
        return (
            <>

                {rowData.description}
            </>
        )
    }

    const brandField = (rowData) => {
        return (
            <>

                {rowData.brand?.name}
            </>
        )
    }
    const blueprintField = (rowData) => {
        return (
            <>

                {rowData.blueprint?.name}
            </>
        )
    }
    const serieField = (rowData) => {
        return (
            <>

                {rowData.serial_number}
            </>
        )
    }
    const colorField = (rowData) => {
        return (
            <>

                {rowData.color?.name}
            </>
        )
    }
    const assetStateField = (rowData) => {
        return (
            <>

                {rowData.asset_state?.name}
            </>
        )
    }

    const optionDataTable = (data) => {
        return (
            <>
                <div className="flex justify-content-center flex-wrap ">
                    <div className="flex align-items-center justify-content-center ">
                        <Button type="button" className="    p-button-sm   p-button-danger" icon="pi pi-trash " onClick={() => deleteElementDataTable(data)} />
                    </div>
                </div>
            </>
        )
    }

    const deleteElementDataTable = (e) => {
        let data = dataBienesSelected;
        let result = data.filter(element => element.id !== e.id);
        setDataBienesSelected(result);
    }

    return (
        <>
            <div>
                <Toast ref={toast} />
                <div className="mb-4">
                    <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-text" onClick={() => history.push('/desplazamiento-de-bienes/listado')} tooltip="Regresar" />
                </div>
                <div className="card">
                    <h5>{(location.pathname !== '/desplazamiento-de-bienes/registro' ? 'Editar' : 'Registrar')} Desplazamiento de Bienes Patrimoniales</h5>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className="grid">

                            <div className="md:col-6 col-12">
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                label="Código"
                                                errors={errorsback.code && errorsback.code.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.code && errorsback.code[0]} />
                                        </>
                                    )}
                                    name="code"
                                    defaultValue=""
                                />
                            </div>
                            <div className="md:col-6 col-12">

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                label="Motivo"
                                                errors={errorsback.reason && errorsback.reason.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.reason && errorsback.reason[0]} />
                                        </>
                                    )}
                                    name="reason"
                                    defaultValue=""
                                />
                            </div>
                            <div className="md:col-6 col-12">
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <AutoComplete label="Solicitante"
                                                value={value}
                                                suggestions={employes}
                                                completeMethod={searchEmploye}
                                                field="name"
                                                dropdown
                                                forceSelection
                                                errors={errorsback.applicant_id && errorsback.applicant_id.length > 0}
                                                textError={errorsback.applicant_id && errorsback.applicant_id[0]}
                                                onChange={(e) => onChange(e)}
                                            />
                                        </>
                                    )}
                                    name="applicant_id"
                                    defaultValue=""
                                />
                            </div>
                            <div className="md:col-6 col-12">
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <AutoComplete label="Responsable de traslado"
                                                value={value}
                                                suggestions={employes}
                                                completeMethod={searchEmploye}
                                                field="name"
                                                dropdown
                                                forceSelection
                                                errors={errorsback.in_charge_id && errorsback.in_charge_id.length > 0}
                                                textError={errorsback.in_charge_id && errorsback.in_charge_id[0]}
                                                onChange={(e) => onChange(e)}
                                            />
                                        </>
                                    )}
                                    name="in_charge_id"
                                    defaultValue=""
                                />
                            </div>
                            <div className="md:col-6 col-12">

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Dropdown
                                                label="Dependencia de origen"
                                                value={value}
                                                options={dataelement.dependencies}
                                                optionLabel="name"
                                                onChange={(e) => onChange(e)}
                                                errors={errorsback.dependency_origin_id && errorsback.dependency_origin_id.length > 0}
                                                textError={errorsback.dependency_origin_id && errorsback.dependency_origin_id[0]} />
                                        </>
                                    )}
                                    name="dependency_origin_id"
                                    defaultValue=""
                                />
                            </div>
                            <div className="md:col-6 col-12">
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Dropdown
                                                label="Dependencia de destino"
                                                value={value}
                                                options={dataelement.dependencies}
                                                optionLabel="name"
                                                onChange={(e) => onChange(e)}
                                                errors={errorsback.dependency_destination_id && errorsback.dependency_destination_id.length > 0}
                                                textError={errorsback.dependency_destination_id && errorsback.dependency_destination_id[0]} />
                                        </>
                                    )}
                                    name="dependency_destination_id"
                                    defaultValue=""
                                />
                            </div>

                            <div className="md:col-6 col-12">
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Calendar
                                                label="Fecha de salida" 
                                                errors={errorsback.departure_date && errorsback.departure_date.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.departure_date && errorsback.departure_date[0]} />
                                        </>
                                    )}
                                    name="departure_date"
                                    type="date"
                                    defaultValue=""
                                />
                            </div>
                            <div className="md:col-6 col-12">

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Calendar
                                                label="Fecha de retorno" 
                                                errors={errorsback.return_date && errorsback.return_date.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.return_date && errorsback.return_date[0]} />
                                        </>
                                    )}
                                    name="return_date"
                                    type="date"
                                    defaultValue=""
                                />
                            </div>
                            <div className="md:col-6 col-12">

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Dropdown
                                                label="Tipo de traslado"
                                                value={value}
                                                options={dataelement.transfer_types}
                                                optionLabel="name"
                                                onChange={(e) => onChange(e)}
                                                errors={errorsback.transfer_type_id && errorsback.transfer_type_id.length > 0}
                                                textError={errorsback.transfer_type_id && errorsback.transfer_type_id[0]} />
                                        </>
                                    )}
                                    name="transfer_type_id"
                                    defaultValue=""
                                />
                            </div>
                            <div className="md:col-6 col-12">

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputTextArea
                                                label="Observaciones"
                                                onChange={onChange}
                                                value={value} />
                                        </>
                                    )}

                                    name="observations"
                                />


                            </div>

                            <div className="md:col-12 col-12">
                                <div className="py-2">
                                    <Label font="font-medium">Bienes con Código Patrimonial</Label>
                                </div>
                            </div>
                            <div className="md:col-12 col-12">
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <AutoComplete label="Buscar Empleado"
                                                value={value}
                                                suggestions={employes}
                                                completeMethod={searchEmploye}
                                                field="name"
                                                dropdown
                                                forceSelection
                                                onChange={(e) => onChange(e)}
                                                onClick={setArrayBienesSelect(value)}
                                            />
                                        </>
                                    )}
                                    name="employe_selected"
                                    defaultValue=""
                                />
                            </div>
                            <div className="md:col-12 col-12">
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <AutoComplete label="Buscar Bien Patrimonial"
                                                value={value}
                                                suggestions={bienes}
                                                completeMethod={searchBien}
                                                field="patrimonial_code"
                                                dropdown
                                                forceSelection
                                                errors={errorsback.bien_select_id && errorsback.bien_select_id.length > 0}
                                                textError={errorsback.bien_select_id && errorsback.bien_select_id[0]}
                                                onChange={(e) => onChange(e)}
                                                onClick={setArrayBienes(value)}
                                            />
                                        </>
                                    )}
                                    name="bien_select_id"
                                    defaultValue=""
                                />
                            </div>

                            <div className="md:col-12 col-12">
                                <br />
                                {
                                    dataBienesSelected.length > 0 && <DataTable

                                        showGridlines
                                        className="p-datatable-responsive"
                                        value={dataBienesSelected}
                                        dataKey="id"
                                        emptyMessage="No se encontraron resultados"
                                        currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} bienes patrimoniales"
                                        scrollable
                                        scrollHeight={`${height - 500}px`}
                                    >
                                        <Column field="patrimonial_code" header="Código Patrimonial" body={patrimonialCodeField}></Column>
                                        <Column field="description" header="Descripción" body={descriptionField}></Column>
                                        <Column field="brand" header="Marca" body={brandField}></Column>
                                        <Column field="blueprint" header="Modelo" body={blueprintField}></Column>
                                        <Column field="serial_number" header="Serie" body={serieField}></Column>
                                        <Column field="color" header="Color" body={colorField}></Column>
                                        <Column field="assetState" header="Estado" body={assetStateField}></Column>
                                        <Column headerStyle={{ width: '10rem' }} body={optionDataTable} className="text-center"></Column>
                                    </DataTable>
                                }

                                <br />
                            </div>
                        </div>
                        <div className="flex md:justify-content-end mt-4">
                            <Button disabled={loading} label={
                                loading ? '...Cargando' : (location.pathname !== '/desplazamiento-de-bienes/registro' ? 'Actualizar' : 'Guardar')
                            } icon="pi pi-check" />
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}
