import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown as DropdownPagination } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Ripple } from 'primereact/ripple';
import { InputText } from 'primereact/inputtext';
import _ from 'lodash';
import useWindowDimensions from '@hooks/UseHeight'
import { useHistory, useParams } from "react-router-dom";
import { select, getList, deleteItem as deleteItemService, deleteItems, downloadfile } from '@services/PermissionService';
import { Create } from './create';
import { Context } from './context';
import FileSaver from 'file-saver';

export const List = () => {
    const context = useContext(Context)
    const { setData, setSelect, deteleteItemContext } = context.actions;
    const history = useHistory();
    const { id } = useParams();
    const { height } = useWindowDimensions();
    let emptyItem = {
        id: null,
        index: null
    };

    const [dataList, setDataList] = useState([]);

    const [selectsData, setSelectsData] = useState([]);
    const [loadingList, setLoadingList] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingDeleteIds, setLoadingDeleteIds] = useState(false);
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [schedule, setScheduleDialog] = useState(false);
    const [deleteItemDialog, setdeleteItemDialog] = useState(false);
    const [deleteItemsDialog, setdeleteItemsDialog] = useState(false);
    const [item, setSelectItem] = useState(emptyItem);
    const [roles, setRoles] = useState([]);
    const [condition, setCondition] = useState('Nuevo');
    const [selectedItems, setselectedItems] = useState(null);
    const [selectedItemsId, setselectedItemsId] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    //pagination 
    const [rows, setRows] = useState(10);
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);

    useEffect(() => {
        if (context) setDataList(context.store.data)
    }, [context])

    useEffect(() => {
        async function getInit() {
            let data = await select();

            if (data) {
                let result = {
                    documents: data.data.documents.map(paramChildren => {
                        return {
                            name: paramChildren.name, code: paramChildren.id
                        }
                    }),
                    durations: data.data.durations.map(paramChildren => {
                        return {
                            name: paramChildren.type, code: paramChildren.id
                        }
                    }),
                    permissions: data.data.permissions.map(paramChildren => {
                        return {
                            name: paramChildren.type, code: paramChildren.id
                        }
                    })
                }

                setSelectsData(result)
            }
        }
        getInit();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getInit() {
            setLoadingList(true)
            await getListFunction(currentpage, rows)

            setLoadingList(false)
        }
        getInit();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openNew = () => {
        setCondition('Nuevo');
        setSelect({})
        setSubmitted(false);
        setScheduleDialog(true);
    }

    const hideDialog = () => {
        setCondition('Nuevo');

        setScheduleDialog(false);
        setSubmitted(false);
    }

    const hidedeleteItemDialog = () => {
        setdeleteItemDialog(false);
    }

    const hidedeleteItemsDialog = () => {
        setdeleteItemsDialog(false);
    }


    const editItem = (user, index) => {
        setCondition('Actualizar');
        setSelect({ ...user, index })
        setScheduleDialog(true);
    }


    const downloadFile = async (item) => {
        let result = await downloadfile(item);
        let arrayText = item.file.split('.')
        let format = arrayText[arrayText.length - 1]
        FileSaver.saveAs(result, item.num_document + " - " + item.document.name + "." + format)
    }

    const confirmdeleteItem = (item, index) => {
        setSelectItem({ id: item.id, index });
        setdeleteItemDialog(true);
    }

    const deleteItem = async () => {
        let result = await deleteItemService(item.id);
        if (result) {
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        }
        deteleteItemContext(item.id)
        getListFunction(currentpage, rows);
        setdeleteItemDialog(false);
        setSelectItem(emptyItem);

    }

    const getListFunction = async (page, row, search = null, sort = null) => {
        if (id) {
            let result = await getList(id, page, row, search, sort);

            if (result) {
                const { data, meta } = result;
                setData(data);
                setCurrentpage(meta.current_page);
                setTotalrecords(meta.total);
                setLastpage(meta.last_page);
            }
        }
    }

    const confirmDeleteSelected = () => {
        let ids = selectedItems.map(param => param.id);
        setselectedItemsId(ids);
        setdeleteItemsDialog(true);
    }

    const deleteselectedItems = async () => {
        setLoadingDeleteIds(true)
        let result = await deleteItems(selectedItemsId)
        if (result) {
            getListFunction(currentpage, rows);

            setselectedItems(null);
            setselectedItemsId([]);
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Mensaje', detail: 'Ocurrio un error.', life: 3000 });
        }
        setLoadingDeleteIds(false)
        setdeleteItemsDialog(false);
    }

    const setPush = async (result) => {
        if (result) {
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Mensaje', detail: 'Ocurrio un error.', life: 3000 });
        }

        getListFunction(currentpage, rows);
    }

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getListFunction(event.page + 1, event.rows);
    }

    const getPaginationUser = async (event) => {
        setCurrentpage(event.page + 1);
        getListFunction(event.page + 1, event.rows);
    }

    const sortFunctionUsers = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }

        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }

        getListFunction(currentpage, rows, null, string);
    }

    const searchGlobal = useCallback(
        _.debounce(async (value) => {
            getListFunction(currentpage, rows, value);
        }, 600),
        [],
    );

    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(dataList) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(dataList) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                        onClick={() => getPaginationUser({
                            page: index,
                            rows: rows
                        })}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(dataList) ? <DropdownPagination value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },

    }

    const actionBodyTemplate = (rowData, props) => {
        return (
            <>
                <div className="justify-content-center w-full md:flex hidden">
                    <div>
                        {
                            (rowData.file &&
                                <Button icon="pi pi-download" className="p-button  mr-2" onClick={() => downloadFile(rowData)} />)
                        }
                        <Button icon="pi pi-pencil" className="p-button  mr-2" onClick={() => editItem(rowData, props.rowIndex)} />
                        <Button icon="pi pi-trash" className="p-button  p-button-danger" onClick={() => confirmdeleteItem(rowData, props.rowIndex)} />
                    </div>
                </div>
                <div className="justify-content-between w-6rem md:hidden flex">
                    <div>
                        {
                            (rowData.file &&
                                <Button icon="pi pi-download" className="p-button  mr-2" onClick={() => downloadFile(rowData)} />
                            )
                        }

                        <Button icon="pi pi-pencil" className="p-button  mr-2" onClick={() => editItem(rowData, props.rowIndex)} />
                        <Button icon="pi pi-trash" className="p-button  p-button-danger" onClick={() => confirmdeleteItem(rowData, props.rowIndex)} />
                    </div>
                </div>
            </>
        );
    }

    const header = (
        <div className="table-header-container">
            <div className="grid">
                <div className="md:col-5 col-12">
                    <div className="table-header">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText type="search" onInput={(e) => searchGlobal(e.target.value)} placeholder="Buscar..." />
                        </span>
                    </div>
                </div>
                <div className="md:col-7 col-12 ">
                    <div className=" md:block hidden">
                        <div className="flex md:justify-content-end">
                            <Button label="Nuevo" icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
                            <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedItems || !selectedItems.length} />
                            {/*<Button label="Exportar" icon="pi pi-file-excel" className="p-button " />*/}
                        </div>
                    </div>
                    <div className="md:hidden block ">
                        <div className="flex flex-wrap justify-content-between w-full">
                            <Button icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
                            <Button icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} />
                            <Button icon="pi pi-file-excel" className="p-button" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

    const deleteItemDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hidedeleteItemDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteItem} />
        </>
    );
    const deleteItemsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hidedeleteItemsDialog} />
            <Button label={loadingDeleteIds ? '...Cargando' : 'Si'} icon="pi pi-check" className="p-button-text" onClick={deleteselectedItems} />
        </>
    );

    const documentField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Documento</span>
                {rowData.document.name}
            </>
        )
    }

    const durationField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Duración</span>
                {rowData.duration.type}
            </>
        )
    }

    const permissionField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Permiso</span>
                {rowData.permission.type}
            </>
        )
    }

    const entryField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha</span>
                {rowData.entry}
            </>
        )
    }

    const endField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha de finalización</span>
                {rowData.end}
            </>
        )
    }

    const startField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha de inicio</span>
                {rowData.start}
            </>
        )
    }


    const observationsField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Observaciones</span>
                {rowData.observations}
            </>
        )
    }

    const setClose = (result) => {

        setScheduleDialog(false);
    }


    return (
        <>
            <Toast ref={toast} />
            <div className="datatable-responsive">
                <div className="pb-2">
                    <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-text" onClick={() => history.push('/empleado/listado')} tooltip="Regresar" label="Regresar" />
                </div>
                <div className="card">
                    <h5 className="p-m-0">Listado de permisos</h5>
                    <DataTable
                        showGridlines
                        className="p-datatable-responsive"
                        ref={dt}
                        value={dataList}
                        selection={selectedItems}
                        onSelectionChange={(e) => setselectedItems(e.value)}
                        dataKey="id"
                        paginator
                        loading={loadingList}
                        onPage={onCustomPage}
                        rows={rows}
                        paginatorTemplate={templatePaginator}
                        emptyMessage="No se encontraron resultados"
                        currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} permisos"
                        scrollable
                        scrollHeight={`${height - 500}px`}
                        header={header}
                        sortMode="multiple"
                        multiSortMeta={multiSortMeta}
                        onSort={e => sortFunctionUsers(e.multiSortMeta)}
                        sortOrder={multiSortMeta}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="num_document" header="Número de Documento" sortable  ></Column>
                        <Column field="document" header="Tipo de Documento" sortable body={documentField}></Column>
                        <Column field="duration" header="Duración" sortable body={durationField}></Column>
                        <Column field="permission" header="Permiso" sortable body={permissionField}></Column>
                        <Column field="entry" header="Fecha de ingreso al sistema" sortable body={entryField}></Column>


                        <Column field="start" header="Fecha de inicio" sortable body={startField}></Column>
                        <Column field="end" header="Fecha de finalización" sortable body={endField}></Column>
                        <Column field="observations" header="Observaciones" sortable body={observationsField}></Column>
                        <Column body={actionBodyTemplate} className="text-center"></Column>
                    </DataTable>
                    <Dialog
                        visible={schedule}
                        style={{ width: '450px' }}
                        header={`${condition} Permiso`}
                        modal
                        className="p-fluid"
                        onHide={hideDialog}
                        draggable={false}
                    >
                        <Create id={id} condition={condition} setPush={setPush} setClose={setClose} selectsData={selectsData} />
                    </Dialog>
                    <Dialog
                        visible={deleteItemDialog}
                        style={{ width: '480px' }}
                        header="Eliminar permiso"
                        modal
                        footer={deleteItemDialogFooter}
                        onHide={hidedeleteItemDialog}
                    >
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {item && <span>¿Está seguro de eliminar?</span>}
                        </div>
                    </Dialog>

                    <Dialog
                        visible={deleteItemsDialog}
                        style={{ width: '480px' }}
                        header="Eliminar permisos"
                        modal
                        footer={deleteItemsDialogFooter}
                        onHide={hidedeleteItemsDialog}
                    >
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {item && <span>¿Está seguro de eliminar a las filas seleccionadas?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    );
}