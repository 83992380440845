import React, { useState, createContext, useEffect, useCallback } from 'react';
import useAuth from "@hooks/Auth"
import Cookies from 'js-cookie';
export const Context = createContext();

export const AuthContext = (props) => {
  const { children } = props;
  const state = useAuth();
  const [store, setStore] = useState({
    auth: state,
    token: Cookies.get('app_token')
  }); 

  const remove = useCallback(() => {
    Cookies.remove('app_token')
  }, []);

  const [actions, setActions] = useState({
    closeSession: () => {
      remove()
      setStore({ ...store, auth: false });
    },
    setValue: value => {
      setStore({ ...store, auth: value });
    }
  });


  useEffect(() => {
    actions.setValue(state)
    return () => {
      setActions({})
    }
  }, [])
  return (
    <>
      <Context.Provider value={{ store, actions }}>
        {children}
      </Context.Provider>
    </>
  )
}

