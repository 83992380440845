import React, { useRef, useState, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DropdownApp as Dropdown } from '@components/form/Dropdown';
import { create, update } from '@services/ScheduleService';
import _ from 'lodash';
import { Context } from './context';

export const Create = (props) => {
    const { selectsData, id, setClose, condition, setPush } = props;
    const context = useContext(Context)
    const { setUpdate } = context.actions;
    const { select } = context.store;

    const { control, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        if (_.has(select, 'modality')) {
            let modality = select.modality;
            let shift = select.shift;
            let work_hour = select.work_hour;
            setValue('modalities', { code: modality.id, name: modality.type });
            setValue('shifts', { code: shift.id, name: shift.type });
            setValue('work_hours', { code: work_hour.id, name: work_hour.hours });
        }

    }, [select])

    const onSubmit = async (props) => {
        setLoading(true)
        let body = {
            modality_id: props.modalities.code,
            shift_id: props.shifts.code,
            work_hour_id: props.work_hours.code,
            employee_id: id,
            schedule_id: _.has(select, 'id') ? select.id : ''
        }

        let result = condition === 'Nuevo' ? await create(body) : await update(body);

        //if (condition === 'Nuevo') { setPush() } else { setUpdate(result.data, select.index) };
        if (condition === 'Nuevo') { setPush(result) } else { setPush(result) };
        setLoading(false);
        reset();
        setClose(result);
    }

    return (
        <>
            <Toast ref={toast} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Dropdown label="Modalidad" value={value} options={selectsData.modalities} optionLabel="name"
                                onChange={(e) => onChange(e)} textError={errors.modalities && errors.modalities.message} errors={_.has(errors, 'modalities')} />
                        </>
                    )}
                    name="modalities"
                    rules={{
                        required: {
                            value: true,
                            message: "Campo requerido."
                        }
                    }}
                    defaultValue=""
                />

                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Dropdown label="Turno" value={value} options={selectsData.shifts} optionLabel="name"
                                onChange={(e) => onChange(e)} textError={errors.shifts && errors.shifts.message} errors={_.has(errors, 'shifts')} />
                        </>
                    )}
                    name="shifts"
                    rules={{
                        required: {
                            value: true,
                            message: "Campo requerido."
                        }
                    }}
                />

                <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Dropdown label="Rango de horas" value={value} options={selectsData.work_hours} optionLabel="name"
                                onChange={(e) => onChange(e)} textError={errors.work_hours && errors.work_hours.message} errors={_.has(errors, 'work_hours')} />
                        </>
                    )}
                    name="work_hours"
                    rules={{
                        required: {
                            value: true,
                            message: "Campo requerido."
                        }
                    }}
                    defaultValue=""
                />

                <div className="flex justify-content-between pt-4">
                    <Button type="button" label="Cancelar" icon="pi pi-times" className="p-button-text mr-1" onClick={setClose} />
                    <Button type="submit" className="ml-1" disabled={loading} label={
                        loading ? '...Cargando' : (condition === 'Nuevo' ? 'Guardar' : 'Actualizar')
                    } icon="pi pi-check" />
                </div>
            </form>
        </>
    )
}