import axios from 'axios';
import Cookies from 'js-cookie'

//const subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;

const axios_client = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers:
  {
    'Authorization': `Bearer ${Cookies.get('app_token')}`,
    "Content-Type": "application/json",
    "Accept": "application/json"
  },
});

axios_client.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  if (error.response.status === 401) {
    Cookies.remove('app_token');
    window.location.href = "/login"
  }
  return error.response;
});

axios_client.interceptors.request.use(function (config) {
  if (config.headers.Authorization === "") {
    window.location.href = "/login"
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

export default axios_client;
