
import axios_client from "../util/axios_client";

export class VacacionesService {

    async getAllElements(page, itemsPerPage, q = null, sort, employe) {
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (q) {
            url = url + "&filter=" + q;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        return await axios_client.get(`/api/employee/${employe}/holiday` + url
        )
            .then(res => res.data);
    }

    async setCreateElement(param, employe) {

        return await axios_client.post(`/api/employee/${employe}/holiday`, param)
            .then(res => res.data).catch((error) => {
                return false;
            })

    }

    async setUpdateElement(param, id) {
        return await axios_client.put(`/api/holiday/${id}`, param)
            .then(res => res.data).catch((error) => {
                // toast.current.show({ severity: 'error', summary: 'Ocurrio un error.', detail: result.message, life: 3000 });
                return false;
            })
    }

    async setDeleteElement(id) {
        return await axios_client.delete(`/api/holiday/${id}`)
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

    async setDeleteMultipleElement(param) {
        return await axios_client.delete(`/api/holiday/destroyMultiple`, {
            data: {
                ids: param
            }
        })
            .then(res => res.data).catch((error) => {
                return error.response;
            })
    }

}