const initialState = {
    view: true,
    index: null,
    animation: false
}
  
const client = (state = initialState,  { type, payload }) => { 
        switch (type) {
            case "SETSIDEBAR":
             return { ...state,view: payload.view, animation: payload.animation}; 

             case "SETINDEX":
              return { ...state,index: payload}; 

            default:
              return state;
          } 
  }

export default client;