import React, { useState, useEffect, useContext, useCallback } from 'react';
import useWindowDimensions from '@hooks/UseHeight';
import { useForm, Controller } from 'react-hook-form';
import { AutoCompleteApp as AutoComplete } from '@components/form/Autocomplete';
import { Ripple } from 'primereact/ripple';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { getListAssetForEmploye } from '@services/ReportService';
import { EmployesService } from '@services/EmployesService'
import { CalendarApp as Calendar } from '@components/form/Calendar';
import { formatCalendar, getDateFormat } from '@util/date';
import { Dropdown as DropdownPagination } from 'primereact/dropdown';
import FileSaver from 'file-saver';

export const BienesPatrimonialesPorEmpleadoList = () => {
    const employesService = new EmployesService();
    const [dataList, setDataList] = useState([]);
    const [loadingList, setLoadingList] = useState(false);
    const { control, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
    const { height } = useWindowDimensions();
    //pagination 
    const [rows, setRows] = useState(10);
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [inputsselected, setInputsselected] = useState({});

    //Filter 
    const [employes, setEmployes] = useState([]);
    const [porcentage, setPorcentage] = useState('');


    useEffect(() => {
        getEmploye()
        //getListFunction(currentpage, rows);
    }, [])

    const getEmploye = async (search) => {
        let data = await employesService.getAll(search);
        setEmployes(data.data.map(param => {
            return {
                name: `${param.name} ${param.last_name}`,
                code: param.id
            }
        }));
    }

    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(dataList) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(dataList) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                        onClick={() => getPagination({
                            page: index,
                            rows: rows
                        })}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(dataList) ? <DropdownPagination value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },

    }

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getListFunction(event.page + 1, event.rows, null, null, inputsselected);
    }

    const progressUpload = (porcentage) => {
        if (isFinite(porcentage)) setPorcentage(`${porcentage}%`)
        if (porcentage === 100) setPorcentage('')
    }

    const getListFunction = async (page, row, search = null, sort = null, param = null) => {
        let result = await getListAssetForEmploye(page, row, search, sort, param, progressUpload);
        if (result) {
            if (param.type === 'query') {
                const { data, meta } = result;
                setDataList(data);
                setCurrentpage(meta.current_page);
                setTotalrecords(meta.total);
                setLastpage(meta.last_page);
            } else {
                FileSaver.saveAs(result, `Bienes patrimoniales por servidor - ${getDateFormat()}.xlsx`)
            }
        }
    }

    const sortFunction = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }


        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }

        getListFunction(currentpage, rows, null, string);
    }

    const getPagination = async (event) => {
        setCurrentpage(event.page + 1);
        getListFunction(event.page + 1, event.rows, null, null, inputsselected);
    }

    const searchEmploye = useCallback(
        _.debounce(async ({ query }) => {
            getEmploye(query);
        }, 300),
        [],
    );

    const onSubmit = (data) => {
        let param = {
            employee_id: data.employesSelect.code,
            type: 'query'
        }
        setInputsselected(param);
        getListFunction(currentpage, rows, null, null, param);
    }

    const exportExcel = () => {
        let param = {
            employee_id: inputsselected.employee_id,
            type: 'excel'
        }
        if (inputsselected.employee_id) {
            getListFunction(currentpage, rows, null, null, param);
        }
    }

    const dependencyField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Dependencia</span>
                {rowData.employee?.currentPosting?.office?.dependency?.name}
            </>
        )
    }
    const blueprintField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Modelo</span>
                {rowData.blueprint.name}
            </>
        )
    }
    const brandField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Marca</span>
                {rowData.brand.name}
            </>
        )
    }
    const assetStateField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Estado</span>
                {rowData.asset_state.name}
            </>
        )
    }
    const serieField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Serie</span>
                {rowData.serial_number}
            </>
        )
    }
    const barCodeField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código de Barra</span>
                {rowData.bar_code}
            </>
        )
    }
    const margesiCodeField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código Margesi</span>
                {rowData.margesi_code}
            </>
        )
    }
    const patrimonialCodeField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código Patrimonial</span>
                {rowData.patrimonial_code}
            </>
        )
    }
    const descriptionField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.description}
            </>
        )
    }
    const nameField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.employee.name} {rowData.employee.last_name}
            </>
        )
    }
    const dniField = (rowData) => {
        return (
            <>
                <span className="p-column-title">DNI</span>
                {rowData.employee.dni}
            </>
        )
    }

    const header = (
        <div className="table-header-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid">
                    <div className="md:col-8 col-12 md:flex justify-content-start">
                        <div className="justify-content-start">
                            <Controller
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <AutoComplete label="Servidor"
                                            value={value} suggestions={employes}
                                            completeMethod={searchEmploye} field="name" dropdown forceSelection
                                            textError={errors.employesSelect && errors.employesSelect.message}
                                            errors={_.has(errors, 'employesSelect')}
                                            onChange={(e) => onChange(e)}
                                        />
                                    </>
                                )}
                                name="employesSelect"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Campo requerido."
                                    }
                                }}
                                defaultValue=""
                            />
                        </div>
                        <div className="md:ml-4 md:mt-5">
                            <Button label="Buscar" className="p-button " />
                        </div>
                    </div>
                    <div className="md:col-4 col-12 ">
                        <div className=" md:block hidden">
                            <div className="flex md:justify-content-end">
                                <Button type="button" label={`Exportar ${porcentage}`} icon="pi pi-file-excel" className="p-button " onClick={exportExcel} />
                            </div>
                        </div>
                        <div className="md:hidden block ">
                            <div className="flex flex-wrap justify-content-between w-full">
                                <Button type="button" icon="pi pi-file-excel" className="p-button" onClick={exportExcel} />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    );

    return (
        <DataTable
            showGridlines
            className="p-datatable-responsive"
            value={dataList}
            dataKey="id"
            paginator
            loading={loadingList}
            onPage={onCustomPage}
            rows={rows}
            paginatorTemplate={templatePaginator}
            emptyMessage="No se encontraron resultados"
            currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} bienes patrimoniales"
            scrollable
            scrollHeight={`${height - 500}px`}
            header={header}
            sortMode="multiple"
            multiSortMeta={multiSortMeta}
            onSort={e => sortFunction(e.multiSortMeta)}
            sortOrder={multiSortMeta}
        >
            <Column field="margesi_code" header="Código Margesi" sortable body={margesiCodeField}></Column>
            <Column field="patrimonial_code" header="Código Patrimonial" sortable body={patrimonialCodeField}></Column>
            <Column field="name" header="Nombre" sortable body={nameField} ></Column>
            <Column field="dni" header="DNI" sortable body={dniField} ></Column>
            <Column field="dependency" header="Dependencia" sortable body={dependencyField}></Column>
            <Column field="blueprint" header="Modelo" sortable body={blueprintField}></Column>
            <Column field="brand" header="Marca" sortable body={brandField}></Column>
            <Column field="asset_state" header="Estado" sortable body={assetStateField}></Column>
            <Column field="serial_number" header="N° de serie" sortable body={serieField}></Column>
            <Column field="bar_code" header="Código de barra" sortable body={barCodeField}></Column>

            <Column field="description" header="Descripción" sortable body={descriptionField}></Column>
        </DataTable>
    )
}