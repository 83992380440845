import { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';

export default function useAuth() {
    let status = Cookies.get('app_token') !== '' && Cookies.get('app_token') !== undefined;
    const [state, setState] = useState(status);

    const check = () => {
        let status = Cookies.get('app_token') !== '' && Cookies.get('app_token') !== undefined;

        setState(status);
        // if(!status) history.push("/"); 
    };

    const initFetch = useCallback(() => {
        check();
        return () => {
          setState(null)
        }
      }, []);
    
      useEffect(() => {
        initFetch();
        return () => {
          setState(null)
        }
      }, [initFetch]);

   
   
    return state;
}