import React, { useState, useEffect, useRef, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'; 
import { Toast } from 'primereact/toast';
import { CalendarApp as Calendar } from '@components/form/Calendar'; 
import { DropdownApp as Dropdown } from '@components/form/Dropdown'; 
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Ripple } from 'primereact/ripple';
import { useParams, useHistory } from "react-router-dom";
import { InputApp as InputText } from '@components/form/InputText';
import { VacacionesService } from "@services/VacacionesService";
import _ from 'lodash';
import useWindowDimensions from '@hooks/UseHeight'
import { formatDate, formatCalendar } from '@util/date';
import { getListPeriods } from '@services/ReportService';
import { useForm, Controller } from 'react-hook-form';

export const Vacaciones = () => {
    const defaultValues = {
        date: '',
        period: '',
        description: '',
        search: ''
    }
    const { control, handleSubmit, formState: { errors }, setValue, reset, watch } = useForm({ defaultValues });
    const search = watch('search')
    const history = useHistory();
    let { id } = useParams();
    const [idemploye, setIdemploye] = useState(id)

    const { height } = useWindowDimensions();
    let emptyElement = {
        id: null,
        employee_id: '',
        start_date: '',
        end_date: '',
        description: ''
    };

    const [periods, setPeriods] = useState([]);
    const [errorGeneral, setErrorGeneral] = useState(false);
    const [elements, setElements] = useState([]); 
    const [loadingAllElements, setLoadingAllElements] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingDeleteIds, setLoadingDeleteIds] = useState(false);
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [elementDialog, setElementDialog] = useState(false);
    const [deleteElementDialog, setDeleteElementDialog] = useState(false);
    const [deleteElementsDialog, setDeleteElementsDialog] = useState(false);
    const [element, setElement] = useState(emptyElement);
    const [condition, setCondition] = useState('Nuevo'); 
    const [selectedElements, setSelectedElements] = useState(null);
    const [selectedElementsId, setSelectedElementsId] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const elementsService = new VacacionesService();

    //pagination 
    const [rows, setRows] = useState(10); 
    
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);

    useEffect(() => {
        async function getInit() {
            setLoadingAllElements(true)
            getAllElementsFunction(currentpage, rows);
            setLoadingAllElements(false);
            getListPeriodsInitial();
        }
        getInit();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openNew = () => { 
        reset()
        setCondition('Nuevo');
        setElement(emptyElement);
        setSubmitted(false);
        setElementDialog(true);
    }

    const hideDialog = () => {
        setCondition('Nuevo');

        setElementDialog(false);
        setSubmitted(false);
    }

    const hideDeleteElementDialog = () => {
        setDeleteElementDialog(false);
    }

    const hideDeleteElementsDialog = () => {
        setDeleteElementsDialog(false);
    }

    const saveElement = async (data) => { 
        setSubmitted(true);  
        setLoading(true)
        data.start_date = (data.date[0] ? formatDate(data.date[0], 'yyyy-MM-dd') : '')
        data.end_date = (data.date[1] ? formatDate(data.date[1], 'yyyy-MM-dd') : '')
        let el = { ...data, employee_id: idemploye, period: data?.period.code }
        let result;
        if (condition === 'Nuevo') {
            result = await elementsService.setCreateElement(el, idemploye)
            if(result?.errors)  {
                for (const  error  of  result?.errors) {
                    toast.current.show({ severity: 'error', summary: error, detail: result.message, life: 3000 });
                }
            }
            if ( !result ) { 
                setErrorGeneral(true)
                setLoading(false)
                toast.current.show({ severity: 'error', summary: 'Ocurrio un error.', detail: result.message, life: 3000 });
                return false;
            } else if(!result?.errors){
                setElementDialog(false);
                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
            }
            
        } else {
            result = await elementsService.setUpdateElement(el, element.id)
            if(result?.errors)  { 
                for (const  error  of  result?.errors) {
                    if(error?.end_date) {
                        for (const error_end_date of error?.end_date) {
                            toast.current.show({ severity: 'error', summary: error_end_date, detail: result.message, life: 3000 });
                        } 
                    } else {
                        toast.current.show({ severity: 'error', summary: error, detail: result.message, life: 3000 });
                    }
                    
                }
            }
            if ( !result ) {
                setErrorGeneral(true)
                setLoading(false)
                toast.current.show({ severity: 'error', summary: 'Ocurrio un error.', detail: result.message, life: 3000 });
                return false;
            } else if(!result?.errors){
                setElementDialog(false);
                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
            }
            
        }
        setErrorGeneral(false)
        getAllElementsFunction(currentpage, rows);
        setLoading(false)  
    }

    const editElement = (element) => {
        let data = { ...element }
        let start_date = data.start_date ? formatCalendar(data.start_date) : ''
        let end_date = data.end_date ? formatCalendar(data.end_date) : '' 
        setCondition('Actualizar');
        setElement({ ...data });
        setValue('date', [start_date, end_date])
        setValue('description', element.description)
 
        setValue('period', {code: element.period , name: element.period  })
        
        setElementDialog(true);
    }

    const confirmDeleteElement = (element) => {
        setElement(element);
        setDeleteElementDialog(true);
    }

    const deleteElement = async () => {
        let result = await elementsService.setDeleteElement(element.id)

        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        setDeleteElementDialog(false);
        setElement(emptyElement);
        getAllElementsFunction(currentpage, rows);
    }

    const getAllElementsFunction = async (page, row, q = null, sort = null) => {
        await elementsService.getAllElements(page, row, q, sort, idemploye).then(data => {
            if (!_.isEmpty(data.meta)) {
                setCurrentpage(data.meta.current_page);
                setTotalrecords(data.meta.total);
                setElements(data);
                setLastpage(data.meta.last_page);
            }

        });
    }

    const getListPeriodsInitial = async ()=> {
        const data = await getListPeriods(); 
        const dataFormat = data?.data?.periods.map(item => ({ name: item, code: item }))
        setPeriods(dataFormat);
    }
    const confirmDeleteSelected = () => {
        let ids = [];
        for (let i = 0; i < selectedElements.length; i++) {
            ids.push(selectedElements[i].id);
        }
        setSelectedElementsId(ids);
        setDeleteElementsDialog(true);
    }

    const deleteSelectedElements = async () => {
        setLoadingDeleteIds(true)
        let result = await elementsService.setDeleteMultipleElement(selectedElementsId)
        getAllElementsFunction(currentpage, rows);
        setLoadingDeleteIds(false)
        setDeleteElementsDialog(false);
        setSelectedElements(null);
        setSelectedElementsId([]);
        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
    }
 

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getAllElementsFunction(event.page + 1, event.rows);
    }

    const getPaginationElement = async (event) => {
        setCurrentpage(event.page + 1);
        getAllElementsFunction(event.page + 1, event.rows);
    }

    const sortFunctionElements = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }


        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }

        getAllElementsFunction(currentpage, rows, null, string);
    }
 
    const searchGlobalElements = useCallback(
        _.debounce(async (value) => {
            getAllElementsFunction(currentpage, rows, value);
        }, 600),
        [],
    );

    useEffect(()=>{
       searchGlobalElements(search)
    }, [search])

    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(elements.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(elements.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                        onClick={() => getPaginationElement({
                            page: index,
                            rows: rows
                        })}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(elements.data) ? <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },

    }


    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="justify-content-center w-full md:flex hidden">
                    <div>
                        <Button icon="pi pi-pencil" className="p-button  mr-2" onClick={() => editElement(rowData)} />
                        <Button icon="pi pi-trash" className="p-button  p-button-danger" onClick={() => confirmDeleteElement(rowData)} />
                    </div>
                </div>
                <div className="justify-content-between w-6rem md:hidden flex">
                    <div>
                        <Button icon="pi pi-pencil" className="p-button  mr-2" onClick={() => editElement(rowData)} />
                        <Button icon="pi pi-trash" className="p-button  p-button-danger" onClick={() => confirmDeleteElement(rowData)} />
                    </div>
                </div>
            </>
        );
    }

    const header = (
        <div className="table-header-container">
            <div className="grid"> 
                <div className="md:col-5 col-12">
                    <div className="table-header">
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <InputText  
                                            iconLeft="pi pi-search"
                                            value={value}
                                            onChange={onChange}  /> 
                                        )}
                                    name="search" 
                                    defaultValue=""
                                /> 
                    </div>
                </div>
                <div className="md:col-7 col-12 ">
                    <div className=" md:block hidden">
                        <div className="flex md:justify-content-end">
                            <Button label="Nuevo" icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
                            <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedElements || !selectedElements.length} />
                            {/* <Button label="Exportar" icon="pi pi-file-excel" className="p-button " />*/}

                        </div>
                    </div>
                    <div className="md:hidden block ">
                        <div className="flex flex-wrap justify-content-between w-full">
                            <Button icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
                            <Button icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedElements || !selectedElements.length} />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
    const elementDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button disabled={loading} label={
                loading ? '...Cargando' : (condition === 'Nuevo' ? 'Guardar' : 'Actualizar')
            } icon="pi pi-check" onClick={handleSubmit(saveElement)} />
        </>
    );
    const deleteElementDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteElementDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteElement} />
        </>
    );
    const deleteElementsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteElementsDialog} />
            <Button label={loadingDeleteIds ? '...Cargando' : 'Si'} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedElements} />
        </>
    );

    const periodField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Periodo</span>
                {rowData.period}
            </>
        )
    }

    const descriptionField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descripción</span>
                {rowData.description}
            </>
        )
    }

    const dayField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Días</span>
                {rowData.days}
            </>
        )
    }
    
    const startdateField = (rowData) => {
        return (
            <>
                <span className="p-column-title">F. de Inicio</span>
                {rowData.start_date}
            </>
        )
    }
    const enddateField = (rowData) => {
        return (
            <>
                <span className="p-column-title">F. de Fin</span>
                {rowData.end_date}
            </>
        )
    }
 
    
    return (
        <div className="datatable-responsive">
            <div className="mb-4">
                <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-text" onClick={() => history.push('/empleado/listado')} tooltip="Regresar" />
            </div>
            <div className="card">
                <Toast ref={toast} />
                <h5 className="p-m-0">Listado de vacaciones</h5>
                <DataTable
                    showGridlines
                    className="p-datatable-responsive"
                    ref={dt}
                    value={elements.data}
                    selection={selectedElements}
                    onSelectionChange={(e) => setSelectedElements(e.value)}
                    dataKey="id"
                    paginator
                    loading={loadingAllElements}
                    onPage={onCustomPage}
                    rows={rows}
                    paginatorTemplate={templatePaginator}
                    emptyMessage="No se encontraron resultados"
                    currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} vacaciones"
                    scrollable
                    scrollHeight={`${height - 460}px`}
                    header={header}
                    sortMode="multiple"
                    multiSortMeta={multiSortMeta}
                    onSort={e => sortFunctionElements(e.multiSortMeta)}
                    sortOrder={multiSortMeta}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="period" header="Periodo" sortable body={periodField}></Column>
                    <Column field="start_date" header="Fecha de Inicio" sortable body={startdateField}></Column>
                    <Column field="end_date" header="Fecha de Fin" sortable body={enddateField}></Column>
                    <Column field="description" header="Descripción" sortable body={descriptionField}></Column>
                    <Column field="days" header="Días" sortable body={dayField}></Column>
                    <Column body={actionBodyTemplate} className="text-center"></Column>
                </DataTable>


                <Dialog
                    visible={elementDialog}
                    style={{ width: '450px' }}
                    header={`${condition} Registro`}
                    modal
                    className="p-fluid"
                    footer={elementDialogFooter}
                    onHide={hideDialog}>
                    <div className='field'>
                    <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Calendar
                                        label="Fecha de inicio - Fecha de fin"
                                        selectionMode="range" 
                                        onChange={onChange}
                                        value={value}
                                        textError={errors.date?.message}
                                        errors={errors.date}
                                    />
                                    )}
                                    name="date"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Campo requerido."
                                        }
                                    }}
                                    defaultValue=""
                                />
                           
                    
                    </div> 

                    <div className="field"> 
                        <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                       <>
                                        <label htmlFor="description">Periodo</label>
                                        <Dropdown 
                                            value={value} 
                                            options={periods} 
                                            onChange={onChange} 
                                            optionLabel="name"  
                                            textError={errors.period?.message}
                                            errors={errors.period}
                                            />
                                       </>
                                    )}
                                    name="period"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Campo requerido."
                                        }
                                    }}
                                    defaultValue=""
                                />
                        
                    </div>

                    <div className="field pt-2">
                    <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                      <>
                                       <label htmlFor="description">Descripción</label>
                                        <InputText id="description" value={value} onChange={onChange} 
                                        textError={errors.description && errors.description.message} 
                                        errors={errors.description}
                                        />
                                     
                                      </>
                                    )}
                                    name='description'
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Campo requerido."
                                        }
                                    }}
                                    defaultValue=""
                                />
                       
                    </div>

                    {errorGeneral ?
                      <small className="w-full text-red-700 label-app text-center">Ocurrio un error.</small> : null
                    }
                </Dialog>

                <Dialog
                    visible={deleteElementDialog}
                    style={{ width: '480px' }}
                    header="Eliminar registro"
                    modal
                    footer={deleteElementDialogFooter}
                    onHide={hideDeleteElementDialog}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {element && <span>¿Está seguro de eliminar al registro?</span>}
                    </div>
                </Dialog>

                <Dialog
                    visible={deleteElementsDialog}
                    style={{ width: '480px' }}
                    header="Eliminar registros"
                    modal
                    footer={deleteElementsDialogFooter}
                    onHide={hideDeleteElementsDialog}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {element && <span>¿Está seguro de eliminar los registros seleccionados?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}