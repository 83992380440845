import React, { useState, createContext,useMemo } from 'react'; 
export const Context = createContext();

export const ContextHorario = (props) => {
  const { children } = props;  
  const [store, setStore] = useState({
    data: [],
    select: {}
  }); 
  const storeMemo = useMemo(
    () => ({ store, setStore }), 
    [store]
  ); 

  const  actions  =  {
    setData: (value) => { 
      setStore({ ...store, data: value });
    },

    setUpdate: (value,index) => {
      let data =  store.data.map((param,indexData)=> indexData===index ? value: param );  
      setStore({ ...store, data, select: {} });
    },

    setPush: value => {
        setStore({ ...store, data: [value, ...store.data] });
    },

    setSelect: value => { 
      setStore({ ...store, select: value });
    },

    deteleteItemContext:  value => { 
      let data =  store.data.filter( param => param.id!==value);  
      setStore({ ...store, data, select: {} });
    },
   }  
  
 
  return (
    <>
      <Context.Provider value={{ store:storeMemo.store, actions }}>
        {children}
      </Context.Provider>
    </>
  )
}