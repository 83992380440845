
import axios_client from "@util/axios_client";

export class SistemaPensionService {

    async getAll(q = null) {
        return await axios_client.get('/api/pensionSystem/listForReport'
        )
            .then(res => res.data);
    }

}