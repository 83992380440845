import React from 'react';

export const AppFooter = (props) => {

    return (
        <>
         {/* <div className="layout-footer">
             <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo.png' : 'assets/layout/images/logo.png'} alt="Logo" height="50" className="mr-2" />
             Creado por
             <span className="font-medium ml-2">Olimpo Code</span>
         </div> */}
        </>
    );
}
