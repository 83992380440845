import React from 'react';
import PropTypes from 'prop-types'
import { InputTextarea } from 'primereact/inputtextarea';
import { Label } from './Label';
export const InputTextArea = (props) => {
  const { errors, textError, label, onChange,  value, type, placeholder } = props;
  let classInput = 'w-full'
  
  return (
    <>
     <div className="py-2 w-full">
     <Label>{label}</Label>
      <div className="p-field pt-1"> 
        <span className={classInput}> 
          <InputTextarea rows={5}  autoResize  className={`w-full p-inputtext-md ${errors ? 'p-invalid' : ''}`} id="inputtext" placeholder={placeholder}
            value={value} onChange={(e) => onChange(e.target.value)}
            type={type} /> 
        </span>
        {errors &&
          <div>  <small className="w-full text-red-700 label-app">{textError}</small></div>
        }
      </div>
     </div>
    </>
  )
};

InputTextArea.defaultProps = {
  errors: false,
  iconLeft: '',
  onChange: () => { },
  onClickRight: () => { },
  textError: '',
  label: '',
  value: '',
  type: 'text',
  placeholder: ''
};
InputTextArea.propTypes = {
  errors: PropTypes.bool,
  iconLeft: PropTypes.string,
  onChange: PropTypes.func,
  onClickRight: PropTypes.func,
  textError: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string
}
