import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputApp as InputText } from '@components/form/InputText';
import { CalendarApp as Calendar } from '@components/form/Calendar';
import { DropdownApp as Dropdown } from '@components/form/Dropdown';
import { Toast } from 'primereact/toast';
import { useHistory, useParams } from "react-router-dom";
import { SelectButtonApp as SelectButton } from '@components/form/SelectButton';
import { useForm, Controller } from 'react-hook-form';
import { Label } from '@components/form/Label';

import { formatDate, formatCalendar } from '@util/date';

import { EmployesService } from "@services/EmployesService";

export const Registro = ({ location }) => {
    let { id } = useParams();
    const [idemploye, setIdemploye] = useState(id)
    const [data, setData] = useState({})
    const history = useHistory();
    const [dataemployee, setDataemployee] = useState({ offices: [] });
    const toast = useRef(null);
    const employesService = new EmployesService();
    const [loading, setLoading] = useState(false);
    const [errorsback, setErrorsback] = useState([]);

    useEffect(async () => {

        async function getInit() {
            let result = await employesService.getEmployeData();
            setDataemployee(result ? result : [])

            if (location.pathname !== '/empleado/registro') {
                let results = await employesService.getEmploye(id);
                setData(results) //llenar data , le agregar en el defaulValue={data.name} 
                setValue("dni", results.dni);
                setValue("name", results.name);
                setValue("last_name", results.last_name);
                setValue("birth_date", results.birth_date ? formatCalendar(results.birth_date) : '');
                setValue("phone", results.phone);
                setValue("address", results.address);
                setValue("company_email", results.company_email);
                setValue("contract_number", results.contract_number);
                setValue("call_number", results.call_number);
                setValue("profession", results.profession);
                setValue("sex_id", results.sex);
                setValue("civil_status_id", results.civil_status);
                setValue("blood_type_id", results.blood_type);
                setValue("comorbidity_id", results.comorbidity);
                setValue("pension_system_id", results.pension_system);
                setValue("contract_id", results.contract);
                setValue("position_id", results.position);
                setValue("employment_regime_id", results.employment_regime);
                setValue("office_id", results.office);
                setValue("private_email", results.private_email);
                setValue("hasChildren", (results.hasChildren ? "Si" : "No"));
                setValue("enabled", (results.enabled ? "Habilitado" : "Deshabilitado"));
                setValue("start_date", (results.start_date ? formatDate(results.start_date, 'yyyy-MM-dd') : ''));
                setValue("end_date", (results.end_date ? formatDate(results.end_date, 'yyyy-MM-dd') : ''));
                setValue("hadCovid", (results.hadCovid ? "Si" : "No"));
            }
        }
        getInit();

        return () => {
            //setData(results)
        }
    }, [])

    const defaultValues = {
        dni: data.dni,
        name: "",
        last_name: "",
        birth_date: "",
        phone: "",
        address: "",
        company_email: "",
        contract_number: "",
        call_number: "",
        profession: "",
        sex_id: null,
        civil_status_id: null,
        blood_type_id: null,
        comorbidity_id: null,
        pension_system_id: null,
        contract_id: null,
        position_id: null,
        employment_regime_id: null,
        office_id: null,
        private_email: "",
        hasChildren: false,
        start_date: "",
        end_date: "",
        hadCovid: false
    }
    const { watch, control, handleSubmit, formState: { errors }, setValue } = useForm({ defaultValues });

    const dependencie_id_selected = watch("dependencie_id");

    const onSubmit = async (data) => {
        console.log(data);
        setLoading(true)
        let employe = {
            address: data.address,
            birth_date: (data.birth_date ? formatDate(data.birth_date, 'yyyy-MM-dd') : ''),
            blood_type_id: (data.blood_type_id ? data.blood_type_id.id : ''),
            call_number: data.call_number,
            civil_status_id: (data.civil_status_id ? data.civil_status_id.id : ''),
            comorbidity_id: (data.comorbidity_id ? data.comorbidity_id.id : ''),
            company_email: data.company_email,
            contract_id: (data.contract_id ? data.contract_id.id : ''),
            contract_number: data.contract_number,
            dni: data.dni,
            employment_regime_id: (data.employment_regime_id ? data.employment_regime_id.id : ''),
            end_date: (data.end_date ? formatDate(data.end_date, 'yyyy-MM-dd') : ''),
            hadCovid: (data.hadCovid === 'Si' ? true : false),
            hasChildren: (data.hasChildren === 'Si' ? true : false),
            last_name: data.last_name,
            name: data.name,
            office_id: (data.office_id ? data.office_id.id : ''),
            pension_system_id: (data.pension_system_id ? data.pension_system_id.id : ''),
            phone: data.phone,
            position_id: (data.position_id ? data.position_id.id : ''),
            private_email: data.private_email,
            profession: data.profession,
            sex_id: (data.sex_id ? data.sex_id.id : ''),
            enabled: (data.enabled === 'Habilitado' ? true : false),
            start_date: (data.start_date ? formatDate(data.start_date, 'yyyy-MM-dd') : ''),
        };

        let result;
        if (location.pathname !== '/empleado/registro') {
            result = await employesService.setUpdateEmploye(employe, idemploye)
        } else {
            result = await employesService.setCreateEmploye(employe)
        }
        if (result.errors) {
            setErrorsback(result.errors[0]);
            setLoading(false)
            return false;
        }
        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        setErrorsback([]);

        setTimeout(() => {
            setLoading(false)
            history.push('/empleado/listado')
        }, 1500);
    }

    return (
        <>
            <div>
                <Toast ref={toast} />
                <div className="mb-4">
                    <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-text" onClick={() => history.push('/empleado/listado')} tooltip="Regresar" />
                </div>
                <div className="card">
                    <h5>{(location.pathname !== '/empleado/registro' ? 'Editar' : 'Registrar')} Servidor</h5>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className="grid">
                            <div className="md:col-6 col-12">
                                <div className="py-2">
                                    <Label font="font-medium">Datos personales</Label>
                                </div>
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                label="DNI"
                                                errors={errorsback.dni && errorsback.dni.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.dni && errorsback.dni[0]} />
                                        </>
                                    )}
                                    name="dni"
                                    defaultValue=""
                                />
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                label="Nombres"
                                                errors={errorsback.name && errorsback.name.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.name && errorsback.name[0]} />
                                        </>
                                    )}
                                    name="name"
                                    defaultValue=""
                                />
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                label="Apellidos"
                                                errors={errorsback.last_name && errorsback.last_name.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.last_name && errorsback.last_name[0]} />
                                        </>
                                    )}
                                    name="last_name"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                label="Email"
                                                errors={errorsback.private_email && errorsback.private_email.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.private_email && errorsback.private_email[0]} />
                                        </>
                                    )}
                                    name="private_email"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Calendar
                                                label="Fecha de Nacimiento" 
                                                errors={errorsback.birth_date && errorsback.birth_date.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.birth_date && errorsback.birth_date[0]} />
                                        </>
                                    )}
                                    name="birth_date"
                                    type="date"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                label="Celular"
                                                errors={errorsback.phone && errorsback.phone.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.phone && errorsback.phone[0]} />
                                        </>
                                    )}
                                    name="phone"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                label="Dirección"
                                                errors={errorsback.address && errorsback.address.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.address && errorsback.address[0]} />
                                        </>
                                    )}
                                    name="address"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <SelectButton label="Sexo"
                                                value={value}
                                                options={dataemployee.sexos}
                                                optionLabel="name"
                                                onChange={(e) => onChange(e)}
                                                errors={errorsback.sex_id && errorsback.sex_id.length > 0}
                                                textError={errorsback.sex_id && errorsback.sex_id[0]} />
                                        </>
                                    )}
                                    name="sex_id"
                                    defaultValue=""
                                />


                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Dropdown
                                                label="Estado Civil"
                                                value={value}
                                                options={dataemployee.civil_statuses}
                                                optionLabel="name"
                                                onChange={(e) => onChange(e)}
                                                errors={errorsback.civil_status_id && errorsback.civil_status_id.length > 0}
                                                textError={errorsback.civil_status_id && errorsback.civil_status_id[0]} />
                                        </>
                                    )}
                                    name="civil_status_id"
                                    defaultValue=""
                                />


                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <SelectButton
                                                label="¿Tiene Hijos?"
                                                value={value}
                                                options={["Si", "No"]}
                                                onChange={(e) => onChange(e)}
                                                errors={errorsback.hasChildren && errorsback.hasChildren.length > 0}
                                                textError={errorsback.hasChildren && errorsback.hasChildren[0]} />
                                        </>
                                    )}
                                    name="hasChildren"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <SelectButton
                                                label="¿Ha tenido covid?"
                                                value={value}
                                                options={["Si", "No"]}
                                                onChange={(e) => onChange(e)}
                                                errors={errorsback.hadCovid && errorsback.hadCovid.length > 0}
                                                textError={errorsback.hadCovid && errorsback.hadCovid[0]} />
                                        </>
                                    )}
                                    name="hadCovid"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Dropdown
                                                label="Grupo sanguíneo"
                                                value={value}
                                                options={dataemployee.blood_types}
                                                optionLabel="name"
                                                onChange={(e) => onChange(e)}
                                                errors={errorsback.blood_type_id && errorsback.blood_type_id.length > 0}
                                                textError={errorsback.blood_type_id && errorsback.blood_type_id[0]} />
                                        </>
                                    )}
                                    name="blood_type_id"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Dropdown
                                                label="Comorbilidad"
                                                value={value}
                                                options={dataemployee.comorbidities}
                                                optionLabel="name"
                                                onChange={(e) => onChange(e)}
                                                errors={errorsback.comorbidity_id && errorsback.comorbidity_id.length > 0}
                                                textError={errorsback.comorbidity_id && errorsback.comorbidity_id[0]} />
                                        </>
                                    )}
                                    name="comorbidity_id"
                                    defaultValue=""
                                />
                            </div>

                            <div className="md:col-6 col-12">
                                <div className="py-2">
                                    <Label font="font-medium">Datos Empresa</Label>
                                </div>
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                label="Correo Institucional"
                                                errors={errorsback.company_email && errorsback.company_email.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.company_email && errorsback.company_email[0]} />
                                        </>
                                    )}
                                    name="company_email"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                label="Profesión"
                                                errors={errorsback.profession && errorsback.profession.length > 0}
                                                onChange={onChange}
                                                value={value}
                                                textError={errorsback.profession && errorsback.profession[0]} />
                                        </>
                                    )}
                                    name="profession"
                                    defaultValue=""
                                />

                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <Dropdown
                                                label="Sistema de Pensiones"
                                                value={value}
                                                options={dataemployee.pension_systems}
                                                optionLabel="name"
                                                onChange={(e) => onChange(e)}
                                                errors={errorsback.pension_system_id && errorsback.pension_system_id.length > 0}
                                                textError={errorsback.pension_system_id && errorsback.pension_system_id[0]} />
                                        </>
                                    )}
                                    name="pension_system_id"
                                    defaultValue=""
                                />

                                {
                                    location.pathname === '/empleado/registro' ?
                                        <div>

                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Calendar
                                                            label="Fecha de inicio de contrato"
                                                            errors={errorsback.start_date && errorsback.start_date.length > 0}
                                                            onChange={onChange}
                                                            value={`${value}`}
                                                            textError={errorsback.start_date && errorsback.start_date[0]} />
                                                    </>
                                                )}
                                                name="start_date"
                                                type="date"
                                                defaultValue=""
                                            />

                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Calendar
                                                            label="Fecha de fin de contrato"
                                                            errors={errorsback.end_date && errorsback.end_date.length > 0}
                                                            onChange={onChange}
                                                            value={`${value}`}
                                                            textError={errorsback.end_date && errorsback.end_date[0]} />
                                                    </>
                                                )}
                                                name="end_date"
                                                type="date"
                                                defaultValue=""
                                            />

                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <InputText
                                                            label="Número de Convocatoria"
                                                            errors={errorsback.call_number && errorsback.call_number.length > 0}
                                                            onChange={onChange}
                                                            value={value}
                                                            textError={errorsback.call_number && errorsback.call_number[0]} />
                                                    </>
                                                )}
                                                name="call_number"
                                                defaultValue=""
                                            />


                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Dropdown
                                                            label="Tipo de Contrato"
                                                            value={value}
                                                            options={dataemployee.contracts}
                                                            optionLabel="type"
                                                            onChange={(e) => onChange(e)}
                                                            errors={errorsback.contract_id && errorsback.contract_id.length > 0}
                                                            textError={errorsback.contract_id && errorsback.contract_id[0]} />
                                                    </>
                                                )}
                                                name="contract_id"
                                                defaultValue=""
                                            />

                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <InputText
                                                            label="Número de Contrato"
                                                            errors={errorsback.contract_number && errorsback.contract_number.length > 0}
                                                            onChange={onChange}
                                                            value={value}
                                                            textError={errorsback.contract_number && errorsback.contract_number[0]} />
                                                    </>
                                                )}
                                                name="contract_number"
                                                defaultValue=""
                                            />

                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Dropdown
                                                            label="Cargo"
                                                            value={value}
                                                            options={dataemployee.positions}
                                                            optionLabel="name"
                                                            onChange={(e) => onChange(e)}
                                                            errors={errorsback.position_id && errorsback.position_id.length > 0}
                                                            textError={errorsback.position_id && errorsback.position_id[0]} />
                                                    </>
                                                )}
                                                name="position_id"
                                                defaultValue=""
                                            />


                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Dropdown
                                                            label="Dependencia"
                                                            value={value}
                                                            options={dataemployee.dependencies}
                                                            optionLabel="name"
                                                            onChange={(e) => onChange(e)}
                                                            errors={errorsback.dependencie_id && errorsback.dependencie_id.length > 0}
                                                            textError={errorsback.dependencie_id && errorsback.dependencie_id[0]} />
                                                    </>
                                                )}
                                                name="dependencie_id"
                                                defaultValue=""
                                            />

                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Dropdown
                                                            label="Oficina"
                                                            value={value}
                                                            options={dataemployee.offices.filter(param => dependencie_id_selected && param.dependency_id === dependencie_id_selected.id)}
                                                            optionLabel="name"
                                                            onChange={(e) => onChange(e)}
                                                            errors={errorsback.office_id && errorsback.office_id.length > 0}
                                                            textError={errorsback.office_id && errorsback.office_id[0]} />
                                                    </>
                                                )}
                                                name="office_id"
                                                defaultValue=""
                                            />

                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <Dropdown
                                                            label="Régimen Laboral"
                                                            value={value}
                                                            options={dataemployee.employment_regimes}
                                                            optionLabel="name"
                                                            onChange={(e) => onChange(e)}
                                                            errors={errorsback.employment_regime_id && errorsback.employment_regime_id.length > 0}
                                                            textError={errorsback.employment_regime_id && errorsback.employment_regime_id[0]} />
                                                    </>
                                                )}
                                                name="employment_regime_id"
                                                defaultValue=""
                                            />
                                        </div>

                                        : <div>

                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <>
                                                        <SelectButton
                                                            label="Estado del Servidor"
                                                            value={value}
                                                            options={["Habilitado", "Deshabilitado"]}
                                                            onChange={(e) => onChange(e)}
                                                            errors={errorsback.enabled && errorsback.enabled.length > 0}
                                                            textError={errorsback.enabled && errorsback.enabled[0]} />
                                                    </>
                                                )}
                                                name="enabled"
                                                defaultValue=""
                                            />
                                        </div>
                                }




                            </div>
                        </div>
                        <div className="flex md:justify-content-end mt-4">
                            <Button disabled={loading} label={
                                loading ? '...Cargando' : (location.pathname !== '/empleado/registro' ? 'Actualizar' : 'Guardar')
                            } icon="pi pi-check" />
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}