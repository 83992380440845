import axios_client from "@util/axios_client";
import _ from 'lodash';

const getListPermissionsFromEmployee = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/permissionsFromEmployee${url}`, {

            employee_id: _.has(data, 'employee_id') ? data.employee_id : '',
            start_date: _.has(data, 'start_date') ? data.start_date : '',
            end_date: _.has(data, 'end_date') ? data.end_date : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListHolidaysFromEmployee = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/holidaysFromEmployee${url}`, {

            employee_id: _.has(data, 'employee_id') ? data.employee_id : '',
            year: _.has(data, 'year') ? data.year : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListPermissionsInPeriod = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }

        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/permissionsInPeriod${url}`, {

            start_date: _.has(data, 'start_date') ? data.start_date : '',
            end_date: _.has(data, 'end_date') ? data.end_date : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListPostingFromEmployee = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }

        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/postingFromEmployee${url}`, {

            employee_id: _.has(data, 'employee_id') ? data.employee_id : '',
            start_date: _.has(data, 'start_date') ? data.start_date : '',
            end_date: _.has(data, 'end_date') ? data.end_date : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListPostingInPeriod = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/postingInPeriod${url}`, {

            start_date: _.has(data, 'start_date') ? data.start_date : '',
            end_date: _.has(data, 'end_date') ? data.end_date : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListChildrenForEmploye = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/relativeFromEmployee${url}`, {

            employee_id: _.has(data, 'employee_id') ? data.employee_id : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListWaiverInPeriod = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/waiverInPeriod${url}`, {

            start_date: _.has(data, 'start_date') ? data.start_date : '',
            end_date: _.has(data, 'end_date') ? data.end_date : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListPermissionsFromRegime = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }

        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/permissionsFromRegime${url}`, {

            employment_regime_id: _.has(data, 'employment_regime_id') ? data.employment_regime_id : '',
            start_date: _.has(data, 'start_date') ? data.start_date : '',
            end_date: _.has(data, 'end_date') ? data.end_date : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListEmployeeFromRegime = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/employeeFromEmploymentRegime${url}`, {

            employment_regime_id: _.has(data, 'employment_regime_id') ? data.employment_regime_id : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListEmployeeFromBloodType = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/employeeFromBloodType${url}`, {

            blood_type_id: _.has(data, 'blood_type_id') ? data.blood_type_id : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListContractFromContractTypeEmployee = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/employeeFromContractType${url}`, {

            contract_id: _.has(data, 'contract_id') ? data.contract_id : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListEmployeeFatherWithChildren = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/employeeWithChildren${url}`, {

            filter: 'PADRE',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListEmployeeMatherWithChildren = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/employeeWithChildren${url}`, {

            filter: 'MADRE',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListEmployeeWhoHadCovid = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/employeeWhoHadCovid${url}`, {

            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListEmployeeFromComorbilty = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/employeeFromComorbidity${url}`, {

            comorbidity_id: _.has(data, 'comorbidity_id') ? data.comorbidity_id : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListEmployeeFromSystemPension = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/employeeFromPensionSystem${url}`, {

            filter: _.has(data, 'filter_id') ? data.filter_id : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListEmployeeFromDisability = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/employeeFromDisability${url}`, {

            disability_id: _.has(data, 'disability_id') ? data.disability_id : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListEmployeeFromChildrenPeriod = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/employeeWithChildrenPeriod${url}`, {

            start: _.has(data, 'start') ? data.start : '',
            end: _.has(data, 'end') ? data.end : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getDataSheetEmploye = async (data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let response = await axios_client.post(`/api/query/employeeShow`, {

            employee_id: _.has(data, 'employee_id') ? data.employee_id : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListAssetAll = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/assetAll${url}`, {
            type: _.has(data, 'type') ? data.type : '',
        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {
        return false;
    }
}

const getListAssetForEmploye = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.post(`/api/query/assetFromEmployee${url}`, {

            employee_id: _.has(data, 'employee_id') ? data.employee_id : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getListAssetTransferAll = async (page, itemsPerPage, search = null, sort, data, progressUpload) => {
    try {

        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let response = await axios_client.get(`/api/transfer${url}`);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getDownloadPdfTransfer = async (data, progressUpload) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: data.type === 'query' ? 'json' : 'blob',
            onDownloadProgress: progress => {
                let progressPercentage = Math.round(progress.loaded / progress.total * 100);
                progressUpload(progressPercentage)
            }
        }

        let response = await axios_client.post(`/api/query/transferShow`, {

            transfer_id: _.has(data, 'transfer_id') ? data.transfer_id : '',
            date: _.has(data, 'date') ? data.date : '',
            type: _.has(data, 'type') ? data.type : '',

        }, config);

        return response.status === 200 ? response.data : false;
    } catch (error) {

        return false;
    }
}

const getAssetByBarCode = async (data) => {
    try {
        let response = await axios_client.get(`/api/asset/getByBarCode/${data.bar_code}`);

        return response.status === 200 ? response.data : false;
    } catch (error) {
        return false;
    }
}

const getListPeriods = async () => {
    try { 
        let response = await axios_client.get(`/api/holiday/data`);

        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }
        
    } catch (error) { 
        return false;
    }
}

export {
    getListPermissionsFromEmployee,
    getListHolidaysFromEmployee,
    getListPermissionsInPeriod,
    getListPostingFromEmployee,
    getListPostingInPeriod,
    getListChildrenForEmploye,
    getListWaiverInPeriod,
    getListPermissionsFromRegime,
    getListEmployeeFromRegime,
    getListContractFromContractTypeEmployee,
    getListEmployeeFatherWithChildren,
    getListEmployeeMatherWithChildren,
    getListEmployeeWhoHadCovid,
    getListEmployeeFromComorbilty,
    getListEmployeeFromSystemPension,
    getListEmployeeFromDisability,
    getListEmployeeFromChildrenPeriod,
    getDataSheetEmploye,
    getListEmployeeFromBloodType,

    getListAssetAll,
    getListAssetForEmploye,
    getListAssetTransferAll,
    getDownloadPdfTransfer,

    getAssetByBarCode,
    getListPeriods
}