import React, { useContext,useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Context } from '@context/Auth'; 
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import { Label } from '@components/form/Label';
import { useDispatch, useSelector } from 'react-redux';

export const AppTopbar = (props) => {
    const context = useContext(Context);
    const dispatch = useDispatch();
    const setSidebar = (payload) => dispatch({ payload, type: "SETSIDEBAR" });
    const view = useSelector(state => state.sidebar.view); 

    const history = useHistory();
    const [dataUser,setDataUser] = useState({id:'',email:'',name: ''  });

    useEffect(() => { 
        setDataUser(JSON.parse(Cookies.get('app_user')))
    }, [])

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src='/assets/layout/images/logo_1.png' alt="logo" />
                <img src='/assets/layout/images/logo_2.png' alt="logo" /> 
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button"  onClick={()=>setSidebar({view:!view, animation:true})}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}> 
                <li  className="text-center">
                   <div> <Label font="font-bold"> {dataUser.name}</Label></div>
                    <Label> {dataUser.email}</Label>
                     
                </li>
                <li>
                    <button onClick={()=> history.push('/usuario/mi-perfil')} className="p-link layout-topbar-button" >
                        <i className="pi pi-cog" />
                        <span>Usuario</span>
                    </button>
                </li>
                <li>
                    <button onClick={() => context.actions.closeSession()} className="p-link layout-topbar-button" >
                        <i className="pi pi-power-off" />
                        <span>Cerrar sesión</span>
                    </button>
                </li>
            </ul>
        </div>
    );
}
