import React from 'react'; 
import PropTypes from 'prop-types'  
import LottieJson from '@public/lottie/loading.json';
import { Image } from 'primereact/image';

import Lottie from 'react-lottie-player'
 
const Loading =(props) => {   
  const { view } = props;
  return (
      <>
      {view &&
        <div className="absolute z-5  bg-blur h-screen w-screen flex align-content-center justify-content-center flex-wrap ">
        <div className="flex align-content-center relative">
            <Lottie
              loop
              animationData={LottieJson}
              play
              style={{ width: 400, height: 400 }} 
            />
         
             <div className="flex justify-content-center align-content-center absolute" style={{top: '6.5rem', left: '7rem'}}>
                  <Image
                    src="assets/layout/images/logo_transparent.png"
                    alt="Logo"
                    width={180}
                    height={180}
                    layout="intrinsic"  />
            </div>  
        </div>
        </div> 
    }
   </> 
  );
}


Loading.defaultProps = {
  view:  false 
};

Loading.propTypes = {  
  view: PropTypes.bool
}

 
export default Loading;