import { zonedTimeToUtc, format } from 'date-fns-tz'
import { addMinutes } from 'date-fns'

const formatDate = (date, formatt = 'dd/MM/yyyy') => {
  const utcDate = zonedTimeToUtc(date, 'America/Lima')
  return format(new Date(utcDate), formatt)
}

const add = (date, count, type) => {
  switch (type) {
    case 'addMinutes':
      return addMinutes(date, count)

    default:
      return date
  }
}
const formatCalendar = (date) => {
  const utcDate = zonedTimeToUtc(date, 'America/Lima')

  return new Date(utcDate)
}

const getDate = () => {
  const utcDate = zonedTimeToUtc(Date.now(), 'America/Lima')

  return new Date(utcDate)
}

const getDateFormat = () => {
  const utcDate = zonedTimeToUtc(Date.now(), 'America/Lima')

  return format(new Date(utcDate), 'dd/MM/yyyy HH:mm:ss')
}

const getDateFormatWithHour = (date) => {
  const utcDate = zonedTimeToUtc(date, 'America/Lima')

  return format(new Date(utcDate), 'dd/MM/yyyy HH:mm:ss')
}

const getDateMore1Day = (date) => {
  const utcDate = zonedTimeToUtc(date, 'America/Lima')

  let d = new Date(utcDate);

  let newd = d.setDate(d.getDate() + 1);
  return new Date(newd);
}

export {
  formatDate,
  formatCalendar,
  getDateMore1Day,
  getDate,
  getDateFormat,
  getDateFormatWithHour,
  add
};