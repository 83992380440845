import React, { useState, useRef } from 'react';
import { InputApp as InputText } from '@components/form/InputText';
import { Button } from 'primereact/button';
import { useForm, Controller } from 'react-hook-form';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { login } from "@services/LoginService";
import Loading from '@components/loading'; 

export const Login = () => {
    const toast = useRef(null);
    const [viewPassword, setViewPassword] = useState(false);
   
    const [disabled, setDisabled] = useState(false);
    const defaultValues = {
        email: '',
        password: ''
    }
    const { control, handleSubmit, formState: { errors } } = useForm({ defaultValues });
    const onSubmit = async (data) => {
        setDisabled(true);
        try {
            if (!disabled) {
                setDisabled(true);
                let result = await login(data)
                
                if ( !result ) { 
                    setTimeout(() => {
                        setDisabled(false);
                        toast.current.show({ severity: 'error', summary: 'Mensaje', detail: 'Correo o contraseña incorrecta', life: 3000 });
                    }, 2000);
                   
                } else { 
                    setTimeout(() => {
                        setDisabled(false);
                        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: 'Bienvenido', life: 3000 });
                        window.location.reload()
                    }, 2000);
                }
            }
        } catch (error) { 
            setDisabled(false);
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <Loading view={disabled} />
            <div className="bg-login">
                <div className="flex justify-content-center h-screen">
                    <div className="flex align-items-center">
                        <div className="card p-4 md:w-24rem">
                            <div className="flex align-items-center justify-content-center">
                                <Image width="100" src="assets/layout/images/logo_1.png" template="Preview Content" alt="Image Text" />
                                <Image width="180" src="assets/layout/images/logo_2.png" template="Preview Content" alt="Image Text" />
                            </div>
                            <h5 className="text-center">INICIO DE SESIÓN</h5>
                            <p className="mt-4">Ingrese sus credenciales.</p>
                            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                                <Controller
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <>
                                            <InputText
                                                iconLeft="pi pi-user"
                                                errors={errors.password}
                                                onChange={onChange}
                                                value={value}
                                                placeholder="Correo"
                                                textError={errors.email && errors.email.message} />
                                        </>
                                    )}
                                    name="email"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Campo requerido."
                                        }
                                    }}
                                    defaultValue=""
                                />
                                <div className="p-field pt-3 pb-4">
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <>
                                                <InputText
                                                    iconLeft="pi pi-lock"
                                                    errors={errors.password}
                                                    placeholder="Contraseña"
                                                    onChange={onChange}
                                                    value={value}
                                                    type={viewPassword ? 'text' : 'password'}
                                                    iconRight={viewPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                                    onClickRight={() => setViewPassword(!viewPassword)}
                                                    textError={errors.password && errors.password.message} />
                                            </>
                                        )}
                                        name="password"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Campo requerido."
                                            }
                                        }}
                                        defaultValue=""
                                    />
                                </div>
                                <Button type="submit" label="INGRESAR" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
