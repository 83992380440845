import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { Ripple } from "primereact/ripple";
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const AppSubmenu = (props) => {
    const dispatch = useDispatch();
    const setActiveIndex = (payload) => dispatch({ payload, type: "SETINDEX" });
    const activeIndex = useSelector(state => state.sidebar.index);
    const setSidebar = (payload) => dispatch({ payload, type: "SETSIDEBAR" });


    const onMenuItemClick = (event, item, index) => {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }


        if (item.index === activeIndex) {
            setActiveIndex(null);
        }

        else {
            if( item.to ) setSidebar({view:true, animation:false})
            setActiveIndex(item.index);
        }


        if (props.onMenuItemClick) {
            props.onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }

    }

    const renderLinkContent = (item) => {
        let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
        let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

        return (
            <>
                <i className={item.icon}></i>
                <span>{item.label}</span>
                {submenuIcon}
                {badge}
                <Ripple />
            </>
        );
    }

    const renderLink = (item, i) => {
        let content = renderLinkContent(item);
        let verify = item.roles.filter(param => param === Cookies.get('app_role'));
        if (verify && verify.length > 0) {
            if (item.to) {
                return (
                    <NavLink className="p-ripple" activeClassName="router-link-active router-link-exact-active" to={item.to} onClick={(e) => onMenuItemClick(e, item, i)} exact target={item.target}>
                        {content}
                    </NavLink>
                )
            }
            else {
                return (
                    <a href={item.url} className="p-ripple" onClick={(e) => onMenuItemClick(e, item, i)} >
                        {content}
                    </a>
                );
            }
        }
    }

    let items = props.items && props.items.map((item, i) => {
        let active = activeIndex ? activeIndex.indexOf(item.index) !== -1 : false;

        let styleClass = classNames(item.badgeStyleClass, { 'layout-menuitem-category': props.root, 'active-menuitem': active && !item.to });

        if (props.root) {
            return (
                <li className={styleClass} key={i}>
                    {props.root === true && <>
                        <div className="layout-menuitem-root-text">{item.label}</div>
                        <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
                    </>}
                </li>
            );
        }
        else {
            return (
                <li className={styleClass} key={i}>
                    {renderLink(item, i)}
                       {active ? <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} /> : ''  }
                </li>
            );
        }
    });

    return items ? <ul className={props.className}>{items}</ul> : null;
}

export const AppMenu = (props) => {
    return (
        <div className="layout-menu-container " style={{paddingBottom: '100px'}}>
            <AppSubmenu items={props.model} className="layout-menu" onMenuItemClick={props.onMenuItemClick} root={true} />
        </div>
    );
}
