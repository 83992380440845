const initialState = {
    view: false 
}
  
const client = (state = initialState,  { type, payload }) => { 
        switch (type) {
            case "SETVIEWSCROLL":
             return { ...state,view: payload}; 
          
            default:
              return state;
          } 
  }

export default client;