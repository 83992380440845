import axios_client from "@util/axios_client";


const create = async ( param ) => {
    try {
        let employee_id = param.employee_id;
        let response = await axios_client.post(`/api/employee/${employee_id}/schedule`,{
            modality_id: param.modality_id,
            shift_id: param.shift_id,
            work_hour_id: param.work_hour_id  
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }
        
    } catch (error) { 
        return false;
    }
}

const update = async ( param ) => {
    try {
        let schedule_id = param.schedule_id;
        let response = await axios_client.put(`/api/schedule/${schedule_id}`,{
            modality_id: param.modality_id,
            shift_id: param.shift_id,
            work_hour_id: param.work_hour_id  
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }
        
    } catch (error) { 
        return false;
    }
}

const select = async () => {
    try { 
        let result = await axios_client.get(`/api/schedule/data`);

        return result.data;
    } catch (error) {

        return false;
    }
}
 
const getList = async (id,page, itemsPerPage, search = null, sort) => {
    try {
        let url = "?page=" +
        page +
        "&per_page=" +
        itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let result = await axios_client.get(`/api/employee/${id}/schedule${url}`);

        return result.data;
    } catch (error) {

        return false;
    }
}

const deleteItem = async ( id ) => {
    try { 
        let response = await axios_client.delete(`/api/schedule/${id}`);

        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }
        
    } catch (error) { 
        return false;
    }
}

const deleteItems = async ( ids ) => {
    try { 
        let response = await axios_client.delete(`/api/schedule/destroyMultiple`,{
            data: {
                ids 
            } 
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }
        
    } catch (error) { 
        return false;
    }
}


export {
    create,
    update,
    deleteItem,
    deleteItems,
    select,
    getList
}