
import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types'

export const MenuApp = (props) => {
    const { data,classNameBtn, icon } = props
    const menu = useRef(null);
    return (
        <>
            <Menu model={data} popup ref={menu} id="popup_menu" />
            <Button className={classNameBtn} icon={icon} onClick={event => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
        </>
    )
}

MenuApp.defaultProps = {
    data: [],
    classNameBtn: 'p-button-text p-button-rounded p-button-sm p-button-outlined',
    icon: 'pi pi-ellipsis-v'
};
MenuApp.propTypes = {
    data: PropTypes.array.isRequired,
    classNameBtn:  PropTypes.string,
    icon: PropTypes.string
}
