
import axios_login from '../util/axios_login'
import Cookies from 'js-cookie' 
async function login(param) {
    try {
        let response = await axios_login.post('/api/retrieveToken', param) 
        Cookies.set('app_token', response.data.data.token);
        Cookies.set('app_role', response.data.data.role);
        
        let dataUser = await showByToken(response.data.data.token)
        Cookies.set('app_user', JSON.stringify(dataUser));
        return dataUser;
    } catch (error) {
        return false
    }
 
}
async function showByToken(token) {
try {
    let response = await axios_login.get('/api/user/showByToken', {
        headers:
        {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
    }) 
    
    return response.data.data;
} catch (error) {
    return false
}
}


export {
    login
};