import React from 'react';
import PropTypes from 'prop-types'
import { InputText } from 'primereact/inputtext';
import { Label } from './Label';
export const InputApp = (props) => {
  const { iconLeft, iconRight, errors, textError, label, onChange, onClickRight, value, type, placeholder } = props;
  let classInput = 'p-float-label w-full'
  if (iconLeft) classInput += ' p-input-icon-left'
  if (iconRight) classInput += ' p-input-icon-right'

  return (
    <>
      <div className="py-2 w-full">
        <Label>{label}</Label>
        <div className="p-field pt-1">
          <span className={classInput}>
            {iconLeft && <i className={iconLeft} />}
            {iconRight && <i className={`${iconRight} cursor-pointer`} onClick={onClickRight} />}
            <InputText className={`w-full p-inputtext-md ${errors ? 'p-invalid' : ''}`} placeholder={placeholder}
              value={value} onChange={(e) => onChange(e.target.value)}
              type={type} />
          </span>
          {errors &&
            <div>  <small className="w-full text-red-700 label-app">{textError}</small></div>
          }
        </div>
      </div>
    </>
  )
};

InputApp.defaultProps = {
  errors: false,
  iconLeft: '',
  onChange: () => { },
  onClickRight: () => { },
  textError: '',
  label: '',
  value: '',
  type: 'text',
  placeholder: ''
};
InputApp.propTypes = {
  errors: PropTypes.bool,
  iconLeft: PropTypes.string,
  onChange: PropTypes.func,
  onClickRight: PropTypes.func,
  textError: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string
}
