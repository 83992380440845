import React from 'react';
import PropTypes from 'prop-types'
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Label } from './Label';

export const CalendarApp = (props) => {
  const { errors, textError, label, onChange, value, placeholder, minDate, maxDate, selectionMode, yearRange, view, showTime} = props;
  let classInput = 'w-full' 
  const monthNavigatorTemplate = (e) => {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
  }

  const yearNavigatorTemplate = (e) => {
    console.log(e.options)
    return <Dropdown value={e.value} options={e.options.reverse()} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="p-ml-2" style={{ lineHeight: 1 }}  />;
  }
  
  return (
    <>
      <div className="py-2 p-field w-full " >
        <Label>{label}</Label>
        <div className=" pt-1">
          <span className={classInput}>
            <Calendar  showTime={showTime}  value={value} onChange={(e) => onChange(e.value)}  dateFormat="dd/mm/yy" selectionMode={selectionMode} touchUI placeholder={placeholder} minDate={minDate} maxDate={maxDate} showIcon monthNavigatorTemplate={monthNavigatorTemplate} yearNavigatorTemplate={yearNavigatorTemplate} monthNavigator yearNavigator yearRange={yearRange} view={view}
            className={`${errors ? 'p-invalid' : null}`}
            />
          </span>
          {errors &&
          <div>  <small className="w-full text-red-700 label-app">{textError}</small></div>
          }
        </div>
      </div>
    </>
  )
};

CalendarApp.defaultProps = {
  errors: false,
  minDate: '',
  maxDate: '',
  onChange: () => { },
  textError: '',
  label: '',
  value: '',
  placeholder: '',
  selectionMode: 'single',
  yearRange: `1900:${new Date().getFullYear()}`,
  view: 'date',
  showTime: false
};
CalendarApp.propTypes = {
  errors:  PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  onChange: PropTypes.func,
  textError: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  selectionMode: PropTypes.string,
  yearRange: PropTypes.string,
  view: PropTypes.string,
  showTime: PropTypes.bool
}
