import React, { useState, useEffect, useRef, useCallback } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { CalendarApp as Calendar } from '@components/form/Calendar';
import { DropdownApp as Dropdown } from '@components/form/Dropdown';
import { Dropdown as DropdownPagination } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Ripple } from 'primereact/ripple';
import { useParams, useHistory } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { ContratoService } from "@services/ContratoService";
import _ from 'lodash';
import useWindowDimensions from '@hooks/UseHeight'
import { formatDate, formatCalendar, getDateMore1Day } from '@util/date';

export const DetallesContrato = () => {
    const history = useHistory();
    let { id } = useParams();
    const [idemploye, setIdemploye] = useState(id)
    const [idcontract, setIdcontract] = useState(null)

    const { height } = useWindowDimensions();
    let emptyElement = {
        id: null,
        start_date: '',
        end_date: '',
        call_number: '',
        contract_number: '',
        dependency_id: '',
        office_id: '',
        employment_regime_id: '',
        position_id: '',
        contract_id: '',
    };

    let emptyElementwaiver = {
        id: null,
        waiver_date: '',
        observations: '',
        contract_detail_id: '',
        departure_date: ''
    };

    const [elements, setElements] = useState([]);
    const [dataform, setDataform] = useState({ offices: [] });
    const [loadingAllElements, setLoadingAllElements] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingwaiver, setLoadingwaiver] = useState(false);
    const [loadingDeleteIds, setLoadingDeleteIds] = useState(false);
    const [multiSortMeta, setMultiSortMeta] = useState([]);
    const [elementDialog, setElementDialog] = useState(false);
    const [elementDialogwaiver, setElementDialogwaiver] = useState(false);
    const [deleteElementDialog, setDeleteElementDialog] = useState(false);
    const [deleteElementDialogwaiver, setDeleteElementDialogwaiver] = useState(false);
    const [deleteElementsDialog, setDeleteElementsDialog] = useState(false);
    const [element, setElement] = useState(emptyElement);
    const [elementwaiver, setElementwaiver] = useState(emptyElement);
    const [condition, setCondition] = useState('Nuevo');
    const [conditionwaiver, setConditionwaiver] = useState('Registrar Renuncia');
    const [errors, setErrors] = useState([]);
    const [errorswaiver, setErrorswaiver] = useState([]);
    const [selectedElements, setSelectedElements] = useState(null);
    const [selectedElementsId, setSelectedElementsId] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [submittedwaiver, setSubmittedwaiver] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const elementsService = new ContratoService();

    //pagination
    const [rows, setRows] = useState(10);
    const [totalrecords, setTotalrecords] = useState(1);
    const [currentpage, setCurrentpage] = useState(1);
    const [lastpage, setLastpage] = useState(1);

    useEffect(() => {
        async function getInit() {
            setLoadingAllElements(true)
            getAllElementsFunction(currentpage, rows);
            let result = await elementsService.getEmployeDataContrato();
            setDataform(result ? result : [])
            setLoadingAllElements(false)
        }
        getInit();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openNew = () => {
        setErrors([]);
        setCondition('Nuevo');
        setElement(emptyElement);
        setSubmitted(false);
        setElementDialog(true);
    }

    const hideDialog = () => {
        setCondition('Nuevo');

        setElementDialog(false);
        setSubmitted(false);
    }

    const hideDialogwaiver = () => {
        setConditionwaiver('Registrar Renuncia');
        setElementDialogwaiver(false);
        setSubmittedwaiver(false);
    }

    const hideDeleteElementDialog = () => {
        setDeleteElementDialog(false);
    }

    const hideDeleteElementDialogwaiver = () => {
        setDeleteElementDialogwaiver(false);
    }

    const hideDeleteElementsDialog = () => {
        setDeleteElementsDialog(false);
    }

    const saveElement = async () => {
        setSubmitted(true);
        setLoading(true)
        let el = {
            start_date: (element.start_date ? formatDate(element.start_date, 'yyyy-MM-dd') : ''),
            end_date: (element.end_date ? formatDate(element.end_date, 'yyyy-MM-dd') : ''),
            call_number: element.call_number,
            contract_number: element.contract_number,
            employment_regime_id: (element.employment_regime_id ? element.employment_regime_id.id : ''),
            position_id: (element.position_id ? element.position_id.id : ''),
            dependency_id: (element.dependency_id ? element.dependency_id.id : ''),
            contract_id: (element.contract_id ? element.contract_id.id : ''),
            office_id: (element.office_id ? element.office_id.id : ''),
        }
        let result;
        if (condition === 'Nuevo') {
            result = await elementsService.setCreateElement(el, idemploye)
            if (result.errors) {
                setErrors(result.errors[0]);
                setLoading(false)
                return false;
            }
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        } else {
            result = await elementsService.setUpdateElement(el, element.id)
            if (result.errors) {
                setErrors(result.errors[0]);
                setLoading(false)
                return false;
            }
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        }

        setErrors([]);
        getAllElementsFunction(currentpage, rows);
        setLoading(false)
        setElementDialog(false);
        setElement(emptyElement);
    }

    const saveElementwaiver = async () => {
        setSubmittedwaiver(true);
        setLoadingwaiver(true)
        let el = {
            waiver_date: (elementwaiver.waiver_date ? formatDate(elementwaiver.waiver_date, 'yyyy-MM-dd') : ''),
            departure_date: (elementwaiver.departure_date ? formatDate(elementwaiver.departure_date, 'yyyy-MM-dd') : ''),
        }
        let result;
        if (conditionwaiver === 'Registrar Renuncia') {
            result = await elementsService.setCreateElementWaiver(el, idcontract)
            if (result.errors) {
                setErrorswaiver(result.errors[0]);
                setLoadingwaiver(false)
                return false;
            }
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        } else {
            result = await elementsService.setUpdateElementWaiver(el, elementwaiver.id)
            if (result.errors) {
                setErrorswaiver(result.errors[0]);
                setLoadingwaiver(false)
                return false;
            }
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        }

        setErrorswaiver([]);
        setLoadingwaiver(false)
        setElementDialogwaiver(false);
        setElementwaiver(emptyElementwaiver);
    }

    const editElement = (element) => {
         console.log(dataform.offices,element)
        let data = {
            ...element,
            call_number: element.call_number,
            contract_number: element.contract_number,
            dependency_id: element.dependency,
            office_id: element.office ? dataform.offices.find(office => office.id === element?.office.id && office?.dependency_id === element?.office.dependency_id) : '',
            employment_regime_id: element.employment_regime,
            position_id: element.position,
            contract_id: element.contract,
        }
        data.start_date = data.start_date ? formatCalendar(data.start_date) : ''
        data.end_date = data.end_date ? formatCalendar(data.end_date) : ''
        //
        setErrors([]);
        setCondition('Actualizar');
        setElement({ ...data });
        setElementDialog(true);
    }
    /*
    const confirmDeleteElement = (element) => {
        setElement(element);
        setDeleteElementDialog(true);
    }*/

    const confirmDeleteElementwaiver = () => {
        setDeleteElementDialogwaiver(true);
    }

    const getWaiver = async (element) => {
        setConditionwaiver('Registrar Renuncia');
        setIdcontract(element.id);
        let data = emptyElementwaiver;
        let result = await elementsService.getWaiverForContract(element.id);
        if (result.id) { //existe renuncia
            data = result;
            data.waiver_date = data.waiver_date ? formatCalendar(data.waiver_date) : ''
            data.departure_date = data.departure_date ? formatCalendar(data.departure_date) : ''
            setConditionwaiver('Editar Renuncia');
        }

        setErrorswaiver([]);
        setElementwaiver({ ...data });
        setElementDialogwaiver(true);
    }

    const deleteElement = async () => {
        let result = await elementsService.setDeleteElement(element.id)

        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
        setDeleteElementDialog(false);
        setElement(emptyElement);
        getAllElementsFunction(currentpage, rows);
    }

    const deleteElementwaiver = async () => {
        let result = await elementsService.setDeleteElementWaiver(elementwaiver.id)

        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.data, life: 3000 });
        setDeleteElementDialogwaiver(false);
        setElementDialogwaiver(false);
        setElementwaiver(emptyElementwaiver);
        getAllElementsFunction(currentpage, rows);
    }

    const getAllElementsFunction = async (page, row, q = null, sort = null) => {
        await elementsService.getAllElements(page, row, q, sort, idemploye).then(data => {
            if (!_.isEmpty(data.meta)) {
                setCurrentpage(data.meta.current_page);
                setTotalrecords(data.meta.total);
                setElements(data);
                setLastpage(data.meta.last_page);
            }

        });
    }

    /*const confirmDeleteSelected = () => {
        let ids = [];
        for (let i = 0; i < selectedElements.length; i++) {
            ids.push(selectedElements[i].id);
        }
        setSelectedElementsId(ids);
        setDeleteElementsDialog(true);
    }*/

    const deleteSelectedElements = async () => {
        setLoadingDeleteIds(true)
        let result = await elementsService.setDeleteMultipleElement(selectedElementsId)
        getAllElementsFunction(currentpage, rows);
        setLoadingDeleteIds(false)
        setDeleteElementsDialog(false);
        setSelectedElements(null);
        setSelectedElementsId([]);
        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: result.message, life: 3000 });
    }

    const onInputChange = (e, name) => {
        let val;
        if (!_.has(e, 'target')) {
            val = e;
        } else {
            val = (e.target && e.target.value) || '';
        }
        let _element = { ...element };
        _element[`${name}`] = val;
        setElement(_element);
    }

    const onInputChangewaiver = (e, name) => {
        let val;
        if (!_.has(e, 'target')) {
            val = e;
        } else {
            val = (e.target && e.target.value) || '';
        }
        let _element = { ...elementwaiver };
        _element[`${name}`] = val;

        if (name === 'waiver_date') {
            _element[`departure_date`] = getDateMore1Day(val);
            console.log(val);
            console.log(_element);
        }
        setElementwaiver(_element);
    }

    const onCustomPage = async (event) => {
        setRows(event.rows);
        setCurrentpage(event.page + 1);
        getAllElementsFunction(event.page + 1, event.rows);
    }

    const getPaginationElement = async (event) => {
        setCurrentpage(event.page + 1);
        getAllElementsFunction(event.page + 1, event.rows);
    }

    const sortFunctionElements = async event => {
        let result = []
        let findDelete = multiSortMeta.find(param => param.field === event[0].field && param.order === -1 && event[0].order === 1)
        if (_.isEmpty(findDelete)) {
            _.pullAllBy(multiSortMeta, event, 'field');
            result = [...multiSortMeta, ...event];
        } else {
            result = multiSortMeta.filter(param => param.field !== findDelete.field);
        }


        let string = "";
        setMultiSortMeta(result);
        for (let i = 0; i < result.length; i++) {
            string += `${result[i].field}=${result[i].order === 1 ? 'ASC' : 'DESC'}${i + 1 === result.length ? '' : ','}`
        }

        getAllElementsFunction(currentpage, rows, null, string);
    }

    const searchGlobalElements = useCallback(
        _.debounce(async (value) => {
            getAllElementsFunction(currentpage, rows, value);
        }, 600),
        [],
    );

    const templatePaginator = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                !_.isEmpty(elements.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Anterior</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'NextPageLink': (options) => {
            return (
                !_.isEmpty(elements.data) ? <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-p-3">Siguiente</span>
                    <Ripple />
                </button> : <div></div>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });
                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }
            let countPages = new Array(lastpage);
            countPages = Array.from(countPages, (param, index) => index)
            return (
                countPages.map((param, index) => (
                    <button
                        key={index}
                        type="button"
                        className={options.className}
                        style={{
                            backgroundColor: currentpage === param + 1 ? 'var(--primary-color)' : 'var(--surface-0)',
                            color: currentpage === param + 1 ? 'var(--surface-0)' : 'var(--text-color)'
                        }}
                        onClick={() => getPaginationElement({
                            page: index,
                            rows: rows
                        })}
                    >
                        {param + 1}
                        <Ripple />
                    </button>
                ))
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'Todos', value: totalrecords }
            ];

            return !_.isEmpty(elements.data) ? <DropdownPagination value={options.value} options={dropdownOptions} onChange={options.onChange} appendTo={document.body} /> : <div></div>;
        },

    }


    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="justify-content-center w-full md:flex hidden">
                    <div>
                        <Button icon="pi pi-pencil" className="p-button " onClick={() => editElement(rowData)} />
                        <Button icon="pi pi-exclamation-triangle" className="p-button  p-button-warning" onClick={() => getWaiver(rowData)} />
                    </div>
                </div>
                <div className="justify-content-between w-6rem md:hidden flex">
                    <div>
                        <Button icon="pi pi-pencil" className="p-button  mr-2" onClick={() => editElement(rowData)} />
                        <Button icon="pi pi-exclamation-triangle" className="p-button  p-button-warning" onClick={() => getWaiver(rowData)} />
                    </div>
                </div>
            </>
        );
    }

    const header = (
        <div className="table-header-container">
            <div className="grid">
                <div className="md:col-5 col-12">
                    <div className="table-header">
                        <span className="p-input-icon-left">

                        </span>
                    </div>
                </div>
                <div className="md:col-7 col-12 ">
                    <div className=" md:block hidden">
                        <div className="flex md:justify-content-end">
                            <Button label="Nuevo" icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
                            {/*<Button label="Eliminar" icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedElements || !selectedElements.length} />*/}
                            {/*<Button label="Exportar" icon="pi pi-file-excel" className="p-button " />*/}

                        </div>
                    </div>
                    <div className="md:hidden block ">
                        <div className="flex flex-wrap justify-content-between w-full">
                            <Button icon="pi pi-plus" className="p-button mr-2" onClick={openNew} />
                            {/*<Button icon="pi pi-trash" className="p-button-danger mr-2" onClick={confirmDeleteSelected} disabled={!selectedElements || !selectedElements.length} />*/}
                            <Button icon="pi pi-file-excel" className="p-button" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
    const elementDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button disabled={loading} label={
                loading ? '...Cargando' : (condition === 'Nuevo' ? 'Guardar' : 'Actualizar')
            } icon="pi pi-check" onClick={saveElement} />
        </>
    );
    const elementDialogFooterWaiver = (
        <>

            <div className="justify-content-between  flex">
                {
                    conditionwaiver === 'Editar Renuncia' &&
                    <Button
                        icon="pi pi-trash"
                        className="p-button-danger mr-2 pl-2 pr-2"
                        onClick={confirmDeleteElementwaiver}
                    />
                }

                <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogwaiver} />
                <Button disabled={loadingwaiver} label={
                    loadingwaiver ? '...Cargando' : (conditionwaiver === 'Registrar Renuncia' ? 'Guardar' : 'Actualizar')
                } icon="pi pi-check" onClick={saveElementwaiver} />
            </div>

        </>
    );

    const deleteElementDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteElementDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteElement} />
        </>
    );
    const deleteElementDialogFooterwaiver = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteElementDialogwaiver} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteElementwaiver} />
        </>
    );
    const deleteElementsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" onClick={hideDeleteElementsDialog} />
            <Button label={loadingDeleteIds ? '...Cargando' : 'Si'} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedElements} />
        </>
    );

    const startdateField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha de Inicio</span>
                <div className="text-overflow-ellipsis overflow-hidden white-space-nowrap " style={{width: 'auto'}}>{rowData.start_date}</div>
            </>
        )
    }
    const enddateField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha de Fin</span>
                <div className="text-overflow-ellipsis overflow-hidden white-space-nowrap " style={{width: 'auto'}}>{rowData.end_date}</div>
            </>
        )
    }
    const callnumberField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Número de Convocatoria</span>
                <div className="text-overflow-ellipsis overflow-hidden white-space-nowrap " style={{width: 'auto'}}>{rowData.call_number}</div>
            </>
        )
    }
    const contractnumberField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Número de Contrato</span>
                <div style={{width: 'auto'}}>{rowData.contract_number}</div>
            </>
        )
    }
    const dependencyField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Dependencia</span>
                <div style={{width: 'auto'}}>{rowData.dependency.name}</div>
            </>
        )
    }

    const officeField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Oficina</span>
                <div className="text-overflow-ellipsis overflow-hidden white-space-nowrap " style={{width: 'auto'}}>{rowData.office.name}</div>

            </>
        )
    }

    const regimenField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Régimen</span>
                <div className="text-overflow-ellipsis overflow-hidden white-space-nowrap " style={{width: 'auto'}}>{rowData.employment_regime.name}</div>
            </>
        )
    }
    const positionField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Posición</span>
                <div className="text-overflow-ellipsis overflow-hidden white-space-nowrap " style={{width: 'auto'}}>{rowData.position.name}</div>
            </>
        )
    }
    const contractField = (rowData) => {
        return (
            <>
                <span className="p-column-title">Contrato</span>
                <div style={{width: 'auto'}}>{rowData.contract.type}</div>
            </>
        )
    }

    return (
        <div className="datatable-responsive">
            <div className="mb-4">
                <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-text" onClick={() => history.push('/empleado/listado')} tooltip="Regresar" />
            </div>
            <div className="card">
                <Toast ref={toast} />
                <h5 className="p-m-0">Listado de contratos</h5>
                <DataTable
                    showGridlines
                    className="p-datatable-responsive"
                    ref={dt}
                    value={elements.data}
                    selection={selectedElements}
                    onSelectionChange={(e) => setSelectedElements(e.value)}
                    dataKey="id"
                    paginator
                    loading={loadingAllElements}
                    onPage={onCustomPage}
                    rows={rows}
                    paginatorTemplate={templatePaginator}
                    emptyMessage="No se encontraron resultados"
                    currentPageReportTemplate="Mostrando  {first} a {last} de  {totalRecords} contratos"
                    scrollable
                    scrollHeight={`${height - 460}px`}
                    header={header}
                    sortMode="multiple"
                    multiSortMeta={multiSortMeta}
                    onSort={e => sortFunctionElements(e.multiSortMeta)}
                    sortOrder={multiSortMeta}
                >

                    <Column field="start_date" header="Fecha de inicio" sortable body={startdateField}></Column>
                    <Column field="end_date" header="Fecha de fin" sortable body={enddateField}></Column>
                    <Column field="call_number" header="Número de convocatoria" sortable body={callnumberField}></Column>
                    <Column field="contract_number" header="Número de contrato" sortable body={contractnumberField}></Column>
                    <Column field="dependency" header="Dependencia" sortable body={dependencyField}></Column>
                    <Column field="employment_regime" header="Régimen" sortable body={regimenField}></Column>
                    <Column field="position" header="Posición" sortable body={positionField}></Column>
                    <Column field="contract" header="Contrato" sortable body={contractField}></Column>
                    <Column body={actionBodyTemplate} className="text-center"></Column>
                </DataTable>


                <Dialog
                    visible={elementDialog}
                    style={{ width: '450px' }}
                    header={`${condition} contrato`}
                    modal
                    className="p-fluid"
                    footer={elementDialogFooter}
                    onHide={hideDialog}
                >
                    <div className="field">
                        <Calendar
                            label="Fecha de inicio"
                            errors={errors.start_date && errors.start_date.length > 0}
                            value={element.start_date}
                            onChange={(e) => onInputChange(e, 'start_date')}
                            textError={errors.start_date && errors.start_date[0]} />
                    </div>
                    <div className="field">
                        <Calendar
                            label="Fecha de fin"
                            errors={errors.end_date && errors.end_date.length > 0}
                            value={element.end_date}
                            onChange={(e) => onInputChange(e, 'end_date')}
                            textError={errors.end_date && errors.end_date[0]} />
                    </div>

                    <div className="field">
                        <label htmlFor="call_number">Número de Convocatoria</label>
                        <InputText id="call_number" value={element.call_number} onChange={(e) => onInputChange(e, 'call_number')} required autoFocus className={classNames({ 'p-invalid': submitted && !element.call_number })} />
                        {submitted && !element.call_number && <small className="p-error">El campo número de convocatoria es requerido.</small>}
                        {submitted && errors.call_number && element.call_number && <small className="p-error">{errors.call_number[0]}</small>}
                    </div>

                    <div className="field">
                        <label htmlFor="contract_number">Número de Contrato</label>
                        <InputText id="contract_number" value={element.contract_number} onChange={(e) => onInputChange(e, 'contract_number')} required autoFocus className={classNames({ 'p-invalid': submitted && !element.contract_number })} />
                        {submitted && !element.contract_number && <small className="p-error">El campo número de contrato es requerido.</small>}
                        {submitted && errors.contract_number && element.contract_number && <small className="p-error">{errors.contract_number[0]}</small>}
                    </div>

                    <div className="field">
                        <Dropdown
                            label="Tipo de Contrato"
                            value={element.contract_id}
                            options={dataform.contracts}
                            optionLabel="type"
                            onChange={(e) => onInputChange(e, 'contract_id')}
                            errors={errors.contract_id && errors.contract_id.length > 0}
                            textError={errors.contract_id && errors.contract_id[0]} />
                    </div>

                    <div className="field">
                        <Dropdown
                            label="Dependencia"
                            value={element.dependency_id}
                            options={dataform.dependencies}
                            optionLabel="name"
                            onChange={(e) => onInputChange(e, 'dependency_id')}
                            errors={errors.dependency_id && errors.dependency_id.length > 0}
                            textError={errors.dependency_id && errors.dependency_id[0]} />
                    </div>

                    <div className="field">
                        <Dropdown
                            label="Oficina"
                            value={element.office_id}
                            options={dataform.offices.filter(param => element.dependency_id && param.dependency_id === element.dependency_id.id)}
                            optionLabel="name"
                            onChange={(e) => onInputChange(e, 'office_id')}
                            errors={errors.office_id && errors.office_id.length > 0}
                            textError={errors.office_id && errors.office_id[0]} />
                    </div>

                    <div className="field">
                        <Dropdown
                            label="Régimen laboral"
                            value={element.employment_regime_id}
                            options={dataform.employment_regimes}
                            optionLabel="name"
                            onChange={(e) => onInputChange(e, 'employment_regime_id')}
                            errors={errors.employment_regime_id && errors.employment_regime_id.length > 0}
                            textError={errors.employment_regime_id && errors.employment_regime_id[0]} />
                    </div>

                    <div className="field">
                        <Dropdown
                            label="Cargo"
                            value={element.position_id}
                            options={dataform.positions}
                            optionLabel="name"
                            onChange={(e) => onInputChange(e, 'position_id')}
                            errors={errors.position_id && errors.position_id.length > 0}
                            textError={errors.position_id && errors.position_id[0]} />
                    </div>

                </Dialog>

                <Dialog
                    visible={elementDialogwaiver}
                    style={{ width: '450px' }}
                    header={`${conditionwaiver}`}
                    modal
                    className="p-fluid"
                    footer={elementDialogFooterWaiver}
                    onHide={hideDialogwaiver}
                >
                    <div className="field">
                        <Calendar
                            label="Fecha de Renuncia"
                            errors={errorswaiver.waiver_date && errorswaiver.waiver_date.length > 0}
                            value={elementwaiver.waiver_date}
                            onChange={(e) => onInputChangewaiver(e, 'waiver_date')}
                            textError={errorswaiver.waiver_date && errorswaiver.waiver_date[0]} />
                    </div>
                    <div className="field">
                        <Calendar
                            label="Fecha de Baja"
                            errors={errorswaiver.departure_date && errorswaiver.departure_date.length > 0}
                            value={elementwaiver.departure_date}
                            onChange={(e) => onInputChangewaiver(e, 'departure_date')}
                            textError={errorswaiver.departure_date && errorswaiver.departure_date[0]} />
                    </div>
                </Dialog>

                <Dialog
                    visible={deleteElementDialog}
                    style={{ width: '480px' }}
                    header="Eliminar contrato"
                    modal
                    footer={deleteElementDialogFooter}
                    onHide={hideDeleteElementDialog}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {element && <span>¿Está seguro de eliminar el contrato <b>{element.num_document}</b>?</span>}
                    </div>
                </Dialog>

                <Dialog
                    visible={deleteElementDialogwaiver}
                    style={{ width: '480px' }}
                    header="Eliminar Renuncia"
                    modal
                    footer={deleteElementDialogFooterwaiver}
                    onHide={hideDeleteElementDialogwaiver}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {elementwaiver && <span>¿Está seguro de eliminar la renuncia?</span>}
                    </div>
                </Dialog>

                <Dialog
                    visible={deleteElementsDialog}
                    style={{ width: '480px' }}
                    header="Eliminar contratos"
                    modal
                    footer={deleteElementsDialogFooter}
                    onHide={hideDeleteElementsDialog}
                >
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {element && <span>¿Está seguro de eliminar a los contratos seleccionados?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}
