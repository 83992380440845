import axios_client from "@util/axios_client";

 
const update = async ( param ) => {
    try { 
        let response = await axios_client.put(`/api/user/profile`,{
            email: param.email,
            password: param.password,
            password_confirmation: param.password_confirmation,
            name: param.name
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }
        
    } catch (error) { 
        return false;
    }
}
 
export {
    update
}