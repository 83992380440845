import axios_client from "@util/axios_client";


const create = async (param) => {
    const fd = new FormData();
    if (param.permission_id) {
        fd.append("permission_id", param.permission_id);
    }
    if (param.duration_id) {
        fd.append("duration_id", param.duration_id);
    }
    if (param.document_id) {
        fd.append("document_id", param.document_id);
    }
    if (param.entry) {
        fd.append("entry", param.entry);
    }
    if (param.start) {
        fd.append("start", param.start);
    }
    if (param.end) {
        fd.append("end", param.end);
    }
    if (param.file) {
        fd.append("file", param.file);
    }
    if (param.num_document) {
        fd.append("num_document", param.num_document);
    }
    if (param.observations) {
        fd.append("observations", param.observations);
    }

    let employee_id = param.employee_id;

    return await axios_client.post(`/api/employee/${employee_id}/employeePermission`, fd)
        .then(res => res.data).catch((error) => {
            return error.response;
        })

}

const update = async (param) => {
    const fd = new FormData();
    if (param.permission_id) {
        fd.append("permission_id", param.permission_id);
    }
    if (param.duration_id) {
        fd.append("duration_id", param.duration_id);
    }
    if (param.document_id) {
        fd.append("document_id", param.document_id);
    }
    if (param.entry) {
        fd.append("entry", param.entry);
    }
    if (param.start) {
        fd.append("start", param.start);
    }
    if (param.end) {
        fd.append("end", param.end);
    }
    if (param.file) {
        fd.append("file", param.file);
    }
    if (param.num_document) {
        fd.append("num_document", param.num_document);
    }
    if (param.observations) {
        fd.append("observations", param.observations);
    }
    fd.append("_method", "put");
    let id = param.id;

    return await axios_client.post(`/api/employeePermission/${id}`, fd)
        .then(res => res.data).catch((error) => {
            return error.response;
        })
}

const select = async () => {
    try {
        let result = await axios_client.get(`/api/employeePermission/data`);

        return result.data;
    } catch (error) {

        return false;
    }
}

const getList = async (id, page, itemsPerPage, search = null, sort) => {
    try {
        let url = "?page=" +
            page +
            "&per_page=" +
            itemsPerPage;

        if (search) {
            url = url + "&filter=" + search;
        }
        if (sort) {
            url = url + "&sortBy=" + sort;
        }

        let result = await axios_client.get(`/api/employee/${id}/employeePermission${url}`);

        return result.data;
    } catch (error) {

        return false;
    }
}

const deleteItem = async (id) => {
    try {
        let response = await axios_client.delete(`/api/employeePermission/${id}`);

        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }

    } catch (error) {
        return false;
    }
}

const downloadfile = async (param) => {
    try {
        const config = {

            responseType: 'blob'
        }

        const fd = new FormData();
        if (param.file) {
            fd.append("name", param.file);
        }

        let response = await axios_client.post(`/api/file`, fd, config);

        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }
    } catch (error) {
        return false;
    }
}

const deleteItems = async (ids) => {
    try {
        let response = await axios_client.delete(`/api/employeePermission/destroyMultiple`, {
            data: {
                ids
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }

    } catch (error) {
        return false;
    }
}


export {
    create,
    update,
    deleteItem,
    deleteItems,
    select,
    downloadfile,
    getList
}