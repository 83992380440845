import React from 'react';
import PropTypes from 'prop-types'
import { SelectButton } from 'primereact/selectbutton';
import { Label } from './Label';
import _ from 'lodash';

export const SelectButtonApp = (props) => {
  const { errors, textError, label, onChange, value, options, optionLabel } = props;

  return (
    <>
      <div className="py-2">
        <Label>{label}</Label>
        <div className="p-field pt-1">
          <SelectButton value={value} options={options} optionLabel={optionLabel} onChange={(e) => { if (!_.isEmpty(e.value)) onChange(e.value) }} />
          {errors &&
            <small className="w-full text-red-700">{textError}</small>
          }
        </div>
      </div>
    </>
  )
};

SelectButtonApp.defaultProps = {
  label: '',
  optionLabel: '',
  options: []
};

SelectButtonApp.propTypes = {
  label: PropTypes.string,
  optionLabel: PropTypes.string,
  options: PropTypes.array
}
